import React from 'react'
import HeadSection from './HeadSection'
import MeetUpGallery from './MeetUpGallery'
import MutualPartner from './MutualPartner'
import SameVision from './SameVision'
import SimilarGoal from './SimilarGoal'
import { Helmet } from "react-helmet";

export default function MainVenture() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Infograins is thrilled to collaborate with CDN to offer cutting-edge solutions to our clients using blockchain technology." />
        <title>Infograins and CDN</title>
      </Helmet>
      <HeadSection />
      <MutualPartner />
      <SimilarGoal />
      <MeetUpGallery />
      <SameVision />
    </>
  )
}
