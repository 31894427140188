import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Img1 from '../../../assets/images/venturWithCDN/img-01.jpg'
import Img2 from '../../../assets/images/venturWithCDN/img-02.jpg'
import Img3 from '../../../assets/images/venturWithCDN/img-03.jpg'

export default function MeetUpGallery() {
    return (
        <>
            <section className='meetUpGallery'>
                <div className='circles'></div>
                <div className='circles'></div>
                <div className='circles'></div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                <h2>Meet Up Gallery</h2>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="imageWrapper">
                                <Image fluid src={Img1} />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="imageWrapper">
                                <Image fluid src={Img2} />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="imageWrapper">
                                <Image fluid src={Img3} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
