import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Operationalize Your Data and AI</h2>
                <div className='description'>Your team needs to work together to develop and disseminate data-driven insights across the organization in order to better understand your processes, customers, and products. Our solutions are created for a wide range of skill levels, including data scientists, engineers, MLOps experts, business analysts, and executives. We provide the strong capabilities businesses need to fully leverage data analytics and AI across the whole data pipeline with a no-code, cloud-ready interface.</div>
                <div className='description'>Organizations may use safe, controlled, and scalable solutions to operationalize data analytics and AI with Infograins Data Analytics.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services