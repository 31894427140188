import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Get A Free Web Design Estimate</h2>
                                    <p>Through results-driven website design, web development, and online marketing services, we assist businesses in realizing their full potential. We'll lay out for you exactly what we'll do, what it will cost, and how we'll destroy your rivals.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
