import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Combine Programming Languages</h2>
                                <div className='description'>Create data-centric applications by combining the syntax of various programming languages in a single program, using the best programming languages for the task. You can design, manage, and run models and programs using the Python, R, SQL, and SAS languages with the help of our code and no-code tools.
                                </div>
                                <div className='description'>Our technologies allow businesses who have spent many man-years creating intellectual property in SAS language to run and manage their existing SAS language systems without relying on any other third-party products.
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies