import React, { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';

export default function Work() {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 3,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 4,

            }
        },
    };

    return (
        <>
            <section className='work_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="head">
                                <h2>How An IT staffing firm, Infograins can benefit your business</h2>
                                <p>By putting your core team in charge, Infograins IT Staffing Services may assist your company in gaining a competitive edge.</p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <OwlCarousel className='owl-theme hero_slider' loop margin={10} {...options}>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">1</div>
                                        <h4>IEfficiency</h4>
                                        <p>Our IT Staffing services increase workload effectiveness and maintain the timeliness and budget of your project.</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">2</div>
                                        <h4>Innovation</h4>
                                        <p>Your internal and external teams will work together and innovate by exchanging knowledge about the industry.</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">3</div>
                                        <h4>Scalability</h4>
                                        <p>Faster goal completion will accelerate business growth and prompt you to consider setting new targets.</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">4</div>
                                        <h4>Modern technologies</h4>
                                        <p>Utilize cutting-edge technology to advance your projects and your business strategy.</p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
