import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
const NFTCate = [
    {
        title:'Functional Testing',
        content:'Confirming that the software system complies with all functional requirements of the application produces the desired results. Functional testing is used by our skilled QA testing team to concentrate mostly on user interface, database, security, APIs, client or server application, and related functionalities of the program.'
    },
    {
        title:'Performance Testing',
        content:'Performance testing can help you get rid of issues like instability, slow responsiveness, and longer load times. We ensure flawless, dependable, and intelligent applications thanks to our extensive experience in performance testing by carrying out quality checks and software behavior analysis under load and stress.'
    },
    {
        title:'Security Testing',
        content:'By enabling security testing with each release, you may identify potential flaws in your running application. A skilled group of test engineers who work on our QA testing services do penetration testing and risk assessments to protect apps from hackers and other malicious threats.'
    },
    {
        title:'Usability Testing',
        content:'When several application functions are being used simultaneously, observe the behavior of the application. Our software testing services include a thorough examination of user behavior trends to spot any usability problems early on and produce a flawless end result.'
    },
    {
        title:'Compatibility Testing',
        content:'With Infograins\'s software testing services, you can investigate the possibilities of compatibility testing, where an application is painstakingly evaluated across a variety of browsers, databases, hardware, operating systems, server infrastructures, resolution displays, and devices.'
    },
    {
        title:'Mobile App Testing',
        content:'A mobile app\'s three main building blocks are scalability, security, and support for multiple platforms. For a variety of mobile applications, Infograins offers cross-platform, functional/non-functional testing using both human testing and automated testing methods.'
    },
]
export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                <h2 className='h2_title'>Software Testing & QA Services</h2>
                            </div>
                        </Col>
                        {NFTCate.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
