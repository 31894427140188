import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>We provide specialized Android app development services for all platforms</h2>
                                <div className='description'>Our services are not just available for smartphones. Our experience working with different Android devices and platforms is demonstrated by the portfolio of Android mobile apps we have developed.</div>
                                <div className='description'>We have been able to delve deeply into the Android world that we follow the full-cycle bespoke Android app development methodology that we employ. Additionally, we have looked into every option, including Android TV, Android Wear, Android tablets, and smartphones.</div>
                                <div className='description'>We deliver user-friendly, intuitive apps using our main tech stack, which is tried-and-true software for developing Android apps. Our expert Android app developers are aware of the formula for creating apps that receive over a million downloads from the Google Play Store.</div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies