import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>IOS App Development Services</h2>
                <div className='description'>Developing iOS applications is regarded as the greatest option for creating scalable, secure digital solutions. Our iOS software developers make sure that your applications are designed to propel your company into the future.</div>
                <div className='description'>Depending on their features, iOS applications are better suited for a particular kind of business. Our team of iOS app developers assists companies in determining whether iOS is the right platform for them and which tech stack best meets their requirements.</div>
                <div className='description'>The designs of iOS applications are renowned for being memorable and captivating. We match your brand with the expectations of your target audience for the Apple environment. Our iOS app design team specializes in making memorable user experiences.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services