import React, { useEffect } from 'react'
import ContactUs from '../landingPages/ContactUs'
import Banner from './Banner'
import Description from './Description'
import LogoSlider from './LogoSlider'
import Talent from './Talent'
import Team from './Team'
import BlogSection from '../landingPages/BlogSection'
import Work from './Work'
import Process from './Process'
import Technology from './Technology'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
export default function HireMain(props) {
    const { nft_slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [nft_slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>IT Staffing and Consulting Services | Infograins</title>
                <meta name="description" content="Access our IT staffing and Consulting services your business needs. We deploys vetted, trained and highly certified specialists." />
            </Helmet>
            <Banner />
            <LogoSlider />
            <Description />
            <Talent />
            {/* <Team /> */}
            <Work />
            <Process />
            <Technology />
            <BlogSection />
            <ContactUs />
        </>
    )
}
