import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Salesforce Development Solutions</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Our team of skillful developers assists brands, from startups to enterprises, to get more out of their Salesforce CRM.</p>
                                    <p>We at Infograins are aware of how distinctive a company is. The nicest part is that we don't have to change how we run our business in order for the Salesforce CRM to work effectively. Instead, we may customize the CRM systems to make them appropriate for the way we conduct business.</p>
                                    <p>We can make some adjustments thanks to Salesforce CRM's extremely customizable design. You must work with a top Salesforce development company to get more. We are the same and have years of expertise providing Salesforce CRM solutions across a range of industry verticals for diverse customer requirements.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
