import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from "react-helmet";

function PreviousEventDetails(props) {
    const slug = useParams()
    const navigate = useNavigate()
    const [eventData, setEventData] = useState()


    useEffect(() => {
        props.demo('top')
    }, [slug])

    const getEventDetail = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}event/get-event-detail/${slug.id}/`)
            .then((res) => {
                // console.log("event detail data---", res.data.response)
                setEventData(res.data.response)
            })
            .catch((err) => {
                console.log("err---", err)
            })
    }

    useEffect(() => {
        getEventDetail()
    }, [navigate])


    const description = useRef([]);

    useEffect(() => {
        description.current.innerHTML = eventData && `${eventData.event_content.content}`;
    }, [eventData]);


    return (
        <>
         <Helmet>
                <meta name="description" content={eventData && eventData.event_meta_content.content} />
                <title>{eventData && eventData.event_meta_content.title}</title>
            </Helmet>
            <div className="event_detail_section">
                <Container>
                    <Row>
                        <Col lg={5} md={6} sm={12}>
                            <div className="event_head_content">
                                <div className="event_batch">{eventData && eventData.is_upcoming_event === true ? "Up Coming Event" : "Previous Event" }</div>
                                <div className="event_title">{eventData && eventData.title}</div>
                                <div className="event_desc">{eventData && eventData.description}</div>
                                <div className="event_date">{eventData && eventData.event_date}</div>
                                <div className="event_location">{eventData && eventData.event_location}</div>
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12}>
                            <div className="event_img">
                                <img src={eventData && eventData.image} alt="event_img" className='img-fluid' />
                            </div>
                        </Col>
                    </Row>
                    <div className="event_details">
                        <Row>
                            <Col lg={8} md={8} sm={12}>
                                <div className="event_detail_content">
                                    <p ref={description}></p>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className="other_events">
                                    <div className="head">Other Events</div>
                                    {
                                        eventData && eventData.upcoming_events.map((ele, index) => {
                                            return (
                                                <div className="other_events_items" key={index}>
                                                    <div className="other_events_img">
                                                        <img src={ele.image} alt="event_img" className='img-fluid' />
                                                    </div>
                                                    <div className="other_events_body">
                                                        <div className="title">{ele.title}</div>
                                                        <div className="desc">{ele.description}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PreviousEventDetails

