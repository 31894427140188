import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Data Analytics Strategy',
        para: 'We\'ll use a data-driven methodology to link your analytics projects to measurable business benefits.'
    },
    {
        title: 'Discovering and Enhancing Data',
        para: 'By combining your assets with third-party data and predictive analytics, we deliver you 360-degree consumer views.'
    },
    {
        title: 'Beyond Data Management',
        para: 'We help with governance, monetization, and compliance in addition to data synthesis and analytics.'
    },
    {
        title: 'Democratization of Data',
        para: 'We create user-friendly tools that are tailored to our employees\' needs so they can embrace data and get started with AI right away.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Data Analytics: The Foundation of Your Journey</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card