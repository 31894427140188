import React from 'react'
import { Image } from 'react-bootstrap'
import Logo from '../../assets/media/logo-infograins.png'
export default function Header() {
    return (
        <>
            <header>
                <div className='img_wrapper'>
                    <Image src={Logo} alt='logo' fluid />
                </div>
                <div className='contact_wrapper'>
                    <p>
                        Contact Us :-
                        <a href="tel:+919770477239">+91 - 9770477239</a>
                    </p>
                </div>
            </header>
        </>
    )
}
