import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Benefits</h2>
                                    <p>Our Next.js development services offer a number of benefits, including:
                                        •	Performance: Next.js can significantly improve the performance of your website by generating static pages at build time.
                                        •	SEO: Next.js can be easily optimized for search engines, which can help you improve your website's ranking in search results.
                                        •	Scalability: Next.js is highly scalable, which means that it can be used to build large and complex websites.
                                        •	Flexibility: Next.js is very flexible, which means that it can be used to build a wide variety of websites.
                                        •	Community support: Next.js has a large and active community. This means that there are a lot of resources available to help you learn and use Next.js.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
