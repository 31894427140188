import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "QA Inspection",
            content: "We examine your QA and development processes, identify quality concerns, and establish a strategy to overcome them before deploying any of the web testing services."
        },
        {
            title: "Functional testing",
            content: "Our functional web tests ensure that your software meets all functional specifications. Cross-browser testing, acceptability testing, exploratory testing, and other methods are included."
        },
        {
            title: "Performance testing",
            content: "Load testing, stress testing, volume testing, and stability testing can help you learn how your system operates under large loads and safeguard your firm from product failures."
        },
        {
            title: "Compatibility testing",
            content: "We verify that your web app or website functions properly across several browsers, devices, and operating systems."
        },
        {
            title: "Automated web testing",
            content: "We create unique test automation frameworks for regression, functional, and performance tests to speed up release cycles and assure comprehensive test coverage."
        },
        {
            title: "Security testing",
            content: "The most prevalent vulnerability that malware can exploit is insecure data storage. We undertake penetration testing, which mimics a hacking attack, to identify security flaws."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>We Provide Web App Testing Services</h2>
                                <p>We have testing experience with SaaS platforms, e-commerce businesses, news websites, social networks and various web applications. Check out our web app testing services below, which range from security to usability.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
