import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Quality Assurance",
            content: "Hard testing confirms that your app is free from bugs, crashes, and glitches, providing a smooth user experience."
        },
        {
            title: "Performance Optimization",
            content: " We test your app's performance under various conditions, ensuring it functions flawlessly across different devices and network speeds."
        },
        {
            title: "Security",
            content: "Protect your users' data and your reputation by detecting and modifying potential security vulnerabilities."
        },
        {
            title: "User Satisfaction",
            content: "Happy users lead to higher ratings, increased downloads, and better retention rates."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why Mobile App Testing Matters</h2>
                                <p>The success of your mobile app depends on providing a flawless user experience. Mobile app testing is not just a process; it's an important factor of your app's journey from development to launch and beyond. Here's why it matters</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
