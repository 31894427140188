import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Functional Testing",
            content: "Ensure your blockchain application's features work seamlessly and accurately."
        },
        {
            title: "Security Testing",
            content: " Secure your blockchain system against vulnerabilities and defend your digital assets."
        },
        {
            title: "Performance Testing",
            content: "Measure and optimize the speed and scalability of your blockchain network."
        },
        {
            title: "Smart Contract Testing",
            content: "Verify the reliability and functionality of your blockchain-based smart contracts."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Blockchain Application Testing Services We Offer</h2>
                                <p>Being a reputable blockchain testing firm across the globe, we put 100% of our effort into detecting problems as soon as possible using real-time data & analytics. The following are our top blockchain app testing services</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
