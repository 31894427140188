import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>The PHP Development Frameworks We Use</h2>
                                    <p>Laravel is a PHP framework that is widely used to create interactive web applications. Laravel is an open-source web development framework that allows us to securely manage complex web projects. It makes the development process easier by lowering the complexity of activities like session management, routing, caching, and authentication. This framework is packed with capabilities that will assist our developers in customizing complex web projects. Hire Laravel developers from Simpalm, the leading php development company in the United States, to create successful and creative web applications.
                                    </p>
                                    <p>The Cake PHP, Symfony,Yii</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
