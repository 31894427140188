import React from 'react'
import HireAddBanner from './HireAddBanner'
import HireAddEmpower from './HireAddEmpower'
import HireAddForm from './HireAddForm'
import HireAddLaverage from './HireAddLaverage'
import HireAddWhyShould from './HireAddWhyShould'

const HireDeveloperAdd = () => {
    return (
        <>
            <HireAddBanner />
            <HireAddEmpower />
            <HireAddWhyShould />
            <HireAddLaverage />
            <HireAddForm />
        </>
    )
}

export default HireDeveloperAdd