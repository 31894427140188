import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: '',
        para: '1.	Our team of skilled Alexa skill developers has plenty of expertise creating Alexa skills for a range of industry verticals and specialized markets.'
    },
    {
        title: '',
        para: '2.	In numerous application development projects, we have used artificial intelligence and machine learning technologies extensively.'
    },
    {
        title: '',
        para: '3.	We are equipped internally to host and maintain Alexa Skills.'
    },
    {
        title: '',
        para: '4.	We have a solid track record of creating Alexa Skills, as evidenced by our impressive portfolio.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Why Infograins For Alexa Skills Development?</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card