import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import itStaffing from "../../../assets/images/itStaffing.png"
export default function Banner() {
    const navigate = useNavigate()
  
    const handleRedirect = ()=>{
        navigate("/contactUs")
    }
    return (
        <>
            <section className='hire_dev_section'>
                <Container>
                    <Row>
                        <Col lg={6} md={6}>
                            <div className='banner_content_wrapper'>
                                <div className='banner_content'>
                                    <h2>IT Staffing Solutions</h2>
                                    <p className='text-white'>Flexible IT staffing for today's digital environment, boost your growth.</p>
                                    <button onClick={handleRedirect} className='text-white'>Get a Quote</button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='banner_image_wrapper'>
                                <div className='banner_image '>
                                    <Image src={itStaffing} alt='Staffing' fluid />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
