import React, { useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import Loader from "react-js-loader";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from 'react-router-dom';
export default function Form() {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    // ============================================ form submit ============================================
    const { handleSubmit, reset, register, formState: { errors }, control } = useForm()
    const onSubmit = ({ userName, userEmail, userNumber, userMessage }) => {
        setLoader(true);
        const bodyData = {
            "name": userName,
            "email": userEmail,
            "contact_us": userNumber,
            "remark": userMessage
        }
        console.log(bodyData);
        axios.post(`${process.env.REACT_APP_BASE_URL}event/event_contact/`, bodyData).then((data) => {
            reset();
            setLoader(false);
            navigate('/thank-you/')
            setTimeout(() => {
                navigate('/')
            }, 4000);
        }).catch((error) => {
            setLoader(false);
            console.log(error);
        })
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                    <div className="row">
                        <div className="col-md-6 ">
                            <label htmlFor="">Name <span className="text-danger">*</span> </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    className="know-schedule-inputs"
                                    placeholder="Name"
                                    {...register('userName', {
                                        required: 'Please enter your name',
                                        pattern: {
                                            value: /^[A-Za-z][A-Za-z0-9\s]{3,20}$/,
                                            message: 'Enter valid name'
                                        }
                                    })}
                                />
                                {errors.userName &&
                                    <p className="know-erroe-text">
                                        {errors.userName.message}
                                    </p>}
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <label htmlFor="">Email  <span className="text-danger">*</span></label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    className="know-schedule-inputs"
                                    placeholder="Email"
                                    {...register('userEmail', {
                                        required: 'Please enter your email',
                                        pattern: {
                                            value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                            message: 'Enter valid email'
                                        }
                                    })}
                                />
                                {errors.userEmail &&
                                    <p className="know-erroe-text">
                                        {errors.userEmail.message}
                                    </p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="row">
                        <div className="col-md-6 ">
                            <label htmlFor="">Contact  <span className="text-danger">*</span></label>
                            <div className="mt-1">
                                <Controller
                                    control={control}
                                    name="userNumber"
                                    rules={{
                                        required: 'PLease enter contact number',
                                        pattern: {
                                            value: /^[0-9]{10,}/,
                                            message: 'Please enter valid number'
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <PhoneInput
                                            inputClass='know-schedule-inputs'
                                            enableSearch={true}
                                            country={"us"}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                {errors.userNumber &&
                                    <p className="know-erroe-text">
                                        {console.log(errors.userNumber)}
                                        {errors.userNumber.message}
                                    </p>}
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <label htmlFor="">Message </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    className="know-schedule-inputs"
                                    placeholder="Message"
                                    {...register('userMessage')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 ">
                    {loader ? (
                        <button type="submit" disabled className="know-schedule-btn w-100">
                            <div className="item">
                                <Loader
                                    type="spinner-circle"
                                    bgColor={"#fff"}
                                    color={"#FFFFFF"}
                                    size={40}
                                />
                            </div>
                        </button>
                    ) : (
                        <button type="submit" className="know-schedule-btn w-100">
                            Let's Discuss
                        </button>
                    )}
                </div>
            </form>
        </>
    )
}
