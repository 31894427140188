import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>React Native App Development</h2>
                <div className='description'>The vision and requirements of the customer are jointly conceptualized by our creative and development teams. We come up with creative ideas for delivering the client's message clearly.Through tight client collaboration, our React Native developers plan and sketch the complete interface, design, and all of the information that will be included in the application. Additionally, we set up the application's structure and technology.To ensure a seamless process, we employ a step-by-step strategy. Our React Native Development teams keep the client informed at every stage of the development process while working closely with them.According to the client's instructions, we carry out their online or mobile application. We thoroughly test the product before release in order to address any faults or performance problems.
                </div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services