import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Salesforce Implementation Services</h2>
                                <p>Salesforce consultation, configuration, customisation, migration, integration, maintenance, and other Salesforce-related services are included in Salesforce Implementation Services. Infograins has been working on CRM development since ten years and assists businesses in putting dependable Salesforce solutions in place to support their marketing, sales, and customer care operations.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
