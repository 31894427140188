import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import gathering from '../../../assets/images/engagementModel/gathering 1.svg'
import anaysis from '../../../assets/images/engagementModel/analytics 1.svg'
import design from '../../../assets/images/engagementModel/vector 1.svg'
import coding from '../../../assets/images/engagementModel/Group 53149.svg'
import testing from '../../../assets/images/engagementModel/Group 53150.svg'
import maintainance from '../../../assets/images/engagementModel/laptop 1.svg'
export default function DevelopmentModel() {
    return (
        <>
            <section className='development_section'>
                <Container>
                    <Row>
                        <Col lg={12} md={12}>
                            <div className="head">
                                <h2>Development Models</h2>
                                <p>We provide agile development in addition to our SDLC (Software Development Life Cycle) Development Model. Our SDLC model includes the following phases</p>
                            </div>
                            <div className="features">
                                <Row className='row1'>
                                    <Col lg={4} md={4}>
                                        <div className="feature_content border1">
                                           <div className="image_div">
                                           <Image src={gathering}  />
                                           </div>
                                            <p>Requirements gathering</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <div className="feature_content border2">
                                        <div className="image_div">
                                           <Image src={anaysis}  />
                                           </div>
                                            <p>Analysis</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <div className="feature_content">
                                        <div className="image_div">
                                           <Image src={design}  />
                                           </div>
                                            <p>Design</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4} md={4}>
                                        <div className="feature_content border3">
                                        <div className="image_div">
                                           <Image src={coding}  />
                                           </div>
                                            <p>Coding</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <div className="feature_content border4">
                                        <div className="image_div">
                                           <Image src={testing}  />
                                           </div>
                                            <p>Testing</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <div className="feature_content">
                                        <div className="image_div">
                                           <Image src={maintainance} style={{width:"30px"}}  />
                                           </div>
                                            <p>Maintenance</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
