import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FixedCostModel from './FixedCostModel'
import HourlyModel from './HourlyModel'
import ResourceModel from './ResourceModel'

export default function BusinessModel() {
    return (
        <>
            <section className='business_section'>
                {/* <Container>
                    <Row>
                        <Col lg={12} md={12}>
                            <div className="head">
                                <h2>Business Models</h2>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <ResourceModel/>
                <FixedCostModel/>
                <HourlyModel/>
            </section>
        </>
    )
}
