import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Business Intelligence Implementation',
        para: 'The adoption of, or migration to, a scalable and reliable business intelligence solution that addresses the data requirements and operational problems of the firm. With integrated performance tracking, automated reporting, KPIs monitoring, and notifications, BI systems serve as consolidated sources of company data that keep all employees focused on the end goal.'
    },
    {
        title: 'Enterprise BI systems development',
        para: 'Developing ETL pipelines for data preparation and storage, linking data warehouses, and creating a sustainable architecture around BI solutions. integrating a BI feature into other systems, including desktop, cloud, online, and mobile apps. As a result, you receive a sophisticated and independent BI ecosystem that is suited to the requirements of your business and the specifics of the sector.'
    },
    {
        title: 'Updates to the BI software',
        para: 'Evaluating and updating your current system to include new features, advanced data storage, new data sources and formats, and top-notch functionality. You get quicker data processing, more precise business insights, a better picture of your business, more operational efficiency, and better internal data democratization as a result.'
    },
    {
        title: 'Analytics for digital goods',
        para: 'To better understand how people interact with your digital product or service, develop complete analytics solutions. Your team can accurately calculate LTV and customer churn thanks to the visualization and analysis of behavioral data. They can also find meaningful possibilities, optimize each step of the customer journey, and increase client retention.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>How we can assist</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card