import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Enhanced Security',
        content:'Node.js includes a variety of capabilities that might help developers be more productive. Its REPL (Read-Eval-Print Loop) capability, for example, allows developers to test and debug programs in real time.'
    },
    {
        title: 'Improved efficiency',
        content:'Because of its event-driven, asynchronous programming approach, Node.js development is extremely productive. This enables developers to build more efficient code and minimize development time.'
    },
    {
        title: 'Improved Scalability and Security',
        content:'Node.js can handle a large number of concurrent requests without sacrificing performance, making it very scalable. It also has support for SSLTLS encryption, which protects data security.'
    },
    {
        title: 'Robust Client-App Communication',
        content:'Node.js is built on top of Chrome\'s V8 JavaScript engine, making it extremely stable and consistent. It also includes a large library of modules that can be used for a variety of applications.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>The Advantages of Node.js Development Solutions</h2>
                                <p>Node.js is a strong and versatile technology for developing data-heavy websites or real-time apps that can help your company achieve new heights with greater speed, security, and agility. It provides numerous benefits to the company, including:</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
