import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';

export default function Description() {
    return (
        <>
            <section className='description_section'>
                <Container>
                    <Row>
                        <Col lg={8}>
                            <div className='description_area'>
                                <p>
                                    <strong>Quality Through Experience</strong>
                                    <p>&nbsp;</p>
                                    <p>Infograins has been a top IT staffing company for more than 10 years, offering Fortune 500+ enterprises with superior technology staffing solutions. We rely on our broad knowledge to assist companies in navigating change and seizing opportunities.</p>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>IT Expertise that Produces Results</strong>
                                </p>
                                <p>&nbsp;</p>
                                <p><strong>IMPACTING BUSINESS GROWTH WITH TECH TALENT AVAILABLE ON DEMAND</strong></p>
                                <p>&nbsp;</p>
                                <p>Business is brisk. You become even more swift thanks to digital revolution. Are you able to meet demand with your IT department?</p>
                                <p>&nbsp;</p>
                                <p>At Infograins, we help our clients expand faster by providing them with the qualified IT talent they require straight now. We provide a flexible, scalable approach to IT staffing that supports both your current needs and those of the future.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='hire_form_wrap'>
                                <BannerForm />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
