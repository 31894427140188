import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Enterprise Blockchain Development',
        content: 'We give enterprise blockchain solutions that go beyond workflow and resource optimization to help you become a game changer in your industry. To secretly communicate, speed up important procedures, and develop continuously, create a resilient ecosystem.'
    },
    {
        title: 'DApps Development',
        content: 'Our decentralized systems enable clients to profit from secure transactions without middlemen, regardless of the size or type of their business. Our dApps have neat front ends, operate on the P2P networks of Ethereum and Bitcoin, aid in managing crucial workflows, and monitor supply chains.'
    },
    {
        title: 'Development of DeFi solutions',
        content: 'We can help if you\'re thinking about developing turnkey DeFi solutions. Our group creates tailored DeFi ecosystems that guarantee safe and affordable financial transactions, easy asset management, smooth billing and accounting, and open fundraising.'
    },
    {
        title: 'NFT Development',
        content: 'Our blockchain development firm assists you in achieving your objectives, whether you want to convert your assets into NFTs, construct a market for NFTs, or build an entire ecosystem for NFTs. On your NFT initiatives, we offer strategic guidance and turn concepts into ground-breaking products.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Our Blockchain Development Services in Focus</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements