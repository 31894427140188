import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, Container, Image, Row } from 'react-bootstrap'
import IPLForm from './Form'
export default function DiabetesPredictionIndex() {
    return (
        <>
            <Header />
            <section className='agriculture_prediction_page'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12}>
                            <h1>Diabetes Prediction</h1>
                            <IPLForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
