import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top TypeScript Development Company</h2>
                                <p>TypeScript allows you to create fast and dependable online applications.
                                    Microsoft Typescript and JS frameworks have greatly improved coding! TypeScript experts investigate and improve on the best options for huge apps. The combination of TS with JS results in higher quality and faster webpages and apps.
                                </p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
