import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import elipse from '../../../assets/images/engagementModel/elipse.svg'
export default function FixedCostModel() {
  return (
    <>
      <section className='fixed_section'>
        <Container>
          <Row>
            <Col xl={10} lg={10}>
              <Row>
                <Col lg={6} md={6}>
                  <div className="fixed_left_content">
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>Our fixed-cost model is ideal for projects with predefined requirements. The budget, delivery time and goals get decided in advance.</p>
                    </div>

                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>Here we start by specifying your requirements and setting the schedule for your project. A fixed price is then decided based on the plan.</p>
                    </div>

                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>We also create a schedule for the delivery of the complete software development.</p>
                    </div>
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="fixed_right_content">
                    <h2>Fixed Cost Model</h2>
                    <p>We propose this model if our client has a fair idea of the project requirements.</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={2} lg={2} className="business_col">
              <div className="head">
                <h2>Business Models</h2>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}
