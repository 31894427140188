import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import elipse from '../../../assets/images/engagementModel/elipse.svg'

export default function HourlyModel() {
  return (
    <>
      <section className='hourly_section'>
        <Container>
          <Row>
            <Col xl={2} lg={2} className="business_col">
              <div className="head">
                <h2>Business Models</h2>
              </div>
            </Col>
            <Col xl={10} lg={10}>
              <Row>
                <Col lg={6} md={6}>
                  <div className="hourly_left_content">
                    <h2>Hourly Model</h2>
                    <p>The hourly model allows you to contract with our team for a set number of hours to work on your development project.
                    </p>
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="hourly_right_content">
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>Here, the work is completed in accordance with an established hourly price.</p>
                    </div>
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>The hourly price is inclusive of all the resources allocated to your project, guaranteeing the team's complete commitment.</p>
                    </div>
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>Adopting this strategy has the benefit of establishing a clear connection between specific duties and the advertised charge.</p>
                    </div>

                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}
