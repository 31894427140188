import React, { useEffect } from 'react'
import HeroSection from './HeroSection'
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import OurTeam from './OurTeam';
import Testimonial from './Testimonial';
import GetInTouch from './GetInTouch';
import Blog from './Blog';
import Services from './Services';
import { Helmet } from 'react-helmet';
export default function LandingIndex(props) {
    useEffect(() => {
        props.demo("top");
    }, []);
    return (
        <>
            <Helmet>
                <meta name="description" content="As one of the leading Software development Company in India | USA, we specializes in custom software development, Blockchain,AI/Ml, Application Dveleopment and many more" />
            </Helmet>
            <HeroSection />
            <Section1 />
            <Section2 />
            <Section3 />
            <Services />
            <Section4 />
            {/* <OurTeam /> */}
            <Blog />
            <Testimonial />
            <GetInTouch />
        </>
    )
}
