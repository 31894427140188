import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Expertise",
            content: "Our team includes experienced testing professionals who specialize in eCommerce environments, offering you industry-specific insights and solutions."
        },
        {
            title: "Advanced Tools",
            content: "We use cutting-edge testing tools and methodologies to provide accurate and reliable results, giving you confidence in your digital storefront's performance."
        },
        {
            title: "Custom Solutions",
            content: "Every eCommerce platform is unique. We customize our testing services to fulfils your specific requirements and challenges."
        },
        {
            title: "Security First",
            content: "Your customers' trust is our priority. We conduct rigorous security testing to protect your eCommerce platform and sensitive data."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Infograins?</h2>
                                {/* <p>Infograins exemplifies creativity, consumer focus, and technology innovation through well-defined design procedures. We produce responsive and adaptive designs with consistent cross-platform compatibility using a unique blend of modern technologies and new ideas. We are known in the design industry for our creative zeal and hands-on knowledge in all elements of UI/UX, and we have over two decades of experience. We understand what it takes to establish a brand from a design standpoint and how to do so with modern technologies and methods of thinking. We assist customers in thriving by simple designs that can leave a lasting impression on the minds of users, increasing brand awareness and loyalty.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
