import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap'
export default function ContentDiv() {

    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>OUR EXCLUSIVE SERVICES FOR MOBILE APP DEVELOPMENT</h2>
                                    <p>We provide complete cycle services for application design, integration, and management. The company oversees the full mobile app development process, from conception and concept to delivery and continuous support, whether it is a consumer-focused app or a game-changing enterprise-class solution.</p>
                                    {/* <div ref={content}></div> */}
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
