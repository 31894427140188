import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Wireframe",
            content: "It is analogous to a building blueprint and is best characterized as the preliminary design of a website that guides the page's content. It functions as a layout or a preview of the main visual of the user interface. Wireframe Design Services is a vital component that serves as the project's basis."
        },
        {
            title: "Prototype",
            content: "A prototype is a more advanced or interactive demonstration of the main project prepared for user testing prior to product development. It appears to be a near-final product, and the prototyping service can save development expenses and time. It serves as a link between designers and developers, allowing the project to go smoothly."
        }
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>What are wireframe and Prototype Design Services?</h2>
                                <p>Wireframes and prototypes are critical components of the website or mobile app design process. These two serve to showcase the design concept and the key function to the clients.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
