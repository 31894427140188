import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Machine Learning',
        para: 'Automatically translate from one language into another without affecting the text\'s meaning.'
    },
    {
        title: 'Semantic Search',
        para: 'Enable apps to conduct in-depth searches by employing NLP approaches to decipher the user\'s intent and the context from their search queries.'
    },
    {
        title: 'Speech Recognition',
        para: 'Determine how speech is represented in text and deliver accurate search and analytics results.'
    },
    {
        title: 'Information Extraction',
        para: 'Extract structured data from semi-structured or unstructured documents, including PDF files, emails, social networking postings, and more.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Develop a Powerful NLP Application that Includes</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card