import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Salesforce Consulting",
            content: "At this point, we assess your current business requirements and establish the business goals that Salesforce should serve (such as raising salespeople's efficiency, cutting down on lengthy sales cycles, or developing a thorough customer view). Next, we discuss how to use Salesforce features to accomplish your business goals and consider any tweaks and integrations that are required to properly customize the solution to meet your needs. We also carefully prepare the implementation procedure so that it completes each phase on time and within budget."
        },
        {
            title: "Configuration and personalization",
            content: "We have two options for customizing your Salesforce solution, each with a distinct amount of complexity, expense, and platform customization. Salesforce configuration entails changing the default features with point-and-click tools. Customization, on the other hand, is a development approach that enables you to more closely tune the platform with Apex code if significant modifications are needed to achieve your business goals."
        },
        {
            title: "Integration",
            content: "We carry out integrations to make your Salesforce solution interact with third-party systems, including as ERP, e-commerce platforms, and document management systems, seamlessly. We also ensure better visibility of data flows across connected apps."
        },
        {
            title: "Data migration from an Old CRM",
            content: "We seamlessly transfer your data into the new Salesforce solution without corrupting it, causing system outages, or disrupting your business operations. Your historical data is well-structured and available for use as soon as it is migrated because it was examined and organized before the migration. We create data structuring rules (such as duplicate rules) to better assist data quality following its migration."
        },
        {
            title: "User training",
            content: "We offer Salesforce training to promote user acceptance, assist users in adjusting to the capabilities of the given Salesforce-based solution, make the best use of Salesforce's built-in and optional features, and boost worker productivity."
        },
        {
            title: "Launch",
            content: "The migration of a custom Salesforce-based solution from the development environment to the UAT (User Acceptance Testing) environment allows representatives of the client company to test the functionality of the solution and ensure that it performs as needed. The new Salesforce solution is launched into the production environment and made accessible to the customer's personnel if the customer approves the project, according to Infograins experts."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Salesforce Implementation Stages</h2>
                                {/* <p>We have a fantastic team of web development experts who are always testing and applying new ideas that will turn website designs into a gold mine for your company.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
