import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'JavaScript Front End',
        content: 'Our UX/UI design and engineering division offers detail-oriented services aimed at exceeding your consumers\' expectations by designing gorgeous, user-friendly designs for corporate systems, company websites, mobile apps, and all types of software solutions.'
    },
    {
        title:'JavaScript Back End',
        content: 'The NodeJS framework powers server-side or back-end applications. NodeJS enables developers to create complex server-side apps while making the most of this technology. It is frequently seen in modern backend IT architectures.'
    },
    {
        title:'JavaScript Web Development Services',
        content: 'Web development is at the heart of the language. Our skilled Web Design Engineers employ the best frameworks to design personalized and scalable solutions that meet your demands, from simple web applications to robust websites'
    },
    {
        title:'JavaScript Enterprise Development',
        content: 'Our enterprise-level solutions are intended to support complicated IT objectives and extensive operational operations. In this sector, our JavaScript Development Services emphasize efficiency, integration, and customization.'
    },
    {
        title:'JavaScript Mobile App Development',
        content: 'The mobile app development capabilities are exponential when frameworks such as ReactJS and ExpressJS are used. As a result, third-party integration and app customization are quick, high-quality, and extremely efficient.'
    },
    {
        title:'JavaScript Support & Maintenance',
        content: 'Get a Dedicated Team of Top Developers and Support Engineers to Monitor Your Software 24/7. We also provide fast issue resolution and risk analysis to ensure that your organization operates at peak efficiency at all times.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our JavaScript Development Services</h2>
                                {/* <p>Infograins provides a comprehensive range of services based on CI components that are ideal for all types of businesses.</p> */}
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
