import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Analysis of Requirements',
        content:'We delve deeply into the aims, problems, and requirements of your project to establish a solid basis for the development plan.'
    },
    {
        title: 'User Interface/UX Design',
        content:'Our creative designers create user interfaces that are intuitive and visually appealing, with a focus on quick navigation and outstanding user experiences.'
    },
    {
        title: 'Development',
        content:'Our skilled React.JS developers bring your idea to life by crafting clean, efficient code that serves as the foundation of your application.'
    },
    {
        title: 'Quality Assurance Testing',
        content:'Following creation, we employ rigorous testing and quality assurance procedures to discover and resolve any issues, ensuring a faultless and secure end result.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our React.JS Development Process</h2>
                                <p>Working with Infograins means a simple and efficient development approach that assures openness at all stages:</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
