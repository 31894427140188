import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Expertise',
        content:'Our team consists of experienced PHP developers with a proven track record of delivering successful projects.'
    },
    {
        title: 'Custom Solutions',
        content:'We understand that every business is unique, so we create customized solutions that align with your specific goals.'
    },
    {
        title: 'Cutting-edge Technology',
        content:'We stay up-to-date with the latest PHP trends and technologies to ensure your project benefits from the best practices.'
    },
    {
        title: 'Client-Centric Approach',
        content:'Client satisfaction is our priority. We maintain transparent communication throughout the development process to ensure your vision becomes a reality.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Infograins for PHP Development?</h2>
                                {/* <p>Node.js is a strong and versatile technology for developing data-heavy websites or real-time apps that can help your company achieve new heights with greater speed, security, and agility. It provides numerous benefits to the company, including:</p> */}
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
