import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Expert CONSULTATION',
        para: 'Transformational, problem-solving strategy. Addressing problems with sentiment analysis across disciplines. Enhancers of Time-To-Value include responsiveness and agility.'
    },
    {
        title: 'TRAINING',
        para: 'specialized resources. Customized skill. curriculum for focused and in-depth microlearning. domain knowledge. Rostering resources.'
    },
    {
        title: 'CUSTOMIZATION OF THE WORKFLOW ',
        para: 'tools and procedures for sentiment analysis alignment. Developmental Milestones with Structure. processes with two steps for QA annotation and production.'
    },
    {
        title: 'REVIEW CYCLE',
        para: 'Analytical transparency. Real-time monitoring and insights about service delivery. Edge case Perspectives. Improvement of Dynamic Models.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>SENTIMENT ANALYSIS:  STEPS</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card