import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import news from "../../../assets/images/trusted/aceGame2.jpg"

const GameNews = () => {
    const GameCate = [
        {
            title: "Industrial IOT",
            content: "HMIs for industrial equipment, collaborative robotics, machine vision systems, and predictive maintenance. "
        },
        {
            title: "Personal IoT",
            content: "Smart home systems, baby monitors, wearables, smart clothing and accessories, and pet tracking devices based on the Internet of Things. "
        },
        {
            title: "IoT in healthcare",
            content: "Smart prescription trackers, telecare systems, vital sign monitors, and integrated healthcare data analytics solutions. "
        },
        {
            title: "The Smart Home",
            content: "Smart home gadgets with self-learning capabilities, IoT-powered HVAC systems, security systems, and remote control smart lighting systems are some examples of connected home solutions. "
        },
    ]
    return (
        <>
            <section className='gameNews-wrap'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='text-center mb-4'>
                                {/* <h4 className='h4_title'>subheading</h4> */}
                                <h2 className='h2_title'>Custom IoT Solutions for End Users and Businesses</h2>
                                {/* <p className='description'>description</p> */}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                {
                                    GameCate.map((e, key) => {
                                        return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                            <div className='gameNews-card-wrap'>
                                                <figure className="gameNews-card-img">
                                                    <Image src={news} alt='News' fluid />
                                                </figure>
                                                <div className='gameNews-card-detail'>
                                                    <h3 className='h3_title'>{e.title}</h3>
                                                    <p>{e.content}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameNews