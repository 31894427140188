import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GameHero from './GameHero'
import GameNews from './GameNews'
import GameSolution from './GameSolution'
import OurGame from './OurGame'
import { Helmet } from 'react-helmet'

const UnityGame = (props) => {
  const { game_slug } = useParams()
  // =========================== scroll To Top default =========================
  useEffect(() => {
    props.demo('top')
  }, [game_slug])
  // =========================== scroll To Top default =========================
  return (
    <>
      <Helmet>
        <title>IOT Hardware Design Services | Hardware Development – Infograins</title>
        <meta name="description" content="Our team of professionals in IoT hardware offers IoT hardware design services and makes sure that the complete IoT ecosystem works seamlessly." />
      </Helmet>
      <GameHero />
      <GameSolution />
      <OurGame />
      <GameNews />
    </>
  )
}

export default UnityGame