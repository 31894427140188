import React, { useState, useEffect, useCallback } from 'react'
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { Slider } from 'antd'
import axios from 'axios'
export default function IPLForm() {
    const [Result, setResult] = useState('')
    const [Loader, setLoader] = useState(false)
    // ================================ Validation ====================================
    const { control, register, reset, formState: { errors }, handleSubmit, watch } = useForm()

    const onSubmit = async (data) => {
        setLoader(true)
        const bodyData = {
            "pregnancies": data.Pregnancies,
            "glucose": data.Glucose,
            "bloodPressure": data.BloodPressure,
            "skinThickness": data.SkinThickness,
            "insulin": data.Insulin,
            "BMI": data.BMI,
            "diabetesPedigreeFunction": data.DiabetesPedigree,
            "Age": data.Age,
        }
        try {
            const api = await axios.post('https://dp.infosense.tech/core/get_diabetes_data', bodyData)
            setResult(api.data.response.result)
            setLoader(false)
            reset()
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }
    const FieldData = [
        {
            controlId: 'formGroup-pregnancies',
            label: 'Pregnancies',
            labelDescription: 'No. of time ( 0-17)',
            marks: {
                0: "0",
                2: "2",
                4: "4",
                6: "6",
                8: "8",
                10: "10",
                12: "12",
                14: "14",
                17: "17",
            },
            step: 1,
            min: 0,
            max: 17,
            validation: {
                ...register('Pregnancies',
                    // {
                    //     required: 'This is required field'
                    // }
                )
            }
        },
        {
            controlId: 'formGroup-Insulin',
            label: 'Insulin',
            labelDescription: '(0-846)  mm U/ ml',
            marks: {
                0: "0",
                94: "94",
                188: "188",
                282: "282",
                376: "376",
                470: "470",
                564: "564",
                658: "658",
                846: "846",
            },
            step: 1,
            min: 0,
            max: 846,
            validation: {
                ...register('Insulin', {
                    required: 'this is required field'
                })
            }
        },
        {
            controlId: 'formGroup-Glucose',
            label: 'Glucose',
            labelDescription: '(0-199) ( mmol or mg/dl)',
            marks: {
                0: "0",
                22: "22",
                44: "44",
                66: "66",
                88: "88",
                110: "110",
                132: "132",
                154: "154",
                199: "199",
            },
            step: 1,
            min: 0,
            max: 199,
            validation: {
                ...register('Glucose', {
                    required: 'this is required field'
                })
            }
        },
        {
            controlId: 'formGroup-BMI',
            label: 'BMI',
            labelDescription: '(0-67.1) kg/m power of 2',
            marks: {
                0: "0",
                7: "7",
                14: "14",
                21: "21",
                28: "28",
                35: "35",
                42: "42",
                49: "49",
                67: "67",
            },
            step: 1,
            min: 0,
            max: 67,
            validation: {
                ...register('BMI', {
                    required: 'this is required field'
                })
            }
        },
        {
            controlId: 'formGroup-BloodPressure',
            label: 'Blood Pressure',
            labelDescription: '(0-122)  mmhg',
            marks: {
                0: "0",
                13: "13",
                26: "26",
                39: "39",
                52: "52",
                65: "65",
                78: "78",
                91: "91",
                122: "122",
            },
            step: 1,
            min: 0,
            max: 122,
            validation: {
                ...register('BloodPressure', {
                    required: 'this is required field'
                })
            }
        },
        {
            controlId: 'formGroup-Age',
            label: 'Age',
            labelDescription: '(21-81) Years',
            marks: {
                21: "21",
                30: "30",
                40: "40",
                50: "50",
                60: "60",
                70: "70",
                81: "81",
            },
            step: 1,
            min: 21,
            max: 81,
            validation: {
                ...register('Age', {
                    required: 'this is required field'
                })
            }
        },
        {
            controlId: 'formGroup-SkinThickness',
            label: 'Skin Thickness',
            labelDescription: '(0-99) mm',
            marks: {
                0: "0",
                10: "10",
                20: "20",
                30: "30",
                40: "40",
                50: "50",
                60: "60",
                70: "70",
                80: "80",
                99: "99",
            },
            step: 1,
            min: 0,
            max: 99,
            validation: {
                ...register('SkinThickness', {
                    required: 'this is required field'
                })
            }
        },
        {
            controlId: 'formGroup-DiabetesPedigree',
            label: 'Diabetes Pedigree',
            labelDescription: '(0.08-2.42)',
            marks: {
                0: '0',
                0.08: "0.08",
                0.50: "0.50",
                0.90: "0.90",
                1.20: "1.20",
                1.50: "1.50",
                1.80: "1.80",
                2: "2",
                2.20: "2.20",
                2.42: "2.42",
            },
            step: 0.01,
            min: 0,
            max: 2.42,
            validation: {
                ...register('DiabetesPedigree',
                    // {
                    //     required: 'this is required field'
                    // }
                )
            }
        }
    ]
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {FieldData.map(({ controlId, marks, min, max, label, labelDescription, step, validation: { name, required } }) => (
                        <Col lg={6} key={controlId}>
                            <Form.Group className="mb-3" controlId={controlId}>
                                <Form.Label>{label}</Form.Label>
                                <Controller
                                    control={control}
                                    name={name}
                                    rules={required}
                                    render={({ field: { onChange, value } }) => (
                                        <Slider
                                            value={value}
                                            onChange={(x) => onChange(x)}
                                            marks={marks}
                                            step={step}
                                            min={min}
                                            max={max}
                                        />
                                    )}
                                />
                                <Form.Text>{labelDescription}</Form.Text>
                                {
                                    errors[name] &&
                                    <p className="know-erroe-text">
                                        {errors[name].message}
                                    </p>
                                }
                            </Form.Group>
                        </Col>
                    ))}
                </Row>
                <Row className='justify-content-center my-4'>
                    <Col lg={6}>
                        <Form.Group className="pb-3 d-flex align-items-end h-100" controlId="formGroupEmail">
                            {
                                Loader ?
                                    <div className='btn btn-primary w-100 disabled'>
                                        <Spinner animation="border" size='sm'/>
                                    </div>
                                    :
                                    <Button className='w-100' variant="primary" type="submit">
                                        Submit
                                    </Button>
                            }
                        </Form.Group>
                    </Col>
                    <Col>
                        <h2>
                            Result: {Result}
                        </h2>
                    </Col>
                </Row>
            </Form>
        </>
    )
}