import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Why Choose Infograins</h2>
                                    <p>Select a Partner Who Gets the Job Done.
                                        There are plenty of reasons to choose us, but we will highlight six of them.
                                    </p>
                                    <p>Certified WordPress Developers</p>
                                    <p>Our qualified WordPress developers and designers are knowledgeable and enthusiastic about their work. Our team works on each project with complete dedication, exceeding your expectations.</p>
                                    <p>Competitive Pricing</p>
                                    <p>We provide the best WordPress development services at reasonable prices. Our mission is to provide a return on investment on every project we work on, regardless of its magnitude, scope, or size.</p>
                                    <p>Rich Experience</p>
                                    <p>We have extensive expertise creating amazing, responsive, and high-converting WordPress websites for clients in a variety of industry verticals. We have worked on a wide range of projects of varying complexity and size over the years.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
