import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { AiFillSetting } from "react-icons/ai"
const HireAddBanner = () => {
  return (
    <>
      <section className='hireAddBanner_wrap'>
        <Container>
          <Row>
            <Col sm={12} md={7} lg={6} xl={5}>
              <div className="hireAddBanner_about">
                <h4 className='h4_title'>Think with Experts - Infograins</h4>
                  <h2 className='h2_title'>Hire Professional Developers</h2>
                  <p>Hire skilled, experienced, and disciplined developers from Infograins.</p>
                  <a href="https://calendly.com/infograinssolutions/enterprise-blockchain-consultant?month=2023-01&date=2023-01-31" target="_blank" rel='noreferrer' className='hireAddBanner_link'><AiFillSetting /> Schedule a Meeting</a>
              </div>  
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HireAddBanner