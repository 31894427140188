import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Infograins Professional UI UX Design</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Infograins' skilled design team has over a decade of experience in responsive web design, product design, brand identity, and maintenance, all with an emphasis on user-friendliness and value addition. We help clients prosper with inexpensive, dynamic, and eye-catching modern designs, from startups to established businesses, and from simple logos to entire brand upkeep.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
