import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Our Wireframe and Prototype Service</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>We help organizations explore all aspects of digital solutions, including wireframe design and Prototype Design Services that produce outstanding results. Using cutting-edge methodologies, our team creates wireframes and prototypes that present a superior notion of information architecture and interaction design. We have the best staff, skills, and remarkable solutions to cover every phase of the project from conception to completion. Our wireframe and prototype services may improve the user experience while also adding advanced features to the items.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
