import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Effective Application Development',
        content:'Angular Minds has received a lot of praise. AngularJS Development Company specializes in creating extremely robust, engaging, and structured web applications with AngularJS.'
    },
    {
        title: 'AngularJS for E-Commerce',
        content:'Clients can use the shopping cart and payment gateway APIs provided by AngularJS E-commerce application development services.'
    },
    {
        title: 'Get quick and fluid development',
        content:'Our skilled teams make the most of the client\'s requirements. Our AngularJS experts have extensive experience designing spontaneous and native web applications and websites for a wide range of businesses.'
    },
    {
        title: 'The customer comes first.',
        content:'Angular Minds ensures consistent performance and a positive user experience by deploying projects in accordance with the client\'s requirements, project demand, and budget.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>What makes us your Reliable Angular JS Development Partner?</h2>
                                {/* <p>{head.description}</p> */}
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
