import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Why Brands Choose Infograins For Web Development</h2>
                                    <p>Here are just a few of the ways our company differs from others when it comes to our expertise in building websites and digital experiences.</p>
                                    <div>
                                        <ul>
                                               <li> Agile web development and design techniques</li>
                                               <li> Award-winning websites and online solutions</li>
                                               <li> Competitive pay for web development and design talent</li>
                                               <li> 300+ experienced members of a multidisciplinary team situated in the Americas, Europe, and Asia</li>
                                               <li> Front-end specialists on staff: business analysts, UX/UI professionals, and designers </li>
                                               <li> Working with clients in a warm, approachable, conversational, and collaborative manner</li>
                                               <li> Proven track record of completing web design and development projects successfully</li>
                                               <li> Thorough quality assurance (QA) testing prior to "Go-Live"</li>
                                               <li> Reduced expenses are achieved by reducing development times.</li>
                                               <li> Unparalleled skills and experience in open technologies</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
