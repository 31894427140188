export const ServiceMenu = [
    // ================================== Blockchain ==================================
    {
        type: "Blockchain",
        list: "Web3 Development",
        slug: "/blockchain/web3-development/",
    },
    {
        type: "Blockchain",
        list: "Metaverse Development",
        slug: "/blockchain/metaverse-development/",
    },
    {
        type: "Blockchain",
        list: "Public Blockchain Development",
        slug: "/blockchain/public-blockchain-development/",
    },
    {
        type: "Blockchain",
        list: "Private Blockchain Development",
        slug: "/blockchain/private-blockchain-development/",
    },
    {
        type: "Blockchain",
        list: "Proof Of Concept Development",
        slug: "/blockchain/proof-of-concept-development",
    },
    {
        type: "Blockchain",
        list: "Crypto Wallet Development",
        slug: "/blockchain/crypto-wallet-development",
    },
    {
        type: "Blockchain",
        list: "Smart Contracts Development",
        slug: "/blockchain/smart-contracts-development",
    },
    {
        type: "Blockchain",
        list: "Smart Contracts Audit",
        slug: "/blockchain/smart-contracts-audit",
    },
    {
        type: "Blockchain",
        list: "Solidity Development",
        slug: "/blockchain/solidity-development",
    },
    {
        type: "Blockchain",
        list: "Multichain",
        slug: "/blockchain/multichain",
    },
    {
        type: "Blockchain",
        list: "Supply Chain",
        slug: "/blockchain/supply-chain",
    },
    {
        type: "Blockchain",
        list: "Finance",
        slug: "/blockchain/finance",
    },
    {
        type: "Blockchain",
        list: "Transportation & Logistics",
        slug: "/blockchain/transportation-logistics/",
    },
    {
        type: "Blockchain",
        list: "Healthcare",
        slug: "/blockchain/healthcare",
    },
    {
        type: "Blockchain",
        list: "Insurance",
        slug: "/blockchain/insurance",
    },
    {
        type: "Blockchain",
        list: "Procure to Pay (PTP)",
        slug: "/blockchain/procure-to-pay-ptp/",
    },
    {
        type: "Blockchain",
        list: "Blockchain Protocol Development",
        slug: "/blockchain/blockchain-protocol-development/",
    },
    {
        type: "Blockchain",
        list: "Polkadot",
        slug: "/blockchain/polkadot",
    },
    {
        type: "Blockchain",
        list: "Polygon",
        slug: "/blockchain/polygon",
    },
    {
        type: "Blockchain",
        list: "Cardano",
        slug: "/blockchain/cardano",
    },
    {
        type: "Blockchain",
        list: "Binance",
        slug: "/blockchain/binance",
    },
    {
        type: "Blockchain",
        list: "Hybrid Blockchain",
        slug: "/blockchain/hybrid-blockchain",
    },
    {
        type: "Blockchain",
        list: "ICO, STO, IDO",
        slug: "/blockchain/ico-sto-ido/",
    },
    {
        type: "Blockchain",
        list: "Consortium Blockchain Development",
        slug: "/blockchain/consortium-blockchain-development",
    },
    {
        type: "Blockchain",
        list: "Token Development",
        slug: "/blockchain/token-development",
    },
    {
        type: "Blockchain",
        list: "Solana Development",
        slug: "/blockchain/solana-development",
    },
    {
        type: "Blockchain",
        list: "Ethereum Development",
        slug: "/blockchain/ethereum-development",
    },

    {
        type: "Blockchain",
        list: "Decentralized Finance (DEFI)",
        slug: "/blockchain/decentralized-finance-defi/",
    },
    {
        type: "Blockchain",
        list: "Decentralized Applications (DAPPS)",
        slug: "/blockchain/decentralized-applications-dapps/",
    },
    {
        type: "Blockchain",
        list: "Staking",
        slug: "/blockchain/staking",
    },
    {
        type: "Blockchain",
        list: "DeFi Yield Farming",
        slug: "/blockchain/defi-yield-farming",
    },
    {
        type: "Blockchain",
        list: "Liquidity Pool",
        slug: "/blockchain/liquidity-pool",
    },
    {
        type: "Blockchain",
        list: "Rust Development",
        slug: "/blockchain/rust-development",
    },
    {
        type: "Blockchain",
        list: "NFT Marketplace Development",
        slug: "/nft/nft-marketplace-development",
    },
    {
        type: "Blockchain",
        list: "NFT Development Company",
        slug: "/nft/nft-development-company/",
    },

    {
        type: "Blockchain",
        list: "NFT Gaming Platform Development",
        slug: "/nft/nft-gaming-platform-development/",
    },
    {
        type: "Blockchain",
        list: "Semi Fungible Token Development",
        slug: "/nft/semi-fungible-token-development/",
    },
    // ================================== MOBILE DEVELOPMENT ==================================
    {
        type: "Mobile Development",
        list: "iOS App",
        slug: "/mobile-development/ios-app",
    },
    {
        type: "Mobile Development",
        list: "Android App",
        slug: "/mobile-development/android-app",
    },
    {
        type: "Mobile Development",
        list: "Flutter",
        slug: "/mobile-development/flutter-app",
    },
    {
        type: "Mobile Development",
        list: "React Native",
        slug: "/mobile-development/react-native-app",
    },
    {
        type: "Mobile Development",
        list: "Kotlin",
        slug: "/mobile-development/kotlin-app",
    },
    {
        type: "Mobile Development",
        list: "Ionic",
        slug: "/mobile-development/ionic-app",
    },
    {
        type: "Mobile Development",
        list: "Swift",
        slug: "/mobile-development/swift-app",
    },
    {
        type: "Mobile Development",
        list: "Unity App",
        slug: "/mobile-development/unity-app",
    },
    // ================================== WEB DEVELOPMENT ==================================
    {
        type: "Web Development",
        list: "PHP",
        slug: "/web-development/php",
    },
    {
        type: "Web Development",
        list: "CodeIgniter",
        slug: "/web-development/codeigniter",
    },
    {
        type: "Web Development",
        list: "Node.js",
        slug: "/web-development/node-js",
    },
    {
        type: "Web Development",
        list: "python",
        slug: "/web-development/python",
    },
    {
        type: "Web Development",
        list: "AngularJs",
        slug: "/web-development/angular-js",
    },

    {
        type: "Web Development",
        list: "ReactJs",
        slug: "/web-development/react-js",
    },
    {
        type: "Web Development",
        list: "NextJs",
        slug: "/web-development/next-js",
    },
    {
        type: "Web Development",
        list: "JavaScript",
        slug: "/web-development/javaScript",
    },
    {
        type: "Web Development",
        list: "TypeScript",
        slug: "/web-development/typeScript",
    },
    {
        type: "Web Development",
        list: "WordPress",
        slug: "/web-development/wordPress",
    },
    // ================================== DESIGN ==================================
    {
        type: "Design",
        list: "Web App",
        slug: "/design/web-app",
    },
    {
        type: "Design",
        list: "Mobile App",
        slug: "/design/mobile-app",
    },
    {
        type: "Design",
        list: "Wireframing and Prototyping",
        slug: "/design/wireframing-and-prototyping",
    },
    {
        type: "Design",
        list: "UI/UX Design",
        slug: "/design/ui/ux-design",
    },
    // ================================== AI and ML Development ==================================

    {
        type: "AI and ML Development",
        list: "Object Recognition",
        slug: "/ai-development/",
    },
    {
        type: "AI and ML Development",
        list: "Text To Speech",
        slug: "/ai-development/text-to-speech",
    },
    {
        type: "AI and ML Development",
        list: "Business Intelligence",
        slug: "/ai-development/business-intelligence",
    },
    {
        type: "AI and ML Development",
        list: "Natural Language Processing",
        slug: "/ai-development/natural-language-processing",
    },
    {
        type: "AI and ML Development",
        list: "Data Analytics",
        slug: "/ai-development/data-analytics",
    },
    {
        type: "AI and ML Development",
        list: "Sentimental Analysis",
        slug: "/ai-development/sentimental-analysis",
    },
    {
        type: "AI and ML Development",
        list: "Alexa Skills Development",
        slug: "/ai-development/alexa-skills",
    },
    // ================================== IOT ==================================

    {
        type: "IOT",
        list: "IoT App",
        slug: "/iot/iot-app",
    },
    {
        type: "IOT",
        list: "IoT Hardware Prototyping",
        slug: "/iot/iot-hardware-prototyping",
    },
    {
        type: "IOT",
        list: "IoT Dashboard and Analytics",
        slug: "/iot/iot-dashboard-and-analytics",
    },
    {
        type: "IOT",
        list: "Smart Home - Home Automation",
        slug: "/iot/smart-home-automation",
    },
    // ================================== Salesforce Solutions ==================================

    {
        type: "Salesforce Solutions",
        list: "Salesforce Development",
        slug: "/salesforce-solutions/development",
    },
    {
        type: "Salesforce Solutions",
        list: "Salesforce Consulting",
        slug: "/salesforce-solutions/consulting",
    },
    {
        type: "Salesforce Solutions",
        list: "Salesforce Implementation",
        slug: "/salesforce-solutions/implementation",
    },
    // ================================== Software Testing ==================================

    {
        type: "Software Testing",
        list: "Mobile App",
        slug: "/software-testing/mobile-app",
    },
    {
        type: "Software Testing",
        list: "Web App",
        slug: "/software-testing/web-app",
    },
    {
        type: "Software Testing",
        list: "IoT App",
        slug: "/software-testing/iot-app",
    },
    {
        type: "Software Testing",
        list: "Ecommerce",
        slug: "/software-testing/ecommerce",
    },
    {
        type: "Software Testing",
        list: "Blockchain App",
        slug: "/software-testing/blockchain-app",
    },
    // ================================== Game ==================================

    {
        type: "Game",
        list: "Unity Game Development",
        slug: "/game/unity-game-development/",
    },
    {
        type: "Game",
        list: "P2E Game Development",
        slug: "/game/p2e-game-development/",
    },
    {
        type: "Game",
        list: "Web3 Game Development",
        slug: "/game/web3-game-development/",
    },
    {
        type: "Game",
        list: "Metaverse Game Development",
        slug: "/game/web3-game-development/",
    },
    {
        type: "Game",
        list: "Ethereum Game Development",
        slug: "/game/metaverse-game-development/",
    }
]