import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BoxSections from './BoxSections'
import ContentDiv from './ContentDiv'
import HeroSections from './HeroSections'
import KeyFeature from './KeyFeature'
import { Helmet } from 'react-helmet'

export default function NextJs(props) {
    const { nft_slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [nft_slug])
    // =========================== scroll To Top default =========================
    return (
        <>
           <Helmet>
                <title>Next JS Development Company – Infograins</title>
                <meta name="description" content="Infograins is the reputed Next JS Development Company. We develop cutting-edge next-generation solutions on the NextJS platform." />
            </Helmet>
            <HeroSections />
            <BoxSections />
            <ContentDiv />
            <KeyFeature />
        </>
    )
}
