import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Our Agile Python Development Methodology</h2>
                                    <p>Got a Project in Mind?</p>
                                    <p>Send us an email or fill out the form. The more specific your project needs are, the more accurate and precise our estimation of what your project will entail in terms of time, resources, and overall estimation will be.</p>
                                    <p>Sign a Contract</p>
                                    <p>We sign Non-Disclosure Agreements (NDAs) to safeguard your possible company idea and data.</p>
                                    <p>Start Development</p>
                                    <p>Bring your product to market faster than the traditional method. We allow you to engage your preferred Python web developer with the skills and competence required to create best-in-class Python online apps.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
