import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Everything You Should Know About Testing Blockchain Apps</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>IOur preconceptions lead us to believe that Blockchain is a very practical, impenetrable, and extremely secure technology for secure transactions. But in fact, it couldn't be further from the truth. Because a lot of blockchain and cryptocurrency users frequently face once a month or once a year with important regulatory, ethical, legal, privacy, and security issues.</p>
                                    <p>Additionally, a significant loss in the millions of dollars was recorded last year as a result of this blockchain security flaw. We strongly advise blockchain testing if you intend to use the blockchain app development service for healthcare, finance, retail, communication, supply chain, media, or any other purpose in order to maintain the security and uniqueness of your digital identity.</p>
                                    <p>Our manual and automated testers have a reputation for producing high-quality work. We use a variety of testing technologies to support profitable business growth.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
