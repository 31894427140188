import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Us for Kotlin Programming Services?</h2>
                                <div className='description'>To give our consumers the greatest experience possible, we offer excellent Kotlin app development services.</div>
                                <div className='description'>We guarantee your company will achieve unheard-of success with the help of our skilled and enthusiastic team of developers.We develop highly effective, scalable Android app solutions.We use an agile development technique to finish projects more quickly and effectively.We create scalable, feature-rich, and dependable Android applications using our expertise in Kotlin app development.
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies