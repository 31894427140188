import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>SENTIMENT ANALYSIS: HOW DOES IT WORK?</h2>
                                <div className='description'>The following objectives of sentiment analysis are achieved through the use of Natural Language Processing (NLP) and Machine Learning (ML) techniques and algorithms:

                                    1.	BREAKING DOWN TEXT DOCUMENTS INTO THEIR ESSENTIAL ELEMENTS, SUCH AS PHRASES, SENTENCES, TOKENS, AND SPEECH PARTS.

                                    2.	ALL SENTIMENT-RELATED PHRASES AND COMPONENTS ARE IDENTIFIED.
                                    3.	EACH PART OF THE PHRASE OR COMPONENT IS ASSIGNED A SENTIMENT SCORE RANGING FROM -1 TO +1.

                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies