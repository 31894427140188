import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import joyStick from "../../../assets/images/background/games/joystick.png"

const GameSolution = () => {
    return (
        <>
            <section className='GameSolution-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}><div className='GameSolution-text'>
                            {/* <h3 className='h3_title'>subheading</h3> */}
                            {/* <h2 className='h2_title'>title</h2> */}
                            <p>It's interesting to note that the first generation of smart homes focused more on automation and remote control than intelligence. A decade ago, having a futuristic home where you could use your Smartphone to control blinders or train your thermostat to remember your preferred temperature was sufficient to qualify as a smart home. This idea has considerably broader implications in 2022.</p>
                            <p>How is IoT used in home automation today? A smart house nowadays meets and occasionally even exceeds the consumer's expectations. Your home's entire spaces, including its appliances, devices, and sensors, continuously capture information about how you use them. They use sophisticated algorithms to identify your consumption patterns and learn about your behaviors. Then, these revelations assist in fine-tuning your experience.</p>
                            <p>Consider the most recent thermostats, such as the Nest or Ecobee models. The latest generation of Internet of Things-based smart home appliances use their sensor data to automatically modify their schedules to your routines. They continuously track your location and adjust the heaters as necessary.</p>
                            <p>The nicest aspect is that there's basically nothing you need to do. Smart thermostats employ their algorithms to adjust the temperature in your home to your preferences and help you save money on electricity.</p>
                            <div className="center">
                                <a href="#"><span data-attr="Click">Click</span><span data-attr="Me">Me</span></a>
                            </div>
                        </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='GameSolution-img'>
                                <Image src={joyStick} alt='GameSolution' fluid />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameSolution