import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top PHP Development Company</h2>
                                <p>Infograins offers a comprehensive range of PHP development services, including as website development, enterprise web portal development, Complex e-commerce and e-business solution development, and more. With more than 10 years of experience in building applications/websites using PHP frameworks. Whether you are a large organization, nonprofit, or startup, we can quickly jump onto your PHP project to take the pressure off.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
