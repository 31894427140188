import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Integration',
        content: 'NextsJs is totally compatible with any JS environment and supports React, Redux, Thunk, and other JavaScript frameworks.'
    },
    {
        title:'Nextjs Websites',
        content: 'NextJS can be used to build extremely dynamic websites such as news sites. Create excellent websites by combining NextJS with React.'
    },
    {
        title:'NextJS Custom Development Services',
        content: 'We may customize NextJs-powered websites to match unique customer needs. We provide NextJs customizations in addition to standard features.'
    },
    {
        title:'Plugin Development Services',
        content: 'Plugins can be used to enhance existing functionality. We can assist you in locating appropriate plugins. If those plugins are not already accessible, we can create them.'
    },
    {
        title:'NextJs Planning And Strategy',
        content: 'Our team works with our clients to create strategies that generate the best results. They have hands-on experience and the ability to solve even the most difficult challenges.'
    },
    {
        title:'Support And Maintenance',
        content: 'We offer proactive assistance and maintenance to our customers. Customers may entrust us with their sites 24 hours a day, 365 days a year.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our Next JS Development Services</h2>
                                <p>We provide server-side application development services utilizing Next.js, one of the quickest JavaScript frameworks, as a reputable NextJS development firm. Our developers leverage Next.js' capabilities to build applications that are feature-rich, quick, and suited to your specific business needs. Infograins is dedicated to provide high-quality NextJS development services. Hiring a NextJS developer will ensure that the total process is smooth, high-performing, and scalable.</p>
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
