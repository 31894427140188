import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
const Owl = () => {

    return (
        <>
            <div className='w3-OwlCarousel-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='image_div'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_four/web_paragraph-3_zj1uhh'} fluid />
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='hero_slide_section_content'>
                                <div className='hero_content_div'>
                                    {/* <h3 className='h3_title hero_cont_subheading'>{BlockchainCate.subheading}</h3> */}
                                    <h2 className='h2_title hero_cont_heading'>Developing Alexa Skills will help you Succeed</h2>
                                    <div className='hero_cont_para'>Any business application you have can be integrated with Amazon Alexa, which is fantastic. Customers will have a more flexible and convenient way to interact with you as a result. The same holds true for any mobile applications you may already have. The development of specific Alexa skills will make it possible to specify and integrate all of your app's features.</div>
                                    {/* <button className='hero_cont_btn'>Click Me</button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Owl