import React, { useState } from 'react'
import { Col, Form, Row, Button, Image } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
export default function AgricultureForm() {
    const [uploadedImage, setUploadedImage] = useState('')
    const [ImgError, setImgError] = useState(false)

    const onSubmit = (data) => {
        data.preventDefault()
        if (uploadedImage.length === 0) {
            setImgError(true)
        } else {
            setImgError(false)
        }
        console.log(uploadedImage);
    }
    const onFileUpload = (event) => {
        let file = event.target.files[0];
        let blobURL = URL.createObjectURL(file);
        console.log("url", blobURL);
        setUploadedImage(blobURL);
     
    }



    return (
        <>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col lg={12} className='drop_box'>
                        <button className='choose_image_btn'>
                            <input type="file" onChange={(e) => onFileUpload(e)} className='video_input'/>
                        </button>
                        <div id='gallery' className='choose_file_wrapper' onDragOver={e => e.preventDefault()}
                        >
                            {uploadedImage &&
                                <video  controls id='id' autoPlay>
                                    <source src={`${uploadedImage}`} type="video/mp4" />
                                    <source src={`${uploadedImage}`} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                            }
                        </div>

                        {ImgError &&
                            <p className='text-danger'>Please upload video</p>
                        }
                        <Form.Group className="mt-4" controlId="formGroupEmail">
                            <Button className='w-100' variant="primary" type="submit">
                                Predict
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </>
    )
}