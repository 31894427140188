import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Expert Developers',
        content: 'We provide you with the best and most agile development team possible as a top Android app development company. Their skill sets line up with the requirements of your project.'
    },
    {
        title: 'Strong Architecture',
        content: 'Our developers use the top security methods and have a full understanding of Android\'s security flaws to seal the architecture of your app with robust security layers.'
    },
    {
        title: 'Complete openness',
        content: 'You can count on complete transparency throughout the entire process of developing your Android app with us. We regularly and weekly update you on the status of your project and, when appropriate, provide advice.'
    },
    {
        title: 'Models for Flexible Hiring',
        content: 'Hire our hourly, fixed-price, and customized Android app development services. Select the model that best fits the needs and budget of your project.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Our Bespoke Solutions</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements