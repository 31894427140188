import React, { useState, useEffect, useCallback } from 'react'
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import axios from "axios"
const Teams = ['Sunrisers Hyderabad', 'Mumbai Indians', 'Royal Challengers Bangalore', 'Kolkata Knight Riders', 'Kings XI Punjab', 'Chennai Super Kings', 'Rajasthan Royals', 'Delhi Capitals']
export default function IPLForm() {
    // ================================ Validation ====================================
    const { register, reset, formState: { errors }, handleSubmit, watch } = useForm()
    const [result, setResult] = useState("")
    const [Loader, setLoader] = useState(false)
    const [getLocation, setGetLocation] = useState([])
    const getBengaluruArea = async () => {
        try {
            const { data: { locations } } = await axios.get("https://dp.infosense.tech/core/api/get_location_names")
            setGetLocation(locations)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getBengaluruArea()
    }, [])
    // ================================ Form Field Data ====================================

    const FormInput = [
        {
            id: 'p1',
            fieldType: 'input',
            controlId: 'formGroupScore',
            label: 'Area (Square feet)',
            labelDetail: 'Range between (1000-52272)',
            type: 'number',
            placeholder: 'Enter Square feet',
            min: 1000,
            max: 52272,
            validateProp: {
                ...register('Score', {
                    required: 'Please enter Square feet',
                    min: {
                        value: 1000,
                        message: "Minimum value is 1000",
                    },
                    max: {
                        value: 52272,
                        message: 'Maximum value is 52272'
                    }
                })
            }
        },
        {
            id: 'p2',
            fieldType: 'input',
            controlId: 'formGroupBHK',
            label: 'BHK',
            labelDetail: 'Range between (1-43)',
            type: 'number',
            placeholder: 'Enter BHK',
            min: 1,
            max: 43,
            validateProp: {
                ...register('BHK', {
                    required: 'Please enter BHK',
                    min: {
                        value: 1,
                        message: "Minimum value is 1",
                    },
                    max: {
                        value: 43,
                        message: 'Maximum value is 43'
                    }
                })
            }
        },
        {
            id: 'p3',
            fieldType: 'input',
            controlId: 'formGroupBath',
            label: 'Bath',
            labelDetail: 'Range between (1-40)',
            type: 'number',
            placeholder: 'Enter Bath',
            min: 1,
            max: 40,
            validateProp: {
                ...register('Bath', {
                    required: 'Please enter value of Bath',
                    min: {
                        value: 1,
                        message: "Minimum value is 1",
                    },
                    max: {
                        value: 40,
                        message: 'Maximum value is 40'
                    }
                })
            }
        },
        {
            id: 'p4',
            fieldType: 'select',
            controlId: 'formGroupLocation',
            label: 'Location',
            options: getLocation,
            validateProp: {
                ...register('Location', {
                    required: 'Please select the location',
                })
            }
        },
    ]
    // ================================ Form API ====================================
    const onSubmit = async (data) => {
        setLoader(true)
        const bodyData = new FormData()
        bodyData.append("total_sqft", Number(data.Score))
        bodyData.append("location ", Number(data.Location))
        bodyData.append("bhk", Number(data.BHK))
        bodyData.append("bath", Number(data.Bath))
        try {
            const api = await axios.post('https://dp.infosense.tech/core/api/predict_home_price', bodyData)
            setResult(api.data.estimated_price)
            setLoader(false)
            reset()
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    // ================================ Form JSX ====================================
    const FormField = ({ fieldType, options, controlId, label, labelDetail, type, placeholder, min, max, validateProp: { name, ...rest } }) => (

        fieldType === 'input' ?
            <Form.Group className="mb-3" controlId={controlId} >
                <Form.Label>{label}</Form.Label>
                <Form.Control type={type} placeholder={placeholder} name={name} {...rest} min={min} max={max} onWheel={(e) => e.target.blur()} />
                <Form.Text>{labelDetail}</Form.Text>
                {
                    errors[name] &&
                    <p className="know-erroe-text">
                        {errors[name].message}
                    </p>
                }
            </Form.Group>
            :
            <Form.Group className="mb-3" controlId={controlId} >
                <Form.Label>{label}</Form.Label>
                <Form.Select aria-label={label} name={name} {...rest}>
                    <option value={''}>Please select the location</option>
                    {
                        options.map((item) => (
                            <option key={item} value={item}>{item}</option>
                        ))
                    }
                </Form.Select>
                {
                    errors[name] &&
                    <p className="know-erroe-text">
                        {errors[name].message}
                    </p>
                }
            </Form.Group>
    )

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {FormInput.map(({ id, ...options }) => (
                        <Col lg={6} key={id}>
                            <FormField {...options} />
                        </Col>
                    ))}
                    <Col lg={6}>
                        <Form.Group className="pb-3 d-flex align-items-end h-100" controlId="formGroupEmail">
                            {
                                Loader ?
                                    <div className="btn btn-primary disabled w-100"><Spinner animation='border' size='sm' /></div>
                                    :
                                    <Button className='w-100' variant="primary" type="submit">
                                        Estimate Price
                                    </Button>
                            }
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {
                result &&
                <div className='result_wrap'>
                    <h3>Result: {result} Lakh</h3>
                </div>
            }
        </>
    )
}