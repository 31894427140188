import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Performance Testing",
            content: "Is your online store ready to handle the rush in traffic during peak shopping seasons? Our performance testing services evaluate the responsiveness and stability of your eCommerce platform. We identify bottlenecks, optimize load times, and ensure that your website or app remains lightning-fast, even under heavy loads."
        },
        {
            title: "Security Testing",
            content: "Protecting your customers' sensitive information is non-negotiable. Our security testing services go beyond the surface to uncover vulnerabilities and weaknesses in your system. We conduct comprehensive security assessments, penetration testing, and security audits to safeguard your eCommerce platform from cyber threats."
        },
        {
            title: "Usability and User Experience Testing",
            content: "Delivering an exceptional user experience is vital for eCommerce success. Our usability and user experience testing services focus on improving the overall shopping journey. We identify usability issues, optimize navigation, and ensure that your online store is intuitive, accessible, and user-friendly."
        },
        {
            title: "Compatibility Testing",
            content: "With the expansions of devices and browsers, ensuring cross-platform compatibility is important. Our compatibility testing services verify that your eCommerce website and app work smoothly on different devices and browsers, providing a consistent experience to all users."
        },
        {
            title: "Functional Testing",
            content: "Every feature of your eCommerce platform needs to work flawlessly. Our functional testing services precisely test all functionalities, from product searches and shopping carts to payment processing and order tracking, ensuring everything operates as expected."
        },
        
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our eCommerce Testing Services</h2>
                                {/* <p>We have a fantastic team of web development experts who are always testing and applying new ideas that will turn website designs into a gold mine for your company.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
