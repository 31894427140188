import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top UI UX Design Company</h2>
                                <p>We at Infograins recognize the significance of good design in digital success. Our people-first, appealing, and pleasurable designs meet the requirements of current corporations and industries. As an experienced UI UX design firm, we provide full UI/UX services such as branding, responsive web design, mobile app design, user experience consultancy, and promotional designs utilizing cutting-edge tools and technologies.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
