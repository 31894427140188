import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Python Development Company</h2>
                                <p>Welcome to Infograins, your reliable Python programming partner. Python is more than simply a programming language; it's a versatile tool capable of transforming your concepts into powerful, efficient, and scalable solutions. Our skilled Python developers are available to assist you in realizing the full potential of this fantastic language.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
