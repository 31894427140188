import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button } from "react-bootstrap"
import { useForm } from "react-hook-form";
import angle from '../../../assets/images/div_after.png'
import Loader from "react-js-loader";
import SuccessModel from "../../common/SuccessModel"
const HireAddForm = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [btnLoader, setBtnLoader] = useState(false)
    const [success, seSuccess] = useState(false);

    const [experienceErr, setExperienceErr] = useState(false)
    const [technologyErr, setTechnologyErr] = useState(false)
    // *****experience year start****
    const [experienceShow, setExperienceShow] = useState(false)
    const [experience, setExperience] = useState("Years of Experience")
    const handleExperienceBox = () => {
        setExperienceShow(!experienceShow)
    }

    const handleYear = (e) => {
        setExperience(e.target.innerText)
        setExperienceErr(false)
    }
    // *****experience year end****



    // *****technology year start****
    const technologyOption = [
        { name: "Select Technology", logo: require('../../../assets/images/hire_add/select.png') },
        { name: "Blockchain Developers", logo: require('../../../assets/images/hire_add/blockchain.png') },
        { name: "Web3 Developers", logo: require('../../../assets/images/hire_add/web3.png') },
        { name: "App Developers", logo: require('../../../assets/images/hire_add/dapps.png') },
        { name: "Golang Developers", logo: require('../../../assets/images/hire_add/go.png') },
        { name: "Rust Developers", logo: require('../../../assets/images/hire_add/rust.png') },
        { name: "Flutter Developers", logo: require('../../../assets/images/hire_add/flutter.png') },
        { name: "Dapps Developers", logo: require('../../../assets/images/hire_add/dapps.png') },
        { name: "Solidity Developers", logo: require('../../../assets/images/hire_add/solidity.png') },
        { name: "React Native Developers", logo: require('../../../assets/images/hire_add/reactnative.png') },
        { name: "iOS Developers", logo: require('../../../assets/images/hire_add/apple.png') },
        { name: "Ethereum Developers", logo: require('../../../assets/images/hire_add/eth.png') },
        { name: "Node JS Developers", logo: require('../../../assets/images/hire_add/dapps.png') },
        { name: "Hyperledger Developers", logo: require('../../../assets/images/hire_add/hyper.png') },
        { name: "PHP Developers", logo: require('../../../assets/images/hire_add/php.png') },
        { name: "Swift Developers", logo: require('../../../assets/images/hire_add/swift.png') },
        { name: "NFT Developers", logo: require('../../../assets/images/hire_add/nft.png') },
        { name: "Python Developers", logo: require('../../../assets/images/hire_add/python.png') },
        { name: "Kotlin Developers", logo: require('../../../assets/images/hire_add/kotlin.png') },
        { name: "Polygon Developers", logo: require('../../../assets/images/hire_add/polygon.png') },
        { name: "Vue JS Developers", logo: require('../../../assets/images/hire_add/vue.png') },
        { name: "Smart Contract Developers", logo: require('../../../assets/images/hire_add/smartcontract.png') },
        { name: "React Js Developers", logo: require('../../../assets/images/hire_add/react.png') }
    ]
    const [technologyShow, setTechnologyShow] = useState(false)
    const [technology, setTechnology] = useState("Select Technology")
    const [technologyIcon, setTechnologyIcon] = useState(require('../../../assets/images/hire_add/select.png'))
    const handleTechnologyBox = () => {
        setTechnologyShow(!technologyShow)
    }

    const handleTechnology = (e) => {
        setTechnologyIcon(e.logo)
        setTechnology(e.name)
        setTechnologyErr(false)

    }
    // *****technology year end****



    const onSubmit = (data) => {

        if (experience === "Years of Experience" && technology == "Select Technology") {
            setExperienceErr(true)
            setTechnologyErr(true)
            return false
        }

        setBtnLoader(true)
        const config = {
            years_of_experience: experience,
            technologies: technology,
            contact_number: data.phone,
            email: data.email
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}hire-developers/post/hire_developer/`, config).then((res) => {
            console.log("--------", res);
           
           
            if(res){
                setBtnLoader(false)
                seSuccess(true);
                document.body.style.overflow = "hidden"

                setTimeout(() => {
                    document.body.style.overflow = "auto"
                    seSuccess(false)
                }, 3000)

                reset()
                setExperience("Years of Experience")
                setTechnology("Select Technology")
            }
      
        }).catch((errors) => {
            console.log("errors-----", errors);
            seSuccess(false)
            setBtnLoader(false)
        })
    }


    return (
        <>
            <section className='hireAddForm_wrap' id='formHire'>
                <Container>
                    <div className='hireAddForm_title'>
                        <h3 className='h3_title'><span>Let’s Schedule</span> A Meeting To Get A  <br /> Developer Of Your Choice</h3>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='hireAddForm_inner_wrap'>
                            <Row>
                                <Col sm={6} md={6} lg={6} xl={6}>
                                    <div className='experience_select' onClick={handleExperienceBox}>
                                        <Form.Label>Years of Experience You're looking for <span className='text-danger'>*</span></Form.Label>
                                        <div className='select_experience_div hireAdd_input mb-3'>
                                            <div className='img_div_experience'>
                                                <img src='https://www.technoexponent.com/images/cal.png' className='img-fluid mt-1' />
                                            </div>
                                            <Form.Control value={experience} className="" disabled />
                                            <img src={angle} className="img-fluid angle_icon" />
                                        </div>
                                        {
                                            experienceShow ?
                                                <div className='experience_dropdown'>
                                                    <div onClick={handleYear}>Years of Experience</div>
                                                    <div onClick={handleYear}>1 Years</div>
                                                    <div onClick={handleYear}>2 Years</div>
                                                    <div onClick={handleYear}>3 Years</div>
                                                    <div onClick={handleYear}>4 Years</div>
                                                    <div onClick={handleYear}>5 Years</div>
                                                    <div onClick={handleYear}>6 Years</div>
                                                    <div onClick={handleYear}>7 Years</div>
                                                </div>
                                                :
                                                ""
                                        }
                                        {experienceErr ? <small className='text-danger' id="experience_error">Experience is required</small> : ""}
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6} xl={6}>
                                    <div className='technology_select' onClick={handleTechnologyBox}>
                                        <Form.Label>Technologies You're looking for <span className='text-danger'>*</span></Form.Label>
                                        <div className='select_technology_div hireAdd_input mb-3'>
                                            <div className='img_div_technology'>
                                                <img src={technologyIcon} className='img-fluid mt-1' />
                                            </div>
                                            <Form.Control value={technology} className="" disabled />
                                            <img src={angle} className="img-fluid angle_icon" />
                                        </div>
                                        {
                                            technologyShow ?
                                                <div className='technology_dropdown'>
                                                    {
                                                        technologyOption.map((e, i) => {

                                                            return (
                                                                <div className='icon_name' key={i} onClick={() => handleTechnology(e)}>
                                                                    <div className='technology_icons'>
                                                                        <img src={e.logo} />
                                                                    </div>
                                                                    <div>{e.name}</div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        {technologyErr ? <small className='text-danger' id="technology_error">Technology is required</small> : ""}
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="formBasicNumber">
                                        <Form.Label>Contact Number <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Phone number" className="hireAdd_input" autoComplete='false'
                                            {...register("phone", {
                                                required: 'Phone Number is Required',
                                                pattern: {
                                                    value: /\S\S+/g,
                                                    message: "field cannot be empty"
                                                }
                                            })}
                                        />
                                        {errors.phone && (
                                            <Form.Text className="text-muted">
                                                <small className='text-danger'>{errors.phone.message}</small>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={6} md={6} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" className="hireAdd_input" autoComplete='false'
                                            {...register("email", {
                                                required: 'Email is Required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid Email Address"
                                                }
                                            })}
                                        />
                                        {errors.email && (
                                            <Form.Text className="text-muted">
                                                <small className='text-danger'>{errors.email.message}</small>
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                </Col>

                            </Row>
                            {
                                btnLoader ?
                                    <Button variant="primary" type="submit" className='hireAdd_button' style={{width:"260px"}} disabled><div className="item">
                                        <Loader type="spinner-circle" bgColor={"#fff"} color={'#FFFFFF'} size={40} /></div>
                                    </Button>
                                    :
                                    <Button variant="primary" type="submit" className='hireAdd_button'>
                                        Schedule a Meeting
                                    </Button>
                            }


                        </div>
                    </Form>
                </Container>
              
            </section>
            {success ? <SuccessModel /> : ""}
        </>
    )
}

export default HireAddForm