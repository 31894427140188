import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Rule-based',
        content: 'Based on a set of manually created rules and a vocabulary of phrases with known sentiments, these systems automatically do sentiment analysis.'
    },
    {
        title: 'Automatic',
        content: 'To learn from training data, these systems typically use machine learning techniques. When nuanced emotions (such as anger, amusement, sadness, and jealousy) are taken into account, this entails training classifiers to conduct binary sentiment classification or multi-class sentiment classification. This kind of sentiment analysis is often implemented by open source Python toolkits like NLTK.'
    },
    {
        title: 'Hybrid',
        content: 'To evaluate sentiment from a semantic perspective, these systems mix both rule-based linguistics and automatic methods.'
    },
    // {
    //     title: 'Personalization',
    //     content: 'Personalization in facial practices, including image, video, and much more, is made possible by object recognition. Users and customers can quickly do searches using the graphic.'
    // }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Types of Sentiment Analysis</h2>
                        <p>The three main types of sentiment analysis are as follows:</p>
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements