import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Custom React Development',
        content: 'Our Company develops unique user interfaces with React technology because it ensures flexibility, efficiency, and scalability. We usually proceed as follows: design the architecture, create and organize components, handle data, and test the program.'
    },
    {
        title:'Single Page Development',
        content: 'Infograins focuses on SPD, which allows all of the app\'s content and functionality to be displayed on a single page. Our React development firm creates a dynamic and interactive web app that loads content and handles user interactions using JavaScript.'
    },
    {
        title:'App Migration to React',
        content: 'Our programming team assists with the conversion of an existing web app to the React JavaScript toolkit. We meticulously organize the migration process, selecting the React components to be used and developing testing processes.'
    },
    {
        title:'React Consulting Services',
        content: 'We provide consulting services with a team of competent specialists - CTO, Team Leads, developers, and QA engineers. Devox Software thoroughly examines the existing app or your requirements to provide the most scalable solution for your company.'
    },
    {
        title:'Build Architecture',
        content: 'We assist you in developing a robust architecture for your React application. The appropriate architecture makes it easier to maintain and scale the program. Various state management libraries, such as Redux, MobX, and Context API, are commonly used by our team.'
    },
    // {
    //     title:'PHP Saas development',
    //     content: 'Our developers can assist you in creating scalable SaaS applications.'
    // }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>React JS Development Services We Provide</h2>
                                {/* <p>Python has grown in popularity for a variety of reasons, including:</p> */}
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
