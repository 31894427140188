import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GameHero from './GameHero'
import GameNews from './GameNews'
import GameSolution from './GameSolution'
import OurGame from './OurGame'
import { Helmet } from 'react-helmet'

const UnityGame = (props) => {
  const { game_slug } = useParams()
  // =========================== scroll To Top default =========================
  useEffect(() => {
    props.demo('top')
  }, [game_slug])
  // =========================== scroll To Top default =========================
  return (
    <>
      <Helmet>
        <title>IOT Based Smart Home Automation – Infograins</title>
        <meta name="description" content="Infograins has expertise in creating cutting-edge smart home automation systems and platforms for remote/real-time access to homes via connected mobile apps." />
      </Helmet>
      <GameHero />
      <GameSolution />
      <OurGame />
      <GameNews />
    </>
  )
}

export default UnityGame