import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"
import axios from 'axios';
import { Form } from "react-bootstrap";
import Loader from "react-js-loader";

const GetInTouch = () => {
    const [ErrorBlockchain, setErrorBlockchain] = useState(false);
    const [countryCodeData, setCountryCodeData] = useState([])
    const { register, handleSubmit } = useForm()
    const [success, seSuccess] = useState(false);
    const [loader, setLoader] = useState(false);

    // ========================= Country Code =============================
    async function countryCode() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}get_country_dialing_code/`);
            const apiData = api.data.response.country_dialing_code;
            setCountryCodeData(apiData)
        } catch (error) {
            setErrorBlockchain(true)
        }
    }

    useEffect(() => {
        countryCode()
    }, []);
    const onSubmit = (data) => {
        var mobilesData = document.getElementById("mobile").value;
        var concatData = mobilesData + data.phone;
        setLoader(true)
        const payload = {
            dialingCode: mobilesData,
            contactNumber: concatData,
            fullName: data.name,
            emailId: data.email,
            message: data.message,
            subject: data.subject
        }
        var formdata = new FormData();
        formdata.append('get_contact_detail', JSON.stringify(payload));
     
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}contact_us/`,
            data: formdata,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => {
            console.log("res",res);
            setLoader(false);
            seSuccess(true);
            document.body.style.overflow = "hidden"
            setTimeout(() => {
                document.body.style.overflow = "auto"
                seSuccess(false)
            }, 3000)
        }).catch(err => {
            setLoader(false)
        })
    }
    return (
        <>
            <section className='get_in_touch_wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <article className='get_in_touch_article'>
                                <div className='get_in_touch_title'>
                                    <h3>Get in Touch</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="274" height="9" viewBox="0 0 274 9" fill="none">
                                        <path d="M1 6.84511C82.2154 1.85645 244.048 -0.937203 272.782 6.84511" stroke="#F48533" strokeWidth="2.39456" />
                                    </svg>
                                    <p>We would love to hear from you, please fill in the form below <br />or mail us your requirements on info@infograins.com
                                    </p>
                                </div>
                                <div className='get_in_touch_info_wrap'>
                                    <Row>
                                        <Col sm={6} md={6} lg={6} xl={6}>
                                            <div className='get_in_touch_info'>
                                                <h5>contact Information</h5>
                                                <p>Infograins</p>
                                            </div>
                                        </Col>
                                        <Col sm={6} md={6} lg={6} xl={6}>
                                            <div className='get_in_touch_info'>
                                                <h5>email</h5>
                                                <a href={`mailto:info@infograins.com`} target={'_blank'}>info@infograins.com</a>
                                            </div>
                                        </Col>
                                        <Col sm={6} md={6} lg={6} xl={6}>
                                            <div className='get_in_touch_info'>
                                                <h5>phone</h5>
                                                <a href={`tel:+91 9770477239`} target={'_blank'}>IND +91 9770477239</a>
                                            </div>
                                        </Col>
                                        <Col sm={6} md={6} lg={6} xl={6}>
                                            <div className='get_in_touch_info'>
                                                <h5>on the skype</h5>
                                                <a href={`skype:point2solutions?chat`} target={'_blank'}>point2solutions</a>                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </article>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='get_in_touch_form_wrap'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col sm={6} md={6} lg={6} xl={6}>
                                            <div className="input-data">
                                                <input type="text" {...register("name", { required: true })} />
                                                <div className="underline"></div>
                                                <label htmlFor="">Your Name<span>*</span></label>
                                            </div>Cutting-Edge 
                                        </Col>
                                        <Col sm={6} md={6} lg={6} xl={6}>
                                            <div className="input-data mobile_code">
                                                <input type="number" {...register("phone", { required: true })} />
                                                <div className="underline"></div>
                                                <label htmlFor="">Mobile No.<span>*</span></label>
                                                <Form.Select id='mobile'>
                                                    {ErrorBlockchain ?
                                                        <option>00</option>
                                                        : countryCodeData.map((e, key) => {

                                                            return <option key={key} value={e.Dial}>{e.country_with_dialing_code}</option>
                                                        })}
                                                </Form.Select>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} xl={12}>
                                            <div className="input-data">
                                                <input type="text" {...register("email", { required: true })} />
                                                <div className="underline"></div>
                                                <label htmlFor="">Your Email<span>*</span></label>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} xl={12}>
                                            <div className="input-data">
                                                <input type="text" {...register("subject", { required: true })} />
                                                <div className="underline"></div>
                                                <label htmlFor="">Subject<span>*</span></label>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} xl={12}>
                                            <div className="input-data textarea">
                                                <textarea rows="8" cols="80"  {...register("message", { required: true })}></textarea>
                                                <br />
                                                <div className="underline"></div>
                                                <label htmlFor="">You Message<span>*</span></label>
                                                <br />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='form_submit_btn_wrap'>
                                        {
                                            loader ?
                                                <button type='submit' className='form_submit_btn'>
                                                    <div className="item">
                                                        <Loader type="spinner-circle" bgColor={"#000"}  size={40} />
                                                    </div>
                                                </button>
                                                :
                                                <button type='submit' className='form_submit_btn'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                                        <path d="M14.0208 0.999936C14.0208 0.447652 13.5731 -6.33821e-05 13.0208 -6.3635e-05L4.02082 -6.35086e-05C3.46853 -6.31714e-05 3.02082 0.447652 3.02082 0.999937C3.02082 1.55222 3.46853 1.99994 4.02082 1.99994L12.0208 1.99994L12.0208 9.99994C12.0208 10.5522 12.4685 10.9999 13.0208 10.9999C13.5731 10.9999 14.0208 10.5522 14.0208 9.99994L14.0208 0.999936ZM1.70711 13.7279L13.7279 1.70704L12.3137 0.29283L0.292893 12.3136L1.70711 13.7279Z" fill="black" />
                                                    </svg>
                                                    Submit
                                                </button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            {
                success ?
                    <section className='congrats_popup'>
                        <div className="svg-container">
                            <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                                <circle className="success" fill="#5bb543" cx="24" cy="24" r="22" />
                                <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                            </svg>
                            <p>Thanks for contacting us. We will contact you shortly</p>
                        </div>
                    </section> : ""
            }
            </section>
        </>
    )
}

export default GetInTouch