import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Why Choose Us For Text to Speech?</h2>
                <div className='description'>Since our business offers cloud-based APIs, the user should not be concerned about losing crucial documents. We offer the most recent cross-platform technology. The professionals at Infograins focus on the details and give customers and consumers the finest possible service. The business has presented a number of use cases and is praised for its excellence. Text-to-speech is something that our engine developers are familiar with and are highly knowledgeable about.</div>
                <div className='description'>
                Experts at Infograins pinpoint consumer demands, trends, and much more. We provide transparency and guarantee that although the voice customers hear is artificial, it nevertheless sounds like a human. Numerous companies have acknowledged Infograins’s unique and customized services. The engine is a fantastic way to grow the business, and we think that every chance should be taken, thus we offer the most trustworthy services.
                </div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services