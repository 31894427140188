import React from 'react'
import { useState, useEffect } from 'react'
import CaseBanner from './CaseBanner'
import Challenges from './Challenges'
import Explore from './Explore'
import Outcome from './Outcome'
import Overview from './Overview'
import TechStack from './TechStack'
import Testimonial from './Testimonial'
import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import Solutions from './Solutions'
import Features from './Features'
import Loader from "react-js-loader";
import NotFound from '../NotFound'

export default function CaseStudiesDetails() {
  const { case_slug } = useParams()
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([])

  async function getDetails() {
    try {
      const { data: { response } } = await axios.get(`https://infograinsbackend.in/case-study/get-case-study-detail/${case_slug}/`)
      if (data) {
        setData(response)
        setLoader(false)
      }
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  console.log("dta---", data)
  return (
    <>
     
            <>
              <CaseBanner data={data && data[0]} />
              <Overview data={data && data[0]} />
              <Challenges data={data && data[0]} />
              <Solutions data={data && data[0]} />
              <Features data={data && data[0]} />
              <Outcome data={data && data[0]} s />
            </>
     
    </>
  )
}
