import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>End-To-End JavaScript Development Services to Meet Your Business Needs</h2>
                                    <p>JavaScript is a sophisticated foundation that allows for the building of extremely dynamic and responsive websites and applications that, in terms of load time and processing speed, are comparable to desktop software. It assists organizations in meeting consumer expectations by providing interactive interfaces that produce exceptional user experiences.</p>
                                    <p>Infograins provides front-end and full stack JavaScript programming services to construct business-centric solutions ranging from simple desktop apps to mobile web apps and complex enterprise solutions. Our JavaScript developers use tried-and-true methodologies and best practices to produce apps with breakneck speed, allowing businesses to innovate with robust, scalable, and high-performance solutions.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
