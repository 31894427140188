import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Row, Modal, Form, Button, Alert, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
export default function SingleArticle(props) {
    const [Success, setSuccess] = useState(false)
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [])
    // =========================== scroll To Top default =========================

    const location = useLocation();
    const filterApi_PathName = location.pathname.slice(1);
    // console.log(filterApi_PathName.split('/')[1]);
    // const [Pdf, setPdf] = useState({})
    // ========================= Single Blog api ================================
    const [blogContent, setBlogContent] = useState({})
    const blog_content = useRef(null)
    const [listData, setListData] = useState([])
    async function singleBlog() {
        const api = await axios.get(`${process.env.REACT_APP_BASE_URL}article/get_single_article/${filterApi_PathName.split('/')[1]}`);
        // console.log("=======", api.data);
        setListData(api.data.data)
        setBlogContent(api.data.data)
        // setPdf(api.data.pdf_data)
        blog_content.current.innerHTML = `${api.data.data.description.slice(0, 250)}...`
    }
    useEffect(() => {
        singleBlog()
    }, [filterApi_PathName])

    // ========================= Single Blog api ================================
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // ==============================================================================================================================
    const [DownloadError, setDownloadError] = useState(false)
    const [DownloadNow, setDownloadNow] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setBtnLoader(true)
        const finalData = { ...data, purpose: blogContent.title }
        console.log(finalData);
        const config = {
            name: finalData.name,
            email: finalData.email,
            contact: finalData.phone,
            title: finalData.purpose,
            about: finalData.requirements
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}article/form_data/${filterApi_PathName.split('/')[1]}/`, config).then((res) => {
            console.log(res);
            reset()
            setBtnLoader(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 5000);
            // window.open(Pdf.pdf, '_blank')
            // window.open('', '_blank')
            // setDownloadNow(true)
            document.body.style.overflow = "hidden"
            setTimeout(() => {
                document.body.style.overflow = "auto"
            }, 3000);
        }).catch((error) => {
            setDownloadError(true)
            setTimeout(() => {
                setDownloadError(false)
            }, 4000);
            setBtnLoader(false)
        })
    }
    // ==================================================================================================================================
    return (
        <>
            <section className='single_blog_page article_single_page'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='blog_body'>
                                <h2 className='h2_title'>{blogContent.title}</h2>
                                <img className='blog_img' src={blogContent.image} alt="" />
                                <div ref={blog_content} className="blog_content_div"></div>
                                <button className='read_more' onClick={handleShow}>Read more</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Download whitepaper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='article__form'>
                        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <Form.Group className="mb-3" controlId="user-name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name"
                                    {...register("name", {
                                        required: 'Name is required',
                                        pattern: {
                                            value: /\S\S+/g,
                                            message: "Field cannot be empty"
                                        }
                                    })}
                                />
                                {errors.name && (
                                    <Form.Text className="text-muted">
                                        <small className='text-danger'>{errors.name.message}</small>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email"
                                    {...register("email", {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
                                            message: "Invalid Email Address"
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <Form.Text className="text-muted">
                                        <small className='text-danger'>{errors.email.message}</small>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="user-number">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control type="number" min={0} placeholder="Enter number"
                                    {...register("phone", {
                                        required: 'Contact number is required',
                                        minLength: {
                                            value: 4,
                                            message: "The minimum length for this field is 4 characters"
                                        },
                                        maxLength: {
                                            value: 14,
                                            message: "The maximum length for this field is 14 characters"
                                        },
                                        pattern: {
                                            value: /\S\S+/g,
                                            message: "Field cannot be empty"
                                        }
                                    })}
                                />
                                {errors.phone && (
                                    <Form.Text className="text-muted">
                                        <small className='text-danger'>{errors.phone.message}</small>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Label>Tell us about your requirements</Form.Label>
                                <Form.Control as="textarea" rows={6} type="text" placeholder="Tell us about your requirements"
                                    {...register("requirements", {
                                        required: 'Requirements is required',
                                        pattern: {
                                            value: /\S\S+/g,
                                            message: "Field cannot be empty"
                                        },
                                    })}
                                />
                                {errors.requirements && (
                                    <Form.Text className="text-muted">
                                        <small className='text-danger'>{errors.requirements.message}</small>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            {/* {DownloadNow ?
                                <Button variant='success' onClick={download}>Download Now</Button>
                                : 
                                } */}
                            <Button variant="success" type="submit">
                                {btnLoader ? <Spinner animation='border' variant='light' size='sm' /> : 'Download pdf'}
                            </Button>
                            {Success &&
                                <p style={{
                                    background: "#7fff84",
                                    padding: "0.4em",
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    marginTop: "1em"
                                }}>Please Check your email for pdf.</p>
                            }
                            {/* <p style={{
                                background: "#ffb7bf",
                                padding: "0.4em",
                                textAlign: "center",
                                borderRadius: "5px",
                                marginTop: "1em"
                            }}>Please Check your email for pdf.</p> */}
                        </Form>
                    </div>
                    {DownloadError &&
                        <Alert variant='danger'>Something went wrong!</Alert>
                    }
                </Modal.Body>
            </Modal>

        </>
    )
}
