import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>CODEIGNITER DEVELOPMENT SOLUTIONS FOR YOUR BUSINESS</h2>
                                    <p>CodeIgniter is a widely used open-source PHP framework for creating scalable websites and online apps. It enables CI developers to create rapid, dynamic, and durable solutions for a variety of industries. Its MVC framework enables CI experts to design clever and engaging CI solutions. CI is popular among PHP developers due to its simplicity and ease of usage. CI has a relatively simple logical framework, reducing the requirement to write code from start. When compared to other PHP frameworks, CI requires almost less configuration, which aids in the development process.</p>
                                    <p>IInfograins is a renowned CodeIgniter programming company known for creating engaging websites and web apps for a wide range of organizations. We have a separate team of trained CI developers who specialize in providing our clients with quick and unique development solutions. CI provides some amazing features that make the development process simple and agile. We have some of the greatest CI professionals who have been working in PHP for over a decade.</p>
                                    <p>Our team of CI developers strives to provide solutions that help you scale up your business operations and reach new heights. Our CodeIgniter programming services are primarily focused on creating high-quality websites and web apps in the shortest amount of time feasible.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
