export const OurCompanyMenus = [
    // ================================== Our Company ==================================
    {
        type: "Our Company",
        list: "About Us",
        slug: "/aboutUs",
    },
    {
        type: "Our Company",
        list: "Contact Us",
        slug: "/contactUs",
    },
    {
        type: "Our Company",
        list: "Career",
        slug: "/career",
    },
    {
        type: "Our Company",
        list: "Mission and Vision",
        slug: "/aboutUs#mission",
    },
    {
        type: "Our Company",
        list: "Our Team",
        slug: "/team",
    },
    {
        type: "Our Company",
        list: "Our Alliances",
        slug: "/a-venture-with-cdn-solution",
    }
]