import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Why use Infograins for Object Recognition?</h2>
                <div className='description'>Infograins quickly identify and analyze several photos and videos. Our skilled team has domain knowledge and can improve business through real-time object identification activities. Multiple and repetitive jobs were examined by the AI and ML systems. Our organization transforms your business to import digital recognition requirements while developing a special and powerful object recognition program.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services