import Index from "./Route/Index";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/main.scss';
// import EventPopup from "./components/common/EventPopup";
function App() {
  return (
    <>
      <Index />
      {/* <EventPopup/> */}
      
    </>
  );
}

export default App;
