import React from 'react';
// import '../Style/Componentscss/Websection.css';

function Web() {
    return (
        <>
            <div className='Web_Body'>
                <div className='Web_Image'> 
                    <div className='Web_Img'></div>
                </div>
                <div className='Web_Content'>
                    <div className='Web_Heading'>
                        <h3>Get In Touch</h3>
                    </div>
                    <div className='Web_Paraone'>
                        <p>We are excited to be a part of Hyderabad's thriving technology ecosystem and look forward to collaborating with the local business community. If you want to know more about our services and the talented team behind them, please do not hesitate to contact us. Together, let us build a distributed and decentralized future!</p>
                    </div>
                    {/* <div className='Web_Paratwo'>
                        <p>Infograins respects your privacy and never divulges your information to third
                            parties.</p>
                    </div> */}
                    {/* <div className='Web_btn'>
                        <button className='Web_Click'><span>Click Me</span></button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Web 