import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Navigation Tools',
        content: ''
    },
    {
        title: 'Voice Recording',
        content: ''
    },
    {
        title: 'E-learning training',
        content: ''
    },
    {
        title: 'Interactive Voice Response',
        content: ''
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Advanced Text-to-Speech Technologies:</h2>
                        <p>1) Unit Synthesis Selection
                            It provides human-like voice without undergoing any signal alterations, although it is still regarded as artificial.
                            2) Articulatory Synthesis And Formant Synthesis
                            The technology offers recorded speech, speech with a defined sound quality, and much more.
                            3) Neural Synthes
                            Voice recordings and written scripts were initially employed as the voice source for this technique.
                        </p>
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements