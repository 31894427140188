import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from "react-js-loader";
import { logDOM } from '@testing-library/react';
export default function Articles(props) {
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [])
    // =========================== scroll To Top default =========================

    // ========================================== Featured Blog List =================================
    const [featuredData, setFeaturedData] = useState([])
    async function featuredApi() {
        const api = await axios.get(`${process.env.REACT_APP_BASE_URL}article/get_all_articles/`);
        // console.log(api.data.data);
        // return
        // setFeaturedData(api.data.response);
        setFeaturedData(api.data.data);
        // const title_tag = document.getElementsByTagName('title')
        // const meta_description = document.getElementsByTagName('meta');
        // meta_description.description.content = api.data.metacontent.content
        // title_tag[0].innerText = api.data.metacontent.title
    }

    useEffect(() => {
        featuredApi()
    }, [])

    // ========================================== Featured Blog List =================================
    // =============================== InfiniteScroll ==========================

    // ======================================= User Value =======================================
    const [userEnteredValue, setUserEnteredValue] = useState('')
    // ==================================== Search API =========================================

    const [dummy, setdummy] = useState([])
    const [LimitReached, setLimitReached] = useState(0)
    const [Count, setCount] = useState(0)
    // async function searchAPI() {
    //     setTimeout(async () => {
    //         let xo;
    //         xo = dummy.length + 5
    //         setCount(xo)
    //         const api = await axios.get(`${process.env.REACT_APP_BASE_URL}blog/search_query/title/?blog=${userEnteredValue}`);
    //         setLimitReached(api.data.response.length)
    //         setdummy(api.data.response.slice(0, xo))

    //     }, 500);
    // }

    // function userSearchValue(e) {
    //     // console.log("e.target.value", e.target.value);
    //     let eCode = e.target.value;
    //     if (eCode === '') {
    //         setUserEnteredValue("")
    //         searchAPI()
    //     } else {
    //         setUserEnteredValue(eCode)
    //         searchAPI()
    //     }

    //     // setUserEnteredValue(e.target.value)
    //     // searchAPI()
    // }
    // ==================================== Search API =========================================

    // useEffect(() => {
    //     searchAPI()
    // }, [dummy])

    // =============================== InfiniteScroll ==========================


    // ==================================================== Top Header API =============================================
    // const [HeaderData, setHeaderData] = useState({})
    // async function api() {
    //     try {
    //         const { data: { response } } = await axios.get(`${process.env.REACT_APP_BASE_URL}/blog/blog/blog_section_one/`)
    //         setHeaderData(response)

    //     } catch (error) {

    //     }
    // }
    // useEffect(() => {
    //     api()
    // }, [])


    return (
        <>
            <section className='blog_section'>
                <div className='top_section'>
                    <h2 className='h2_title'>WhitePaper</h2>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem totam aliquam fuga debitis repudiandae officiis velit tenetur facere nemo natus magni quod, porro fugiat eum molestias dolorum quidem eligendi ratione!</p> */}
                </div>
                <Container fluid>
                    <div className='blog_tab_section'>
                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="profile" title="Latest WhitePaper">
                                <Row>
                                    <Col lg={12} md={12}>
                                        <div className='all_blogs'>
                                            <Container>
                                                <Row>
                                                    {featuredData && featuredData.map((blog, key) => {
                                                        return <Col className='my-3' sm={6} xl={4} lg={4} md={6} key={key}>
                                                            <div className='blog_card'>
                                                                <img src={blog.image} alt="" />
                                                                <div className='blog_card_content'>
                                                                    <h3 className='h3_title' data-blog-title>{blog.title}</h3>
                                                                    <p>{blog.description}</p>
                                                                    <Link to={blog.slug}>Read More</Link>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </Container>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
                <Row>
                    <Col lg={12}>
                        <div className='blog_contact_form'>
                        </div>
                    </Col>
                </Row>
            </section>

        </>
    )
}
