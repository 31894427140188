import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap"
import whyCard1 from "../../../assets/images/hireDeveloperAdd/whyCard1.jpg"
import whyCard2 from "../../../assets/images/hireDeveloperAdd/whyCard2.jpg"
import whyCard3 from "../../../assets/images/hireDeveloperAdd/whyCard3.jpg"
import infograinsIcon from "../../../assets/images/infograins_icon.svg"
const HireAddWhyShould = () => {
    return (
        <>
            <section className='hireAddWhyShould_wrap'>
                <Container>
                    <div className='hireAddWhyShould_title'>
                        <div className='infograins_icon_img'>
                            <Image src={infograinsIcon} alt="infograinsIcon" fluid />
                        </div>
                        <h3 className='h3_title'><span>Why Should</span> You Hire <br /> Developers From Infograins?</h3>
                    </div>
                    <Row>
                        <Col sm={4} md={4} lg={4} xl={4}>
                            <div className='hireAddWhyShould_card_wrap'>
                                <figure className='hireAddWhyShould_card_img'>
                                    <Image src={whyCard1} alt='Why' fluid />
                                </figure>
                                <article className='hireAddWhyShould_card_article'>
                                    <h3 className='h3_title'>Certified Developers</h3>
                                    <p>Our team consists of certified developers. They are well-versed with the latest technologies and are familiar with the necessary tools to develop future-proof applications.</p>
                                </article>
                            </div>
                        </Col>
                        <Col sm={4} md={4} lg={4} xl={4}>
                            <div className='hireAddWhyShould_card_wrap'>
                                <figure className='hireAddWhyShould_card_img'>
                                    <Image src={whyCard2} alt='Why' fluid />
                                </figure>
                                <article className='hireAddWhyShould_card_article'>
                                    <h3 className='h3_title'>Customer-Centric Services</h3>
                                    <p>We deliver customized solutions as per client requirements. Our developers are trained to follow global quality standards.</p>
                                </article>
                            </div>
                        </Col>
                        <Col sm={4} md={4} lg={4} xl={4}>
                            <div className='hireAddWhyShould_card_wrap'>
                                <figure className='hireAddWhyShould_card_img'>
                                    <Image src={whyCard3} alt='Why' fluid />
                                </figure>
                                <article className='hireAddWhyShould_card_article'>
                                    <h3 className='h3_title'>Professional Consultation</h3>
                                    <p>Why take confusing advice when the pioneers are here to help you? Hire developers from us for free technical consultation with experienced developers.</p>
                                </article>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HireAddWhyShould