import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>DELIVERING STELLAR MOBILE APP DEVELOPMENT SERVICES</h2>
                                <p>We work with major international businesses to create cutting-edge digital products that provide flawless user interfaces on all current platforms and gadgets.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
