import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title: 'Enterprise Application Development',
        content: 'The user interfaces must be out of this world! Hire our TypeScript developers to create robust, engaging, and scalable applications.'
    },
    {
        title: 'Web Application Development',
        content: 'We studied the TS scripting language and combined it with JS to create innovative web apps that outperform the competition.'
    },
    {
        title: 'TypeScript App Development',
        content: 'In banking and healthcare, education and retail, intuitive apps are what you need to shine. We make things happen.'
    },
    {
        title: 'TypeScript Consultation',
        content: 'Do you intend to develop new online and mobile apps? Alternatively, might TS be integrated into the current system? Please contact us to discuss.'
    },
    {
        title: 'Maintenance & Support',
        content: 'Looking for newer versions? We use the TS scripting language for a variety of purposes. In corporate software, maintenance and support services are critical.'
    },
    {
        title: 'JS to TS Migration',
        content: 'Many people are hesitant to migrate! With our practical approach, converting from the current JavaScript to TypeScript poses no risks. A straightforward procedure.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our TypeScript Development Services</h2>
                                {/* <p>Python has grown in popularity for a variety of reasons, including:</p> */}
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
