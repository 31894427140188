import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import joyStick from "../../../assets/images/background/games/joystick.png"

const GameSolution = () => {
    return (
        <>
            <section className='GameSolution-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}><div className='GameSolution-text'>
                            {/* <h3 className='h3_title'>subheading</h3> */}
                            <h2 className='h2_title'>IOT Development Services</h2>
                            <p>Transferring technical responsibility to an experienced tech partner is a wise decision for an innovative startup or enterprise. You may rely on Infograins as a knowledgeable technology partner with our IoT development services. We can assist you in creating customized IoT solutions infrastructure, digitalizing analog products, and building smart devices. Our IoT consulting strategists can assist you in navigating a comprehensive company shift with ease.</p>
                            <div className="center">
                                <a href="#"><span data-attr="Click">Click</span><span data-attr="Me">Me</span></a>
                            </div>
                        </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='GameSolution-img'>
                                <Image src={joyStick} alt='GameSolution' fluid />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameSolution