import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GameHero from './GameHero'
import GameNews from './GameNews'
import GameSolution from './GameSolution'
import OurGame from './OurGame'
import { Helmet } from 'react-helmet'

const UnityGame = (props) => {
  const { game_slug } = useParams()
  // =========================== scroll To Top default =========================
  useEffect(() => {
    props.demo('top')
  }, [game_slug])
  // =========================== scroll To Top default =========================
  return (
    <>
      <Helmet>
        <title>IOT App Development Company – Infograins</title>
        <meta name="description" content="Infograins is a leading IOT development company across the globe helping startups and enterprises build intelligent IOT development services." />
      </Helmet>
      <GameHero />
      <GameSolution />
      <OurGame />
      <GameNews />
    </>
  )
}

export default UnityGame