import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Data = [
    {
        title: 'Custom Software Solutions',
        para: 'Our custom software development services are designed to address your unique business challenges. We work closely with you to create bespoke solutions that streamline operations, enhance productivity, and drive growth. Experience software that fits your business like a glove.'
    },
    {
        title: 'Mobile App Innovation',
        para: 'Transform your mobile app ideas into reality with our expert mobile app development services. We bring creativity, functionality, and user-friendliness to your app, ensuring it stands out in today\'s competitive digital landscape. Elevate your brand through engaging mobile experiences.'
    },
    {
        title: 'Remote Insourcing Solutions',
        para: 'Our remote insourcing services empower you to expand your team virtually and access global talent. Enjoy the benefits of a dedicated, cost-effective, and scalable workforce without geographical constraints. Seamlessly integrate remote team members into your projects.'
    },
    {
        title: 'Digital Transformation Hub',
        para: 'Dive into the future of digital innovation with our comprehensive suite of services. Blockchain, AI /ML, IOT, Chatbot development and more, we help you harness the latest technologies to revolutionize your business processes, drive efficiency, and stay ahead of the curve. Explore boundless digital possibilities with us.'
    }
]

export default function Section4() {
    return (
        <>
            <section className='section_four'>
                <Container>
                    <Row>
                        <Col lg={12} className='title'>
                            <h2>Innovative Solutions for a <span> Digital World</span> </h2>
                        </Col>
                        {Data.map(({ title, para }, key) => (
                            <Col xl={6} lg={6} md={6} sm={12} key={key} className='phases_wrapper'>
                                <div className='phases'>
                                    <span>0{key + 1}</span>
                                    <h2>{title}</h2>
                                    <p>{para}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    )
}
