import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Multi-Platform Presence Establishment with Low Investment</h2>
                                <div className='description'>React Native ends the age-old conundrum that mobile app entrepreneurs confront when deciding on a cost-effective solution for react native app development because it is supported by Facebook's reliability and JavaScript's simplicity. We are the React Native framework-based app development firm that can assist you in creating a faster-to-create and -to-use app with a native-like user experience.</div>
                                <div className='description'>We are experts at producing applications with near-native quality that need little work and time to develop. We have incredibly honed processes and procedures that enable us to effectively launch hundreds of cutting-edge solutions across the globe as a top React Native app development business.
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies