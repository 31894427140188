import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Node JS Development Services ',
        content: 'Infograins is a prominent Nodejs development firm with considerable experience in creating customer-centric software solutions and business-oriented apps.'
    },
    {
        title:'Node.js web Development',
        content: 'Use our Node.js web application development services to create unique node.js web applications. Our Node.js consultant will help your company thrive and stay ahead of the competition.'
    },
    {
        title:'Node.js Product Development',
        content: 'Develop lightweight, scalable, feature-rich, and lightning-fast web applications to give your business a boost. Use our result-driven Node.js development services to delight your customers and increase usability and engagement.'
    },
    {
        title:'Back-end Dashboards Development',
        content: 'As the leading Node JS web development firm, clients all over the world rely on Node JS development services to create dashboard solutions that are personalized to their specific business needs.'
    },
    {
        title:'Node js Blockchain Application',
        content: 'Our services are not restricted to Node.js software development; our Node.js experts are well-versed in developing Blockchain-based BaaS solutions that efficiently support cryptocurrency exchange.'
    },
    {
        title:'Node.js API development and Integration',
        content: 'We deliver real-time and data-intensive scalable solutions regardless of the development environment, starting with the implementation of bespoke APIs in Node.js to ensure easy integration with any solutions.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                {/* <h2 className='h2_title'>Our Next JS Development Services</h2> */}
                                {/* <p>We provide server-side application development services utilizing Next.js, one of the quickest JavaScript frameworks, as a reputable NextJS development firm. Our developers leverage Next.js' capabilities to build applications that are feature-rich, quick, and suited to your specific business needs. Infograins is dedicated to provide high-quality NextJS development services. Hiring a NextJS developer will ensure that the total process is smooth, high-performing, and scalable.</p> */}
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
