import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Infograins As A Blockchain Development Company?</h2>
                                <div className='description'>Regardless matter the intricacy of your project, we are dedicated to delivering
                                    assured results.Infograins provides best blockchain development services by ensuring transparency in
                                    transactions, as all participants in the network have access to the same information, fostering trust
                                    and accountability.end-to-end solutions by enhancing transparency and security for enterprises.
                                    Infograins Blockchain Development services empower enterprises with a secure, transparent, and efficient
                                    technology infrastructure. By providing end-to-end solutions, you can position your company as a trusted
                                    partner for businesses looking to harness the full potential of blockchain for their growth and
                                    innovation.
                                </div>
                                {/* <ul>
                                    <li> 360° Holistic Approach</li>known
                                    <li> Dynamic Engagement Model</li>
                                    <li> Client Participation at Each Stage</li>
                                    <li> Use of Latest Technologies</li>
                                    <li> Center of Excellence and Research Team</li>
                                    <li> Agile and Lean Project Execution Approach</li>
                                </ul> */}
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies