import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Creating and designing 2D video games.',
        content: 'Have a concept for an iOS app? To find the best Swift iOS app development solution, contact our knowledgeable team of Swift developers today.'
    },
    {
        title: 'Interactive program in 2D or 3D.',
        content: 'For iOS app development, we specialize in meeting the specific needs of each of our clients. Tell us about your specific requirements for developing apps, and we\'ll provide a solid solution.'
    },
    {
        title: 'Designing and creating 3D video games.',
        content: 'We can assist you with all of your swift app migration needs, whether you need to move a legacy app to the swift ecosystem, convert a cross-platform development into a native iOS app solution, or vice versa.'
    },
    {
        title: 'The creation of augmented reality-based video games.',
        content: 'Utilize our knowledge of the swift programming language and game development to create an immersive iOS-compatible game that lives up to your greater expectations for video games.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>What We Offer in the Development of Unity 3D Games</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements