import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
const Owl = () => {

    return (
        <>
            <div className='w3-OwlCarousel-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='image_div'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_four/web_paragraph-3_zj1uhh'} fluid />
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='hero_slide_section_content'>
                                <div className='hero_content_div'>
                                    {/* <h3 className='h3_title hero_cont_subheading'>{BlockchainCate.subheading}</h3> */}
                                    <h2 className='h2_title hero_cont_heading'>Data Transformation</h2>
                                    <div className='hero_cont_para'>Infograins, a leader in the field with years of experience in data discovery and transformation, provides the quickest and most straightforward method for extracting data from Big Data and other structured sources as well as from challenging semi-structured data, such as PDFs, spreadsheets, and text files. Infograins can automate preparation operations and turn your data into accurate and clean datasets in seconds rather than hours or days, freeing you up to spend time on value-adding activities rather than on boring, repetitive, and error-prone tasks. This is true whether the data is on-premises or in the cloud.
                                    </div>
                                    {/* <button className='hero_cont_btn'>Click Me</button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Owl