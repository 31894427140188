import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    {/* <h2 className='h2_title'>Benefits</h2> */}
                                    <p>Node.js is gaining popularity because to its extensive data exchange expertise in multi-media, chat services, and other high-data-demanding services such as real-time tracking. Infograins is a prominent Node JS web development firm that specializes in the deployment of cloud-based and server-less apps. We specialize in developing NodeJS apps that demand asynchronous programming, scalability, and high speed, such as chatting, IoT data processing, and collaborative tools. We offer NodeJS development services to create highly scalable and real-time web apps, continually delivering cutting-edge solutions that meet our clients' business needs.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
