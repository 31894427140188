import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Get a Full-Service Web Testing Solutions</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>We determine how to test your web application based on the requirements of your project. If you need website quality assurance testing for a static website, we will concentrate on GUI design and non-functional tests. Functional testing and automation are more important in dynamic web programs. For each project, we create a unique testing strategy.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
