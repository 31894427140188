import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import elipse from '../../../assets/images/engagementModel/elipse.svg'

export default function ResourceModel() {
  return (
    <>
      <section className='resource_section'>
        <Container>
          <Row>
            <Col xl={2} lg={2} className="business_col">
              <div className="head">
                <h2>Business Models</h2>
              </div>

            </Col>
            <Col xl={10} lg={10}>
              <Row>
                <Col lg={6} md={6}>
                  <div className="resource_left_content">
                    <h2>Resource Allocation Model</h2>
                    <p>This model is flexible for specialists' time allocation according to the project's requirements.
                      The Resource Allocation Model is best for projects where the scope and specifications are not defined clearly.
                      Infograins will define a well-suited model for your business requirements.
                    </p>
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="resource_right_content">
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>We will provide you with the best resources to shape your requirements.</p>
                    </div>
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>You will only pay for the services and resources we offer.</p>
                    </div>
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>You can discontinue once the duration ends without inconvenience.</p>
                    </div>
                    <div className="desc">
                      <div className="image_box">
                        <Image src={elipse} />
                      </div>
                      <p>Our business model will adapt to unfolding requirements.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}
