import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, Container, Image, Row } from 'react-bootstrap'
import IPLForm from './Form'
import IPLPrediction from '../../assets/media/man-playing.jpg'
export default function IPLpredictionIndex() {
    return (
        <>
            <Header />
            <section className='agriculture_prediction_page'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <Image src={IPLPrediction} alt='agriculture-ai' fluid/>
                        </Col>
                        <Col lg={6}>
                            <h1>IPL Win Predictor</h1>
                            <IPLForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
