import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
const listStyle = {
    fontWeight: "400",
    fontSize: "16px",
    color: "#47576a",
    letterSpacing: "0.7px",
    marginBottom: "0px",
    paddingLeft: '1em',
    marginTop: '1em'
}
const NFTCate = [
    {
        title: 'IOS APP Development',
        content: 'We have all the skills required to create complete, reliable, and scalable mobile applications, such as:',
        list: <ul style={listStyle}>
            <li>iPhone App Development</li>
            <li>iPad App Development</li>
            <li>Apple Watch App Development</li>
            <li>Apple TV App Development</li>
            <li>App Clips Development</li>
        </ul>
    },
    {
        title: 'Android APP Development',
        content: 'We assist companies in establishing a presence on any contemporary gadget or platform using the most recent technology, including:',
        list: <ul style={listStyle}>
            <li>Android Mobile App Development</li>
            <li>Android TV App Development</li>
            <li>Android Tablet App Development</li>
            <li>Android Wear App Development</li>
        </ul>
    },
    {
        title: 'Cross-Platform APP Development',
        content: 'Get premium mobile applications that work across numerous platforms for affordable prices while giving your users an app that is compatible with their OS. Utilize this alternative to cut costs and time to market, reach more users, and maintain quality, including:',
        list: <ul style={listStyle}>
            <li>Hybrid Mobile App Development</li>
            <li>Hybrid Tablet App Development</li>
            <li>Hybrid TV App Development</li>
        </ul>
    }
]
export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>ADVANCED MOBILE APP DEVELOPMENT TECHNOLOGIES WE ARE EXPERIENCED IN</h2>
                                <p>Use our vast expertise working with all key technologies to delight your users with expressive and feature-rich native IOS / Android, cross-platform, or Progressive Web Apps.</p>
                            </div>
                        </Col>
                        {NFTCate.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                    {e.list}
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
