import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Amazon Alexa Skills Development</h2>
                <div className='description'>Our team of Alexa Skills developers at Infograins has years of experience and expertise creating intelligent Alexa skills for apps.</div>
                <div className='description'>Our Alexa skill allows users to effortlessly browse through the services or products with the use of a voice recognition system if you are creating an information-rich database. Allow Alexa to aid in product discovery for customers of your e-commerce app. No matter what industry you are in, it will be a service that adds value for your clients.</div>
                <div className='description'>According to your company's demands and specifications, we provide custom Alexa Skills development services. We have extensive expertise creating Alexa Skills for your company's services using AWS Lambda and custom server environments.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services