import React, { useEffect } from 'react';
import BlogSection from './BlogSection';
import ContactUs from './ContactUs';
import HeroSection from './HeroSection';
import LaunchPlatform from './LaunchPlatform';
import OnlineStore from './OnlineStore';
import PartnerStrip from './PartnerStrip';
import SideBoxSection from './SideBoxSection';
import StartSomething from './StartSomething';
import Technology from './Technology';
import Testimonial from './Testimonial';
import WhyChoose from './WhyChoose';
import { Helmet } from 'react-helmet';
import KeyPoints from './KeyPoints';

const Home = (props) => {
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [])
    // =========================== scroll To Top default =========================

    return (
        <>
            <Helmet>
                <meta name="description" content="Infograins is a leading software development company across the globe. Trusted team of 200+ skilled engineers, designers & developers providing top-notch tailored software solutions. " />
            </Helmet>
            <HeroSection />
            <KeyPoints />
            {/* <PartnerStrip /> */}
            <Technology />
            <OnlineStore />
            <StartSomething />
            <LaunchPlatform />
            <SideBoxSection />
            <WhyChoose />
            <BlogSection />
            <Testimonial />
            <ContactUs />
        </>
    )
}

export default Home