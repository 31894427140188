import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import requirement from '../../../assets/images/hire/icons/requirement.png'
import layer from '../../../assets/images/hire/icons/Layer.png'
import testing from '../../../assets/images/hire/icons/Testing.png'
import deployment from '../../../assets/images/hire/icons/Deployment.png'
import support from '../../../assets/images/hire/icons/Support.png'
export default function Talent() {
    return (
        <>
            <section className='talent_section'>
                <Container>
                    <Row className='justify-content-center '>
                        <Col lg={12}>
                            <div className="head">
                                <h2>What are IT Staffing Services?</h2>
                                <p>IT Staffing Services can give completely managed teams or lone professional Engineers, depending on how much additional talent your projects require. These staffing models are the most typical.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={requirement} fluid />
                                <h4>Remote & Nearshore Staffing</h4>
                                <p>The top IT specialists are not only hard to find but also dispersed widely around the globe. We employ remote approaches to get our clients access to the Top 1% of IT Talent in the area and get over any obstacles posed by location.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={layer} fluid />
                                <h4>On-Demand Talent</h4>
                                <p>On-Demand Staffing's great flexibility is necessary for some tasks. With the help of this approach, businesses can hire qualified software developers whenever they're needed for however long.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={testing} fluid />
                                <h4>On-Site Engineers</h4>
                                <p>The most difficult and lengthy projects that call for the actual presence of software engineers and IT specialists during the whole development process are best suited for on-site engineers.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={deployment} fluid />
                                <h4>Managed Solutions</h4>
                                <p>Managed Solutions are the most comprehensive type of IT Staffing Services, including anything from IT consultancy to full-scale IT teams. To solve important business objectives, this strategy builds teams of highly qualified individuals specifically for each client.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
