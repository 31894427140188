import axios from 'axios'
import React, { useState } from 'react'
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
export default function AgricultureForm() {
    const [Result, setResult] = useState('')
    const [Loader, setLoader] = useState(false)
    const { register, reset, formState: { errors }, handleSubmit } = useForm()
    const FormInput = [
        {
            id: 'a1',
            controlId: 'formGroupNitrogen',
            label: 'Nitrogen',
            labelDetail: 'Range between (0-140)',
            type: 'number',
            placeholder: 'Enter Nitrogen',
            min: 0,
            max: 140,
            validateProp: {
                ...register('Nitrogen', {
                    required: 'Please enter value of Nitrogen',
                    min: {
                        value: 0,
                        message: "Minimum value is 0",
                    },
                    max: {
                        value: 140,
                        message: "Maximum value is 140",
                    }
                })
            }
        },
        {
            id: 'a2',
            controlId: 'formGroupPhosphorus',
            label: 'Phosphorus',
            labelDetail: 'Range between (5-145)',
            type: 'number',
            placeholder: 'Enter Phosphorus',
            min: 5,
            max: 145,
            validateProp: {
                ...register('Phosphorus', {
                    required: 'Please enter value of Phosphorus',
                    min: {
                        value: 5,
                        message: "Minimum value is 5",
                    },
                    max: {
                        value: 145,
                        message: "Maximum value is 145"
                    }
                })
            }
        },
        {
            id: 'a3',
            controlId: 'formGroupPotassium',
            label: 'Potassium',
            labelDetail: 'Range between (5-205)',
            type: 'number',
            placeholder: 'Enter Potassium',
            min: 5,
            max: 205,
            validateProp: {
                ...register('Potassium', {
                    required: 'Please enter value of Potassium',
                    min: {
                        value: 5,
                        message: "Minimum value is 5",
                    },
                    max: {
                        value: 205,
                        message: 'Maximum value is 205'
                    }
                })
            }
        },
        {
            id: 'a4',
            controlId: 'formGroupTemperature',
            label: 'Temperature',
            labelDetail: 'Range between (8-43)',
            type: 'number',
            placeholder: 'Enter Temperature',
            min: 8,
            max: 43,
            validateProp: {
                ...register('Temperature', {
                    required: 'Please enter value of Temperature',
                    min: {
                        value: 8,
                        message: "Minimum value is 8",
                    },
                    max: {
                        value: 43,
                        message: 'Maximum value is 43'
                    }
                })
            }
        },
        {
            id: 'a5',
            controlId: 'formGroupHumidity',
            label: 'Humidity',
            labelDetail: 'Range between (14-99)',
            type: 'number',
            placeholder: 'Enter Humidity',
            min: 14,
            max: 99,
            validateProp: {
                ...register('Humidity', {
                    required: 'Please enter value of Humidity',
                    min: {
                        value: 14,
                        message: "Minimum value is 14",
                    },
                    max: {
                        value: 99,
                        message: 'Maximum value is 99'
                    }
                })
            }
        },
        {
            id: 'a6',
            controlId: 'formGrouppH',
            label: 'pH',
            labelDetail: 'Range between (3-9)',
            type: 'number',
            placeholder: 'Enter pH',
            min: 3,
            max: 9,
            validateProp: {
                ...register('pH', {
                    required: 'Please enter value of pH',
                    min: {
                        value: 3,
                        message: "Minimum value is 3",
                    },
                    max: {
                        value: 9,
                        message: 'Maximum value is 9'
                    }
                })
            }
        },
        {
            id: 'a7',
            controlId: 'formGroupRainfall',
            label: 'Rainfall',
            labelDetail: 'Range between (20-298)',
            type: 'number',
            placeholder: 'Enter Rainfall',
            min: 20,
            max: 298,
            validateProp: {
                ...register('Rainfall', {
                    required: 'Please enter value of Rainfall',
                    min: {
                        value: 20,
                        message: "Minimum value is 20",
                    },
                    max: {
                        value: 298,
                        message: 'Maximum value is 298'
                    }
                })
            }
        },
    ]

    const onSubmit = async (data) => {
        setLoader(true)
        const bodyData = new FormData()
        bodyData.append("Nitrogen", Number(data.Nitrogen))
        bodyData.append("Phosporus", Number(data.Phosphorus))
        bodyData.append("Potassium", Number(data.Potassium))
        bodyData.append("Temperature", Number(data.Temperature))
        bodyData.append("Humidity", Number(data.Humidity))
        bodyData.append("Ph", Number(data.pH))
        bodyData.append("Rainfall", Number(data.Rainfall))
        try {
            const api = await axios.post('https://dp.infosense.tech/core/api/predictcropApi', bodyData)
            console.log(api.data.result);
            setResult(api.data.result)
            setLoader(false)
            reset()
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }

    const FormField = ({ controlId, label, labelDetail, type, placeholder, min, max, validateProp: { name, ...rest } }) => (
        <Form.Group className="mb-3" controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control type={type} placeholder={placeholder} name={name} {...rest} min={min} max={max} onWheel={(e) => e.target.blur()} />
            <Form.Text>{labelDetail}</Form.Text>
            {errors[name] &&
                <p className="know-erroe-text">
                    {errors[name].message}
                </p>}
        </Form.Group>
    )

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {FormInput.map(({ id, ...options }) => (
                        <Col lg={6} key={id}>
                            <FormField {...options} />
                        </Col>
                    ))}
                    <Col lg={6}>
                        <Form.Group className="pb-3 d-flex align-items-end h-100" controlId="formGroupEmail">
                            {
                                Loader ?
                                    <div className="btn btn-primary w-100 disabled"><Spinner animation='border' size='sm' /></div>
                                    :
                                    <Button className='w-100' variant="primary" type="submit">
                                        Submit
                                    </Button>
                            }
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <h2>Result : {Result}</h2>
        </>
    )
}