import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Ecommerce Web/App Testing services</h2>
                                <p>At Infograins, we offer a comprehensive suite of eCommerce web and app testing services to ensure the flawless performance, security, and user experience of your online store. Our expert testing team is dedicated to helping you provide your customers with a seamless shopping experience, ultimately boosting your eCommerce success. </p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
