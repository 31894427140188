import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AgricultureForm from './Form'
import AgricultureAi from '../../assets/media/agriculture-ai.png'
export default function AgricultureIndex() {
    return (
        <>
            <Header />
            <section className='agriculture_prediction_page'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <Image src={AgricultureAi} alt='agriculture-ai' fluid/>
                        </Col>
                        <Col lg={6}>
                            <h1>Agriculture Crop Recommendation System</h1>
                            <AgricultureForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
