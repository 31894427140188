import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import news from "../../../assets/images/trusted/aceGame2.jpg"

const GameNews = () => {
    const GameCate = [
        {
            title: "Less Time & Money",
            content: "The varied approaches to IoT hardware prototyping result in time and money savings. Risk management is now less likely, and it is possible to rule out the necessity of investing more time and money in post-deployment device issues. This kind of pricey repair would have a devastating effect on the morale of your employees and your clientele. Not to mention the money you will lose if the job is not completed."
        },
        {
            title: "Gather Useful Information",
            content: "The design aspect of IoT prototyping is to collect useful information. The connectivity shows the level of latency that is much more powerful than what was required and, consequently, makes the unique design not adequate. Moreover, prototyping a device might reveal insufficient range, is apt to overheat or runs out of battery sooner than expected."
        },
        {
            title: "Large Range of Sensors",
            content: "An IoT device must include sensors in order to collect useful data and assess the environment. To detect humidity, pressure, temperature, smoke, gas, radiation, vibration, water flow, speed, expedition, closeness, GPS position, elevation, and other changeable elements, it makes use of a wide variety of sensors."
        },
        {
            title: "Faster Speed",
            content: "Faster applications are produced using IoT prototyping. Thanks to the company's innovative processes and cutting-edge technology, it is feasible. We offer the quickest and most effective services for IoT hardware prototyping. There are several sensors, each of which has a unique execution time. The functionality is also simple and reasonably priced."
        },
    ]
    return (
        <>
            <section className='gameNews-wrap'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='text-center mb-4'>
                                {/* <h4 className='h4_title'>subheading</h4> */}
                                <h2 className='h2_title'>Benefits of IOT Hardware Prototyping</h2>
                                {/* <p className='description'>description</p> */}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                {
                                    GameCate.map((e, key) => {
                                        return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                            <div className='gameNews-card-wrap'>
                                                <figure className="gameNews-card-img">
                                                    <Image src={news} alt='News' fluid />
                                                </figure>
                                                <div className='gameNews-card-detail'>
                                                    <h3 className='h3_title'>{e.title}</h3>
                                                    <p>{e.content}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameNews