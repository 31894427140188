import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Building Alexa Abilities is Essential for Future Development</h2>
                                <div className='description'>There's no denying that all businesses will eventually need to focus on developing their Alexa abilities. This will result from the upcoming transition from developing mobile apps to developing Alexa skills. All businesses will need to adapt as customers start to anticipate Alexa voice commands to be available while using your systems.</div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies