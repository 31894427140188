import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Our Mobile App Testing Services",
            content: "At Infograins we offer a comprehensive suite of mobile app testing services, including"
        },
        {
            title: "Functional Testing",
            content: "We carefully test your app's functionality to ensure all features work as intended, identifying and addressing issues before they reach your users."
        },
        {
            title: "Compatibility Testing",
            content: " Our team verifies that your app works seamlessly on different devices, screen sizes, and operating systems to guarantee a consistent user experience."
        },
        {
            title: "Performance Testing",
            content: "We evaluate your app's speed, responsiveness, and stability under various conditions to optimize its performance."
        },
        {
            title: "Security Testing",
            content: "Protect your app and user data from vulnerabilities with our thorough security testing, including penetration testing and code analysis."
        },
        {
            title: "Usability Testing",
            content: "We assess the app's user interface and overall user experience to ensure it meets the expectations and needs of your target audience."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                {/* <h2 className='h2_title'>Our Services for Full-Stack Web App Development</h2> */}
                                {/* <p>We have a fantastic team of web development experts who are always testing and applying new ideas that will turn website designs into a gold mine for your company.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
