import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function Banner() {
    return (
        <>
            <section className='case_banner'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                <h2>Our Achievements</h2>
                                <p>Explore how we think, perform and ensure client satisfaction.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
