import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Banking and Finance',
        para: 'Blockchain application development services have many benefits in terms of better record-keeping, transparency, and security. For financial requirements like anti-money laundering, customer onboarding, or fraud prevention, we offer the best solution.'
    },
    {
        title: 'Healthcare Technology',
        para: 'Blockchain technology enables pharmaceutical businesses to track drugs across the whole supply chain, which makes it more difficult for thefts and enables quick discovery of discrepancies. Blockchain features guarantee data protection, integrity, and system access logs, enhancing security.'
    },
    {
        title: 'Logistics',
        para: 'To facilitate the expansion of shared mobility, two people must be able to communicate with each other directly without the use of a third party. Our blockchain-based technologies enable communication between these linked devices or automobiles.'
    },
    {
        title: 'Real Estate',
        para: 'You may progress and streamline your footprint and process by working with our blockchain developers to increase energy utilization in commercial and industrial buildings using our specialized IoT and blockchain solutions for energy distribution.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Our Blockchain Development Industries</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card