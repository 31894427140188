import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Your one-stop IoT testing solution",
            content: "Infograins provides comprehensive security, performance, interoperability, robustness, In-the-Wild, mobile app, and telematics testing services. We apply our proprietary agile quality assurance for IoT to assist you in meeting your goals and objectives."
        },
        {
            title: "Extensive industrial knowledge",
            content: "We deliver outcomes by combining customer-centric business models, critical thinking, and the ability to obtain a profound understanding of your goals and challenges with our technology-specific capabilities and unique testing-focused assets."
        },
        {
            title: "Regulatory Expertise",
            content: "We use our regulatory requirement expertise in various industries and countries to check regulatory compliance and communicate the details of your compliance, ensuring the success of your IoT product launch."
        },
        {
            title: "Capabilities for real-world testing",
            content: "For testing, we exclusively utilize actual devices. We believe that emulators and simulators do not guarantee real-world outcomes as a pure play testing firm."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>OUR Services</h2>
                                {/* <p>Infograins exemplifies creativity, consumer focus, and technology innovation through well-defined design procedures. We produce responsive and adaptive designs with consistent cross-platform compatibility using a unique blend of modern technologies and new ideas. We are known in the design industry for our creative zeal and hands-on knowledge in all elements of UI/UX, and we have over two decades of experience. We understand what it takes to establish a brand from a design standpoint and how to do so with modern technologies and methods of thinking. We assist customers in thriving by simple designs that can leave a lasting impression on the minds of users, increasing brand awareness and loyalty.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
