import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BoxSections from './BoxSections'
import ContentDiv from './ContentDiv'
import HeroSections from './HeroSections'
import KeyFeature from './KeyFeature'
import { Helmet } from 'react-helmet'

export default function ReactJs(props) {
    const { nft_slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [nft_slug])
    // =========================== scroll To Top default =========================
    return (
        <>
           <Helmet>
                <title>Trusted React JS Development Company – Infograins</title>
                <meta name="description" content="As a leading React JS development company, Infograins offers top-class React JS web and app development services to our clients globally." />
            </Helmet>
            <HeroSections />
            <BoxSections />
            <ContentDiv />
            <KeyFeature />
        </>
    )
}
