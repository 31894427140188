import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import axios from 'axios';
import { Link } from 'react-router-dom';
export default function AllCases() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const options = {
        // margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: false,
        autoplayHoverPause: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 5,

            },
            1200: {
                items: 7
            }
        },
    };

    function prev() {
        document.getElementsByClassName('owl-prev')[0].click()
    }
    function next() {
        document.getElementsByClassName('owl-next')[0].click()
    }

    const [filterKey, setFilterKey] = useState("All")
 
    function selectFilterTag(e) {

        setFilterKey(e.target.name)
        // const allTags = document.getElementsByClassName('tag')
        // for (let i = 0; i < allTags.length; i++) {
        //     if (allTags[i].classList.contains('active')) {
        //         allTags[i].classList.remove('active')
        //     } else {
        //         e.target.classList.add('active')
        //     }
        // }
       
    }
    const [PageCount, setPageCount] = useState(1)

    // ============================================= Filtered Tags API =================================
    const [TagData, setTagData] = useState([])
    async function getTagsAPI() {
        try {
            const { data: { response } } = await axios.get('https://infograinsbackend.in/case-study/get-categories/')
            console.log("tags",response);
            setTagData(response)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTagsAPI()
    }, [])
    // ============================================= Filtered Tags API =================================
    // ============================================= All Case Studies Tags API =================================
    const [AllCaseData, setAllCaseData] = useState([])
    const [AllCaseRes, setAllCaseRes] = useState({})
    async function getAllCaseDataAPI() {
        try {
            const { data: { response } } = await axios.get(`https://infograinsbackend.in/case-study/get-case-study/?category=${filterKey}&p=${PageCount}`)
            console.log("details", response);
            setAllCaseRes(response)
            setAllCaseData(response.results)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllCaseDataAPI()
    }, [PageCount, filterKey])
    // ============================================= All Case Studies Tags API =================================

    // ================================================== Case Pagination =================================
    function prevCaseBtn(e) {
        console.log(e.target.name);
        setPageCount(e.target.name)
    }

    function nextCaseBtn(e) {
        console.log(e.target.name);
        setPageCount(e.target.name)
    }

    return (
        <>
            <section className='allCases'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='tags_wrapper'>
                                {/* <button className='prevBtn' onClick={prev}><AiOutlineArrowLeft /></button> */}
                                {TagData.length > 1 ?
                                    <OwlCarousel className='owl-theme hero_slider' margin={10} {...options}>
                                        {TagData.map(({ category_with_count, id, name },i) => {
                                            return <div key={i} className='item'>
                                                <button onClick={selectFilterTag} name={name}  className={name == filterKey ? "tag active" : "tag"}>{category_with_count}</button>
                                            </div>
                                        })}
                                    </OwlCarousel>
                                    : ''}
                                {/* <button className='nextBtn' onClick={next}><AiOutlineArrowRight /></button> */}
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className='justify-content-center'>
                        {AllCaseData.map(({ title, content, technology, case_study_url, image, slug }, id) => {

                            return <Col lg={4} md={6} sm={12} key={id}>
                                <div className='case_box'>
                                    <div className='case_image'>
                                        <Image fluid src={image} alt='case_image' />
                                    </div>
                                    <div className='case_content'>
                                        <h4>{title}</h4>
                                        <p>{content && content.length > 88 ? `${content.slice(0, 88)}...` : content}</p>
                                        <div className='case_tag_wrapper'>
                                            {technology.map(({ name }, key) => {
                                                return <span key={key} className='case_tags'>{name}</span>
                                            })}
                                        </div>
                                    </div>
                                    <Link className='view_details' to={slug}>View details</Link>
                                </div>
                            </Col>
                        })}
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg={4}>
                            <div className='case_pagination_div'>
                                <button onClick={prevCaseBtn} className='prev_btn' name={AllCaseRes ? AllCaseRes.previous === null ? '' : AllCaseRes.previous : ''} disabled={AllCaseRes ? AllCaseRes.previous === null ? true : false : ''}>Prev</button>
                                <button onClick={nextCaseBtn} className='next_btn' name={AllCaseRes ? AllCaseRes.next === null ? '' : AllCaseRes.next : ''} disabled={AllCaseRes ? AllCaseRes.next === null ? true : false : ''}>Next</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
