import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import finance from '../../../assets/images/hire/icons/finance.png'
import health from '../../../assets/images/hire/icons/health.png'
import insurance from '../../../assets/images/hire/icons/insurance.png'
import transport from '../../../assets/images/hire/icons/transport.png'
import realState from '../../../assets/images/hire/icons/realState.png'
import gasoil from '../../../assets/images/hire/icons/gasoil.png'
import chain from '../../../assets/images/hire/icons/chain.png'

export default function Process({ data }) {
    return (
        <>
            <section className='process_section'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className="head">
                                <h2>Industries We Serve</h2>
                            </div>
                        </Col>
                        {/* {data ? data.map(({title, content, id}) => {
                            return <Col lg={3} key={id}>
                                <div className='team_content'>
                                    <Image src='https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg' fluid />
                                    <h4>{title}</h4>
                                    <p>{content}</p>
                                </div>
                            </Col>
                        }) : ''} */}

                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={finance} fluid />
                                <h4>Finance</h4>
                                <p>Our developers can integrate technology with finance to accelerate the Fintech revolution. We help businesses harness the true potential of different technologies.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={transport} fluid style={{width:"70px"}} />
                                <h4>Transportation</h4>
                                <p>We help transportation businesses transition towards digitization and assist them in achieving overall better results. With our industry-specific expertise, we have delivered meaningful outcomes to accomplish business goals.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={health} fluid style={{width:"40px"}} />
                                <h4>Healthcare</h4>
                                <p>We can help you empower your healthcare business with the best-in-class solutions that bring a transition from a fee-for-service model to value-based care focusing on low cost and service quality.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={insurance} fluid style={{width:"40px"}} />
                                <h4>Insurance</h4>
                                <p>We have assisted several insurance companies in automating and accelerating data processing. Our customized solution has helped in fastening the claim process instantly and accurately.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={chain} fluid />
                                <h4>Supply Chain</h4>
                                <p>We have a team of professional developers who are experts in supply chain management software. We can help you develop supply chain management software for your business leveraging the latest technology.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={realState} fluid style={{width:"42px"}} />
                                <h4>Real Estate</h4>
                                <p>Our development solution can help you streamline the real estate process and establish intelligent communication between buyers, agents, real estate firms, and brokers.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={gasoil} fluid />
                                <h4>Oil and Gas</h4>
                                <p>Our developers can establish an environment for your oil and gas activities that places you at the cutting edge of marketing and technology.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
