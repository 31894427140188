import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Custom Alexa Skills Development',
        content: 'We create custom Alexa skills for your business applications regardless of your industry or expertise. We use the best technical expertise and cutting-edge technology to create for you world-class Alexa skills.'
    },
    {
        title: 'Skill Integration for Alexa',
        content: 'We can assist you if you already have an Android or iOS app and want to add an Alexa skill to it. Infograins can incorporate an Alexa skill into your current app to enhance and strengthen consumer engagement.'
    },
    {
        title: 'Voice Design Skills for Alexa',
        content: 'We create an exceptional and incredibly engaging automated speech experience for Alexa using artificial intelligence and machine learning technology. With our help, provide your users with a potent and enjoyable Alexa voice experience.'
    },
    {
        title: 'Alexa Skill Evaluation',
        content: 'Our fully staffed team of skilled testers and quality assessors will thoroughly test your Alexa Skills to assure their correctness and interest.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Our Features:</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements