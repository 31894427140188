import React, { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from "react-bootstrap"
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs"
import { useState } from 'react';
const Address = ({children}) => {

  const [infograinsData, setInfograinsData] = useState([])
  const [otherData, setOtherData] = useState([])
  const addressData = async () => {
    try {
      const url = await axios.get(`${process.env.REACT_APP_BASE_URL}about_us/office-address/`)
      setInfograinsData(url.data.infograins_india_office)
      setOtherData(url.data.response);
    } catch (error) {
    }
  }
  useEffect(() => {
    addressData()
  }, [])
// console.log(infograinsData, otherData);
  return (
    <>
      <section className='address-wrap' >
        <Container>
          <div className='address-title'>
            <h2 className='h2_title'>Development Center-InfoGrains Software Solutions Pvt. Ltd.</h2>
          </div>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className='ind-address-wrap'>
                <Card>
                  <Card.Body>
                    <Card.Title>{infograinsData.office}</Card.Title>
                    <Card.Text>{infograinsData.location}</Card.Text>
                    <div className='address-num' >
                      <BsTelephone /> <Card.Link href={`tel:${infograinsData.phone}`}>{infograinsData.phone} <b>(For Sales)</b></Card.Link><br></br>
                      <BsTelephone /> <Card.Link href="tel:9617635159"> +91 9617635159 <b>(HR)</b></Card.Link>
                    </div>
                    <div className='address-email'>
                      <AiOutlineMail /> <Card.Link href={`mailto:${infograinsData.email}`} target="_blank" rel='noreferrer'>{infograinsData.email}</Card.Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              {children}
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className='sub_address'>
                {/* <Row>
                  {
                    otherData.map((ele) => {
                      return (
                        <Col sm={6} md={6} lg={6} xl={6} key={ele.id}>
                          <Card>
                            <Card.Body>
                              <Card.Title>{ele.office}</Card.Title>
                              <Card.Text>{ele.location}</Card.Text>
                              <div className='address-num'>
                                <BsTelephone />  <Card.Link href={`tel:${ele.phone}`}>{ele.phone}</Card.Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row> */}

                   <Row>
                        <Col sm={6} md={6} lg={6} xl={6} >
                          <Card>
                            <Card.Body>
                              <Card.Title>UK OFFICE</Card.Title>
                              <Card.Text>Apartment 4105, 25 Arena Tower Crossharbour Plaza, London E14 9YF</Card.Text>
                              <div className='address-num'>
                                <BsTelephone />  <Card.Link href={`tel:${+447401232155}`}> +447401232155</Card.Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} >
                          <Card>
                            <Card.Body>
                              <Card.Title>USA OFFICE</Card.Title>
                              <Card.Text>135, 447 Broadway, 2nd Floor, New York, NY 10013, USA</Card.Text>
                              <div className='address-num'>
                                <BsTelephone />  <Card.Link href={`tel:${+13312695159}`}>+13312695159</Card.Link>
                              </div>
                              <Card.Text>720 W. Randolph Street Chicago, IL</Card.Text>

                              <div className='address-num'>
                                <BsTelephone />  <Card.Link href={`tel:${+13312695159}`}>+13312695159</Card.Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} >
                          
                          <Card>
                            <Card.Body>
                              <Card.Title>UAE OFFICE</Card.Title>
                              <Card.Text>FDRK3822 Compass Building, Al Shohada Road, AL Hamra Industrial Zone-FZ, Ras Al Khaimah, United Arab Emirates</Card.Text>
                              <div className='address-num'>
                                <BsTelephone />  <Card.Link href={`tel:${+971585596272}`}>+971585596272</Card.Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} >
                          <Card>
                            <Card.Body>
                              <Card.Title>AUSTRALIA OFFICE</Card.Title>
                              <Card.Text>264 George Street, Sydney 2000</Card.Text>
                              <div className='address-num'>
                                <BsTelephone />  <Card.Link href={`tel:${+61480043472}`}>+61480043472</Card.Link>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        
                  </Row>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}

export default Address

