import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import gameGirl from "../../../assets/images/trusted/aceRemove.png"

const OurGame = () => {
    const GameCate = [
        {
            title: " Development Kits (DKs)",
            content: "It has hardware that is user-friendly and is simple to use. The Development Kits are ideal for scaling and relying and aid in optimizing expandability. Every device has a unique set of applications, hence the output will vary depending on those applications and use cases. Use it if you need something for a brief period of time with few features."
        },
        {
            title: "MPMs, or Mass Production Modules",
            content: "Choose the mass production modules if you want to launch a product in mass manufacturing. But it has tiny spaces and is not for development. It is extremely sustainable and has a lifespan of 5 to 10 years. There are more usage options and restriction features."
        },
        {
            title: " MPM Evaluation Kits (EVK's)",
            content: "The expanded version for Mass production Modules is MPM evaluation kits. It enables debug, develop, and emphasize IoT solutions. The kits are easy to use and quickly with efficiency. Anyone with less knowledge can operate this kit as it is friendly to use. There are different models and methods for it, but the benefits are all significant."
        },
    ]
    return (
        <>
            <section className='ourGame-wrap'>
                <Container>
                    <div className='ourGame-title'>
                        {/* <h3 className='h3_title'>subheading</h3> */}
                        <h2 className='h2_title text-center'>Three Types Of IoT Hardware:</h2>
                    </div>
                    <Row>
                        {
                            GameCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='ourGame-card-wrap'>
                                        <figure className="ourGame-card-img">
                                            <Image src={gameGirl} alt='Ace' />
                                        </figure>
                                        <div className='ourGame-card-detail'>
                                            <h3 className='h3_title'>{e.title}</h3>
                                            <p>{e.content}</p>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurGame