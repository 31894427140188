import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import transperency from '../../../assets/images/hire/icons/Transperency.png'
import efficency from '../../../assets/images/hire/icons/Efficency.png'
import watch from '../../../assets/images/hire/icons/watch.png'

export default function Team({data}) {
  return (
    <>
      <section className='team_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="head">
                                <h2>Benefits of Hiring Developers from Infograins</h2>
                                <p>Our developers focus on achieving compelling results. They develop solutions per your requirements and business challenges.</p>
                            </div>
                        </Col>
                        {/* {data ? data.map(({title, id, content}) => {
                            return <Col lg={4} key={id}>
                                <div className='team_content'>
                                    <Image src='https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg' fluid />
                                    <h4>{title}</h4>
                                    <p>{content}</p>
                                </div>
                            </Col>
                        }) : ''} */}
                         <Col lg={4}>
                                <div className='team_content'>
                                    <Image src={transperency} fluid />
                                    <h4>Transparency</h4>
                                    <p>You can connect and communicate directly with developers and the team you have hired to share your requirements and feedback with them.</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='team_content'>
                                    <Image src={efficency} fluid />
                                    <h4>Efficiency</h4>
                                    <p>We will provide you with a dedicated team to handle the project. Get your project within a minimal span of time.</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='team_content'>
                                    <Image src={watch} fluid />
                                    <h4>Latest Technology</h4>
                                    <p>We embrace the latest technology and adopt a modern approach to the project. Our developers are proficient in implementing the latest technology.</p>
                                </div>
                            </Col>
                    </Row>
                </Container>
            </section>
    </>
  )
}
