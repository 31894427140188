import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                {/* <h2 className='h2_title'>Android App</h2> */}
                <h3 className='h3_title'>Text to Speech</h3>
                <p>For a better outcome for your application, an AI and ML tailored engine can turn your text into spoken speech. It gives your application the ability to speak and lets you build a whole product using voice. </p>
                <p>Business needs text to speech because it makes operations more productive and quick. There are different formats for text to speech throughout, allowing you to personalize it. The greatest text-to-speech services are offered by Infograins, which streamlines your work.</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection