import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { BsFillCheckCircleFill } from 'react-icons/bs'
export default function Overview(props) {
    const data = props.data

    return (
        <>
            <section className='overview_sections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head text-center py-5'>
                                <h2>Project Overview </h2>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='overview_img_wrapper'>
                                {/* {
                                    data && data.business_overview_image != null ? 
                                    <Image src={data.business_overview_image.image} className="img_side" alt='overview' fluid />
                                    :
                                    <Image src="https://dummyimage.com/600x400/000/fff" className="img_side" alt='overview' fluid />
                                } */}
                                <Image src="https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/business_overview/Asset_9_xcz1xv" className="img_side" alt='overview' fluid />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='overview_content py-5'>
                                <div className='list_content'>
                                    <ul>
                                        <li>
                                            <div className='list_item' style={{ display: "flex", gap: "20px" }}>
                                                <div className='list_icon'><BsFillCheckCircleFill /></div>
                                                <div className='list_description'>
                                                    {data && data.business_overview[0].content}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
