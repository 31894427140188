import React, { useState, useEffect } from 'react'
import ContactUs from '../landingPages/ContactUs'
import Banner from './Banner'
import Description from './Description'
import LogoSlider from './LogoSlider'
import PricingPlan from './PricingPlan'
import Talent from './Talent'
import Team from './Team'
import BlogSection from '../landingPages/BlogSection'
import Work from './Work'
import Process from './Process'
import Technology from './Technology'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { useParams, useLocation } from 'react-router-dom'

export default function HireMain() {
    const router = useParams()
    const location = useLocation()
    const [AllData, setAllData] = useState([])
    const [Error, setError] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    async function hireDevApi() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}hire-developers/get_developer_detail/${router.hire_slug}/`);
            // console.log(api.data.response);
            setAllData(api.data.response)
            // console.log("api res", api.data.response)
        } catch (error) {
            setError(true)
        }
    }

    useEffect(() => {
        hireDevApi()
    }, [location])

   
    return (
        <>

            <Banner data={AllData[0] ? AllData[0] : ''} />
            <LogoSlider />
            {/* <PricingPlan data={AllData[0] ? AllData[0].pricing_plans : ''} /> */}
            <Description DescriptionData={AllData[0] ? AllData[0].developer_content : ''} />
            <Talent />
            <Team />
            <Work />
            <Process />
            <Technology />
            <BlogSection />
            <ContactUs />
        </>
    )
}
