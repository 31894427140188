import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Implementation of Salesforce",
            content: "Implement Salesforce, tailored to your specific needs, including the Sales Cloud, Service Cloud, Marketing Cloud, and more."
        },
        {
            title: "Support for Salesforce",
            content: "We'll assist you with platform upkeep, continuous improvements, optimization, and more with hands-on Salesforce support."
        },
        {
            title: "Managed Salesforce Services",
            content: "Access a scalable, adaptable solution to protect and profit from your investment without adding more full-time staff."
        },
        {
            title: "Salesforce Integration",
            content: "Connect your primary systems and tools to Salesforce. To position yourself for the future, create a full 360-degree vision, streamline your company procedures, and create an integration strategy."
        },
        {
            title: "Custom Salesforce Development",
            content: "Infograins assists with your Salesforce customisation requirements, including the design of new objects, fields, data management strategies, and workflows."
        },
        {
            title: "Services for Salesforce Development",
            content: "Custom development can assist if configuration fails. To customize your organization, have access to Force.com and Salesforce app development."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our Salesforce Consulting Services</h2>
                                {/* <p>We have a fantastic team of web development experts who are always testing and applying new ideas that will turn website designs into a gold mine for your company.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
