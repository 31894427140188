import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Custom Web Application Development",
            content: "Use our experience to design unique web apps that best represent your company. We cover everything from wireframing to responsive design."
        },
        {
            title: "Website Portal",
            content: "Our B2B portal development solutions are trusted for their seamless user components, simple checkout, appealing design, feature-rich admin panel, simple product navigation, and integration with cutting-edge technology."
        },
        {
            title: "Front-end Development",
            content: "Our web designers create beautiful, user-friendly interfaces for your web project by utilizing cutting-edge technologies such as Angular, ReactJS, Vue.js, and others."
        },
        {
            title: "Development of the back end",
            content: "Navigate between multiple elements of your application with ease. AWS and Azure cloud hosting, dependable data storage and retrieval with SQL and NoSQL databases, and Microservices are among our services."
        },
        {
            title: "Development of SaaS Applications",
            content: "We thoroughly grasp your web application requirements and potential for expansion, and we provide safe and multi-tenant SaaS application development solutions for long-term business benefits."
        },
        {
            title: "Migration and Upgrading",
            content: "With our application upgrade and migration services, you can upgrade your outdated web technology or migrate current web technology to any other platform."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our Services for Full-Stack Web App Development</h2>
                                <p>We have a fantastic team of web development experts who are always testing and applying new ideas that will turn website designs into a gold mine for your company.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
