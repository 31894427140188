import { Container, Row, Col } from "react-bootstrap";

const KeyPointsData = [
    {
      value: '23 +',
      text: 'Years of Experience'
    },
    {
      value: '270 +',
      text: 'Development Staff'
    },
    {
      value: '75 +',
      text: 'Countries Served'
    },
    {
      value: '2200 +',
      text: 'Project Delivered'
    },
    {
      value: '85 +',
      text: 'Repeated Clients'
    },
    {
      value: '4.7M +',
      text: 'Hours of Efforts'
    },
  ]
  
export default function KeyPoints() {
  
    return (
      <>
        <section className='keyPoint_section'>
          <Container>
            <Row>
              {KeyPointsData.map(({ text, value }, id) => (
                <Col lg={2} md={4} sm={12} key={id}>
                  <div className='keyPoint_box'>
                    <div className='value'>{value}</div>
                    <div className='text'>{text}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </>
    )
  }