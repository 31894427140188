import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: '•	Expert Team',
        content: 'Our team consists of skilled developers, designers, and strategists who are passionate about iOS app development.'
    },
    {
        title: '•	Client-Centric Approach',
        content: ': We prioritize your goals and aspirations, tailoring our services to your specific needs.'
    },
    {
        title: '•	Innovation',
        content: 'We stay ahead of the curve by incorporating the latest technologies and design trends into our work.'
    },
    {
        title: '•	Proven Track Record',
        content: 'Check out our portfolio of successful iOS apps that have garnered positive user feedback and achieved business milestones.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Why Choose US?</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements