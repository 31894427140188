import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Boosting Your Business Through the Development of Custom iOS Applications</h2>
                                <div className='description'>As a full-stack iPhone app development business, we have released hundreds of native iOS applications that have seen exponential growth and are top sellers on the App Store. We have a talented group of seasoned iPhone mobile app developers, and Objective-C and Swift make up our main tech stack when creating iOS solutions.</div>
                                <div className='description'>We are a dedicated team of iPhone app developers who work for an iOS mobile app development company. We offer custom iPhone application development services while taking into account the unique design and iOS application development company guidelines related to each individual platform, including iPad, iPhone, Apple TV, and Apple Watch. Our iOS app developers have created apps for a variety of use cases, including real-time analytics, route matching, geo-tagging, streaming video, and more across several industries.
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies