import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function Banner({ data }) {
    const navigate = useNavigate()
  
    const handleRedirect = ()=>{
        navigate("/contactUs")
    }
    return (
        <>
            <section className='hire_dev_section'>
                <Container>
                    <Row>
                        <Col lg={6} md={6}>
                            <div className='banner_content_wrapper'>
                                <div className='banner_content'>
                                    <h2>{data.name}</h2>
                                    <p className='text-white'>{data.description}</p>
                                    <button onClick={handleRedirect} className='text-white'>Get a Quote</button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='banner_image_wrapper'>
                                <div className='banner_image '>
                                    <Image src={data.image} fluid />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
