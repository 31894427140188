const KnowMoreContent = [
  {
    id: 1,
    canonical: 'https://www.infograins.com/event/event-dubai/',
    url: "event-dubai",
    schedule: "https://calendly.com/vipin-infograins/meeting-with-infograins?month=2023-11",
    heading: "We Can Help You Transform Your Business: Reserve Your Spot To Meet Us In Dubai ",
    date: '',
    setionSecHead: "BUSINESS TRIP TO DUBAI",
    setionSecText: "Our team is on a Business trip to Dubai. Don't miss your chance to meet the top blockchain experts in Dubai! Join us for an exciting discussion regarding your requirements, where you'll have the opportunity to explore how blockchain and web3 technologies can benefit your businesses.",
    eventName: "BUSINESS TRIP TO DUBAI",
    title: "Business Trip in Dubai - Infograins",
    Description: "Explore unlimited opportunities, develop an unbreakable professional terms and get revolutionary IT business solutions.Get a free consultation with our experts & achieve all your goals.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "Dubai Business Trip",
            "url": " https://www.infograins.com/event/event-dubai/",
            "description": " Our team is on a Business trip to Dubai. Don't miss your chance to meet the top blockchain experts in Dubai! Join us for an exciting discussion regarding your requirements, where you'll have the opportunity to explore how blockchain and web3 technologies can benefit your businesses.",
            "startDate": "2023-09-03",
            "endDate": "2023-09-10",
            "location": {
                "@type": "Place",
                "name": " Grand Excelsior Hotel Bur Dubai",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": " Burdubai - Kuwait St - Al Mankhoo",
                    "addressLocality": "Dubai",
                    "postalCode": "00000",
                    "addressCountry": "United Arab Emirates"
                }
            }
        }`

  },
  {
    id: 2,
    canonical: 'https://www.infograins.com/event/ces-2024/',
    url: "ces-2024",
    schedule: "",
    heading: "We Can Help You Transform Your Business: Reserve Your Spot To Meet Us In Las Vegas, USA ",
    date: '',
    setionSecHead: "Custom Software Development Company Invites You At CES Las Vegas 2024",
    setionSecText: "Infograins team is ready to witness the CES Las Vegas.We have geared up to participate in the event with full enthusiasm. Let's meet us and and experience our suit of smart services and innovative solutions. We look forward to seeing you!",
    eventName: "BUSINESS TRIP TO USA",
    title: "Meet Team Infograins at CES 2024 Las Vegas",
    Description: "Embrace the future of technology with Custom Software Development, Blockchain Development, Application development, and many more. Book a meeting with us at CES 2024.",
    eventScript: `{
          "@context": "https://schema.org",
          "@type": "Event",
          "name": "Meet Team Infograins at CES 2024 Las Vegas",
          "url": " https://www.infograins.com/event/ces-2024/",
          "description": "Embrace the future of technology with Custom Software Development, Blockchain Development, Application development, and many more. Book a meeting with us at CES 2024.",
          "startDate": "2023-01-09",
          "endDate": "2023-01-12",
          "location": {
              "@type": "Place",
              "name": " Grand Excelsior Hotel Bur Dubai",
              "address": {
                  "@type": "PostalAddress",
                  "streetAddress": " Burdubai - Kuwait St - Al Mankhoo",
                  "addressLocality": "Dubai",
                  "postalCode": "00000",
                  "addressCountry": "United Arab Emirates"
              }
          }
      }`
  },
  // {
  //     id:2,
  //     url:"/CDN",
  //     schedule:"https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
  //     heading:"Trusted Blockchain, IoT, Web, App & Custom Software Development company in USA",
  //     date:'Tue, 26 Sep, 7:00 PM – Wed, 27 Sep, 10:00 PM GST',
  //     setionSecHead:"Global Tech Innovation Summit 2023",
  //     setionSecText:"We at Infograins are thrilled to attend Global Tech Innovation Summit 2023 in Dubai on September 26-27, 2023. With a diverse range of industry leaders, innovators, and experts gathered under one roof, the summit will be a promising platform for sharing insights. We believe this summit provides an ideal platform to demonstrate our expertise and capabilities to a global audience.",
  //     eventName:"Global Tech Innovation Summit 2023"
  // },
  {
    id: 3,
    canonical: 'https://www.infograins.com/event/international-crypto-blockchain-&-metaverse-expo-event',
    url: "international-crypto-blockchain-&-metaverse-expo-event",
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Discover The Power Of Our Transformative Blockchain Services At ICBM 2023: Schedule A Meeting Now",
    date: 'Thu, 7 Sep – Fri, 8 Sep',
    setionSecHead: "ICBM 2023",
    setionSecText: "We are excited to attend ICBM 2023 in Dubai on September 7-8, 2023. This prominent conference gathers visionaries, leaders, and innovators from across the world to share their ideas and success stories. We look forward to ICBM as it provides an ideal environment to showcase our blockchain development services, connect with decision-makers, and establish mutually beneficial alliances.",
    eventName: "ICBM 2023",
    title: "ICBM Expo 2023 - Infograins",
    Description: "Infograins, a blockchain development company is going to attend ICBM Expo 2023 in Dubai. Schedule a meeting now to discuss more about crypto, blockchain and metaverse with our team of experts. We look forward to connecting with everyone.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "ICBM Expo 2023",
          "url": "https://www.infograins.com/event/international-crypto-blockchain-&-metaverse-expo-event/",
            "description": "We are excited to attend ICBM 2023 in Dubai on September 7-8, 2023. This prominent conference gathers visionaries, leaders, and innovators from across the world to share their ideas and success stories. We look forward to ICBM as it provides an ideal environment to showcase our blockchain development services, connect with decision-makers, and establish mutually beneficial alliances.",
            "startDate": "2023-09-07",
            "endDate": "2023-09-08",
            "location": {
              "@type": "Place",
              "name": "Festival Arena",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Dubai Festival City - Al Badia Blvd",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }`
  },
  {
    id: 4,
    canonical: 'https://www.infograins.com/event/vibe-martech-fest-event',
    url: 'vibe-martech-fest-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Schedule A Meeting With Infograins At Vibe Martech Fest 2023 For Revolutionary Solutions",
    date: 'Tue, 12 Sep – Thu, 14 Sep',
    setionSecHead: "Vibe Martech 2023",
    setionSecText: "Infograins is delighted to attend Vibe Martech 2023 in Dubai from September 12-14, 2023. This edition of the event focuses on AI and Web3 functionalities. We are excited to see how businesses leverage these technologies to drive business success. We look forward to demonstrating our Web3 solutions and creating synergies with innovative businesses from across the globe.",
    eventName: "Vibe Martech 2023",
    title: "Vibe Martech Fest 2023 - Infograins",
    Description: "Get ready to interact with the team of the leading software development company - Infograins at Vibe Martech Fest 2023. Book a meeting now for a progressive discussion and avoid the last-minute hassle.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Vibe Martech Fest 2023",
          "url": " https://www.infograins.com/event/vibe-martech-fest-event/",
            "description": "Infograins is delighted to attend Vibe Martech 2023 in Dubai from September 12-14, 2023. This edition of the event focuses on AI and Web3 functionalities. We are excited to see how businesses leverage these technologies to drive business success. We look forward to demonstrating our Web3 solutions and creating synergies with innovative businesses from across the globe.",
            "startDate": "2023-09-12",
            "endDate": "2023-09-14",
            "location": {
              "@type": "Place",
              "name": "Dubai Marina",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Dubai Marina",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 5,
    canonical: 'https://www.infograins.com/event/global-tech-innovation-summit-event',
    url: 'global-tech-innovation-summit-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Empower Your Business: Schedule A Meeting With Infograins At Global Tech Innovation Summit For Agile Software Development",
    date: 'Tue, 26 Sep – Wed, 27 Sep',
    setionSecHead: "Global Tech Innovation Summit 2023",
    setionSecText: "We at Infograins are thrilled to attend Global Tech Innovation Summit 2023 in Dubai on September 26-27, 2023. With a diverse range of industry leaders, innovators, and experts gathered under one roof, the summit will be a promising platform for sharing insights. We believe this summit provides an ideal platform to demonstrate our expertise and capabilities to a global audience.",
    eventName: "Global Tech Innovation Summit 2023",
    title: "Global Tech Summit 2023 - Infograins",
    Description: "Infograins, a leading blockchain development company is beyond delighted to attend the prestigious Global Tech Summit 2023 in Dubai. Book a calendly to engage in more in-depth discussion with our team.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Global Tech Innovation Summit",
          "url": "https://www.infograins.com/event/global-tech-innovation-summit-event/",
            "description": "We at Infograins are thrilled to attend Global Tech Innovation Summit 2023 in Dubai on September 26-27, 2023. With a diverse range of industry leaders, innovators, and experts gathered under one roof, the summit will be a promising platform for sharing insights. We believe this summit provides an ideal platform to demonstrate our expertise and capabilities to a global audience.",
            "startDate": "2023-09-26",
            "endDate": "2023-09-27",
            "location": {
              "@type": "Place",
              "name": "Dubai",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Dubai",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 6,
    canonical: 'https://www.infograins.com/event/blockchain-economy-dubai-summi-event',
    url: 'blockchain-economy-dubai-summi-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Take Your Business To The Next Level: Join Us At Blockchain Economy Summit 2023 In Dubai",
    date: 'Wed, 4 Oct – Thu, 5 Oct',
    setionSecHead: "Blockchain Economy Summit",
    setionSecText: "The team of Infograins is elated to announce that they will be attending Blockchain Economy Summit in Dubai on October 4-5, 2023, the event that has turned out to be a game changer for the blockchain industry. By attending this event, we hope to showcase our services and be a part of a collaborative environment.",
    eventName: "Blockchain Economy Summit",
    title: "Blockchain Economy Summit 2023 - Infograins",
    Description: "Are you looking forward to Blockchain Economy Summit 2023? Infograins - A leading Blockchain Company is too! Engage with our blockchain experts to unlock new opportunities and explore potential synergies. Schedule a meeting now.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Blockchain Economy Summit 2023",
          "url": " https://www.infograins.com/event/blockchain-economy-dubai-summi-event/",
            "description": " The team of Infograins is elated to announce that they will be attending Blockchain Economy Summit in Dubai on October 4-5, 2023, the event that has turned out to be a game changer for the blockchain industry. By attending this event, we hope to showcase our services and be a part of a collaborative environment. Connect with team Infograins at Blockchain Economy Summit and be a part of their journey toward becoming",
            "startDate": "2023-10-04",
            "endDate": "2023-10-05",
            "location": {
              "@type": "Place",
              "name": " Le Meridien Dubai Hotel & Conference Centre",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Airport Road - Garhoud",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 7,
    canonical: 'https://www.infograins.com/event/fintech-surge-northstar-event',
    url: 'fintech-surge-northstar-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Seize The Opportunity For Advanced Blockchain Development Services At Fintech Surge 2023 / Northstar: Book A Meeting Now!",
    boothNum: 'POD Number - Hall 2 Stand No: H2-C29, H2-C30',
    location: 'Location - Dubai Harbour',
    date: 'Sun, 15 Oct – Wed, 18 Oct',
    setionSecHead: "Fintech Surge 2023/Northstar",
    setionSecText: "Infograins is delighted to attend the highly anticipated Fintech Surge 2023/ Northstar in Dubai from October 15-18, 2023. We are confident that these events will provide us with an invaluable opportunity to connect with key players in the industry and gain insights into the latest advancements.",
    eventName: "Fintech Surge 2023/Northstar",
    title: "Fintech Surge 2023/Northstar - Infograins ",
    Description: "Infograins, a leading IT organization specializes in developing custom software for startups and enterprises to help them achieve their business goals. Meet with our team at the biggest gathering of startups - Expand North Star and Fintech Surge.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "Fintech Surge 2023 / Northstar",
          "url": " https://www.infograins.com/event/fintech-surge-northstar-event/",
            "description": "Infograins is delighted to attend the highly anticipated Fintech Surge 2023/ Northstar in Dubai from October 15-18, 2023. We are confident that these events will provide us with an invaluable opportunity to connect with key players in the industry and gain insights into the latest advancements.",
            "startDate": "2023-10-15",
            "endDate": "2023-10-18",
            "location": {
              "@type": "Place",
              "name": " Dubai HarBour",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Dubai ",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 8,
    canonical: 'https://www.infograins.com/event/techspo-dubai-event',
    url: 'techspo-dubai-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Gain A Competitive Edge: Join Us At TECHSPO Dubai 2023 In Dubai",
    date: 'Tue, 10 Oct – Wed, 11 Oct',
    setionSecHead: "Techspo Dubai 2023",
    setionSecText: "The team of Infograins is excited to attend Techspo Dubai on October 10-11, 2023. Visionaries, innovators, and experts will gather at this event to exchange ideas and explore potential business opportunities. We are looking forward to expanding our network and forming potential partnerships. Join us for an exciting discussion.",
    eventName: "Techspo Dubai 2023",
    title: "Techspo Dubai 2023 - Infograins",
    Description: "Don't miss your chance to discover the newest advancements in the IT sector from Infograins at Techspo Dubai 2023. As a leading software development company, we can help you leverage latest technologies. Book a meeting now to stay forefront of the industry.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "TECHSPO Dubai 2023",
          "url": "https://www.infograins.com/event/techspo-dubai-event/",
            "description": " The team of Infograins is excited to attend Techspo Dubai on October 10-11, 2023. Visionaries, innovators, and experts will gather at this event to exchange ideas and explore potential business opportunities. We are looking forward to expanding our network and forming potential partnerships. Join us for an exciting discussion.",
            "startDate": "2023-10-10",
            "endDate": "2023-10-11",
            "location": {
              "@type": "Place",
              "name": " Hyatt Regency Dubai Hotel",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Al Khaleej Road, Deira Corniche, Deira ",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 9,
    canonical: 'https://www.infograins.com/event/business-trip-to-dubai-2024',
    url: 'business-trip-to-dubai-2024',
    phone:"+971585596272",
    schedule: "https://calendly.com/marketing-infograins/business-trip-to-dubai-2024?month=2024-10",
    heading: "Infograins is on a business trip to Dubai, UAE. Come join us and discover the future of technology solutions!",
    date: 'Oct 13th - Oct 20th, 2024',
    boothNum: 'POD Number - Hall 26, P-11',
    location: 'Location: Dubai World Trade Centre',
    setionSecHead: "Business Trip to Dubai, UAE.",
    setionSecText: "Infograins is thrilled to announce its upcoming business trip to Dubai from Oct 13th - Oct 20th, 2024. Dubai has always been a hub of ideas and innovation that has continuously inspired us and fueled our passion for technology. We eagerly anticipate the countless opportunities this trip will bring.",
    eventName: "Gitex 2024",
    title: "oftware Development Agency in Dubai | Business Trip to Dubai - Infograins",
    Description: "Infograins is heading to Dubai from Oct 13th-20th, 2024! Meet our top IT consultants to discuss your software, blockchain, app, and web development services in Dubai.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " GITEX 2023",
          "url": " https://www.infograins.com/event/business-trip-to-dubai-2024/",
            "description": " Infograins is thrilled to announce its upcoming business trip to Dubai from Oct 13th - Oct 20th, 2024. Dubai has always been a hub of ideas and innovation that has continuously inspired us and fueled our passion for technology. We eagerly anticipate the countless opportunities this trip will bring.",
            "startDate": "2024-10-13",
            "endDate": "2024-10-20",
            "location": {
              "@type": "Place",
              "name": " Dubai International Convention & Exhibition Centre",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Sheikh Zayed Road",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 10,
    canonical: 'https://www.infograins.com/event/world-blockchain-summit-event',
    url: 'world-blockchain-summit-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Join Infograins At World Blockchain Summit To Experience Seamless Digital Transformation Together",
    date: 'Wed, 1 Nov -Thu 2 Nov',
    setionSecHead: "World Blockchain Summit 2023",
    setionSecText: "Infograins is excited to attend World Blockchain Summit on Nov 1 and Nov 2, 2023, in Dubai. The World Blockchain Summit is an exceptional platform that brings together visionaries from around the world. They discuss challenges, trends, and opportunities in the blockchain industry. If you are attending the summit, we would be delighted to meet you there.",
    eventName: "World Blockchain Summit 2023",
    title: "World Blockchain Summit 2023 - Be A Witness Of Your Future of Blockchain with Infograins",
    Description: "Meet us at World Blockchain Summit to learn more about our blockchain development services. Book a meeting now!",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " World Blockchain Summit",
          "url": " https://www.infograins.com/event/world-blockchain-summit-event/",
            "description": " Infograins is excited to attend World Blockchain Summit on Nov 1 and Nov 2, 2023, in Dubai. The World Blockchain Summit is an exceptional platform that brings together visionaries from around the world. They discuss challenges, trends, and opportunities in the blockchain industry. If you are attending the summit, we would be delighted to meet you there.",
            "startDate": "2023-11-01",
            "endDate": "2023-11-02",
            "location": {
              "@type": "Place",
              "name": " Dubai Marina",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Dubai Marina",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 11,
    canonical: 'https://www.infograins.com/event/the-future-innovation-summit',
    url: 'the-future-innovation-summit',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Meet Us At The Future Innovation Summit To Accelerate Your Business Growth",
    date: 'Tue, 10 Oct – Wed, 11 Oct',
    setionSecHead: "The Future Innovation Summit 2023",
    setionSecText: "The team of Infograins is thrilled to attend The Future Innovation Summit in Dubai on Oct 10th and 11th, 2023. The Future Innovation Summit will be an enlightening experience that will inspire us to explore new possibilities. We look forward to connecting with industry peers and anyone interested in software development services.",
    eventName: "The Future Innovation Summit 2023",
    title: "Future Innovation Summit 2023 - Witness Your Innovative Future of IT with Infograins",
    Description: "Explore plethora of software development services at Future Innovation Summit 2023 by interacting with us.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Future Innovation Summit",
          "url": " https://www.infograins.com/event/the-future-innovation-summit/",
            "description": “The team of Infograins is thrilled to attend The Future Innovation Summit in Dubai on Oct 10th and 11th, 2023. The Future Innovation Summit will be an enlightening experience that will inspire us to explore new possibilities. We look forward to connecting with industry peers and anyone interested in software development services.",
            "startDate": "2023-10-10",
            "endDate": "2023-10-11",
            "location": {
              "@type": "Place",
              "name": " Jumeirah Beach Hotel",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Jumeirah St - Umm Suqeim - Umm Suqeim 3",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 12,
    canonical: 'https://www.infograins.com/event/crypto-expo-dubai-event',
    url: 'crypto-expo-dubai-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Fuel Your Business With Blockchain. Engage With Us At Crypto Expo Dubai 2023 ",
    date: 'Wed, 20 Sept - Thu, 21 Sept',
    setionSecHead: "Crypto Expo Dubai 2023",
    setionSecText: "Infograins will attend Crypto Expo Dubai 2023 on September 20th and 21st, 2023- one of the well-known events known in the cryptocurrency industry. Crypto Expo focuses on the latest trends and advancements in the crypto sector. Join us as we highlight our blockchain solutions and other related services.",
    eventName: "Crypto Expo Dubai 2023",
    title: "Crypto Expo Dubai 2023 - Infograins",
    Description: "Schedule a meeting with Infograins- a leading blockchain development company at Crypto Expo Dubai 2023.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Crypto Expo Dubai 2023",
          "url": " https://www.infograins.com/event/crypto-expo-dubai-event/",
            "description":"Infograins will attend Crypto Expo Dubai 2023 on September 20th and 21st, 2023- one of the well-known events known in the cryptocurrency industry. Crypto Expo focuses on the latest trends and advancements in the crypto sector. Join us as we highlight our blockchain solutions and other related services.",
            "startDate": "2023-09-20",
            "endDate": "2023-09-21",
            "location": {
              "@type": "Place",
              "name": "Festival Arena",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Dubai Festival City - Al Badia Blvd",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 13,
    canonical: 'https://www.infograins.com/event/the-forex-expo-event',
    url: 'the-forex-expo-event',
    schedule: "https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-10",
    heading: "Discover Infinite Software Development Possibilities At The Forex Expo 2023 In Dubai",
    date: 'Tue, 26 Sept – Wed, 27 Sept',
    setionSecHead: "The Forex Expo 2023",
    setionSecText: "Join us at The Forex Dubai 2023 on the 26th and 27th of September 2023. The expo provides a platform for industry leaders and experts to come together to discuss opportunities in the Forex market. We are confident that we will gain the opportunity to establish potential partnerships and showcase our IT development services.",
    eventName: 'The Forex Expo 2023',
    title: "Forex Expo 2023 - Infograins",
    Description: " Join us at Forex Expo 2023 to know about our unrivalled software development services. Schedule a meeting right away.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Forex Expo 2023",
          "url": " https://www.infograins.com/event/the-forex-expo-event/",
            "description":"Join us at The Forex Dubai 2023 on the 26th and 27th of September 2023. The expo provides a platform for industry leaders and experts to come together to discuss opportunities in the Forex market. We are confident that we will gain the opportunity to establish potential partnerships and showcase our IT development services.",
            "startDate": "2023-09-26",
            "endDate": "2023-09-27",
            "location": {
              "@type": "Place",
              "name": "Dubai World Trade Centre",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Sheikh Zayed Rd - Trade Centre - Trade Centre 2",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 14,
    canonical: 'https://www.infograins.com/software-development-company',
    url: 'software-development-company',
    schedule: "https://calendly.com/marketing-infograins/business-trip-to-dubai-2024?month=2024-10",
    phone:"+13312695159",
    heading: "We Can Help You Transform Your Business: Schedule your Meeting Now",
    date: 'Sept 12th  – Oct 10th, 2024',
    setionSecHead: "Top Custom Software Development Services for a Smarter World",
    setionSecText: "Infograins is a leading software development, blockchain development, app development, and web development company helping global businesses achieve digital transformation. We bring Extensive 13+ Years Experience in IT by becoming a true technological partner, We are empowering startups to enterprise-level businesses to Global leaders.",
    eventName: 'BUSINESS TRIP TO USA',
    title: "Software Development Company Chicago",
    Description: " Join us at Forex Expo 2023 to know about our unrivalled software development services. Schedule a meeting right away.",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Forex Expo 2023",
          "url": " https://www.infograins.com/event/the-forex-expo-event/",
            "description":"Join us at The Forex Dubai 2023 on the 26th and 27th of September 2023. The expo provides a platform for industry leaders and experts to come together to discuss opportunities in the Forex market. We are confident that we will gain the opportunity to establish potential partnerships and showcase our IT development services.",
            "startDate": "2023-09-26",
            "endDate": "2023-09-27",
            "location": {
              "@type": "Place",
              "name": "Dubai World Trade Centre",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Sheikh Zayed Rd - Trade Centre - Trade Centre 2",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
  {
    id: 14,
    canonical: 'https://infograins.com/event/business-trip-to-chicago',
    url: 'business-trip-to-chicago',
    schedule: "https://calendly.com/marketing-infograins/business-trip-to-dubai-2024?month=2024-10",
    phone:"+13312695159",
    heading: "We Can Help You Transform Your Business: Schedule your Meeting Now",
    date: 'Sept 12th  – Oct 10th, 2024',
    setionSecHead: "Top Custom Software Development Services for a Smarter World",
    setionSecText: "Infograins is a leading software development, blockchain development, app development, and web development company helping global businesses achieve digital transformation. We bring Extensive 13+ Years Experience in IT by becoming a true technological partner, We are empowering startups to enterprise-level businesses to Global leaders.",
    eventName: 'BUSINESS TRIP TO USA',
    title: "Software Development Agency in Chicago | Business Trip to Chicago",
    Description: "Infograins is the leading software, blockchain, app, and web development company in Chicago!. With 13+ years of IT expertise, meet our consultants to discuss your project and digital transformation goals",
    eventScript: `{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": " Forex Expo 2023",
          "url": " https://www.infograins.com/event/business-trip-to-chicago/",
            "description":"Join us at The Forex Dubai 2023 on the 26th and 27th of September 2023. The expo provides a platform for industry leaders and experts to come together to discuss opportunities in the Forex market. We are confident that we will gain the opportunity to establish potential partnerships and showcase our IT development services.",
            "startDate": "2023-09-26",
            "endDate": "2023-09-27",
            "location": {
              "@type": "Place",
              "name": "Dubai World Trade Centre",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Sheikh Zayed Rd - Trade Centre - Trade Centre 2",
                "addressLocality": "Dubai",
                "postalCode": "00000",
                 "addressCountry": "United Arab Emirates"
              }
            }
          }
          `
  },
]

export default KnowMoreContent