import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import SecImg from '../../../assets/images/venturWithCDN/assset-01.png'
export default function MutualPartner() {
    return (
        <>
            <section className='mutualPartnership'>
                <div className='circles'></div>
                <div className='circles'></div>
                <div className='circles'></div>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className='imageWrapper'>
                                <Image fluid src={SecImg} />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="wrapper">
                                <div className='ContentDiv'>
                                    <h2>A Unified Vision To Make The Difference</h2>
                                    <p>Infograins has collaborated with CDN Solutions Group aspiring to expand its reach to global customers. We are delighted to be a part of a reputable organization having 20+ years of experience in the IT industry. Infograins brings its extensive experience and expertise in blockchain technology to the partnership, while CDN Solutions Group offers proficiency, competence and years of experience in developing unique IT solutions for various industries. With the two companies collaborating, clients can look forward to innovative and efficient solutions that meet their specific needs.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
