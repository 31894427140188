import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Salesforce Consulting",
            content: "By utilizing Infograin's top-tier salesforce consulting & CRM services, you can advance your company. Offering customers top-notch services is where your company will transform. At Infograins, we give our clients resources to use the sales force effectively. We provide a variety of salesforce implementation and development services."
        },
        {
            title: "Salesforce Integration",
            content: "We provide seamless integration of Salesforce with the applications used by your organizations. In addition, we provide web services in Salesforce that enable data exchange between the third-party app and Salesforce. Our talented team creates AppExchange applications for software suppliers so their product may be easily integrated with Salesforce."
        },
        {
            title: "Salesforce Customization",
            content: "We provide Salesforce modification solutions that enhance both internal and external business operations for our clients and address pressing issues that are impossible to solve with standard CRM tools. These programs are used to manage tasks like delivery planning and property management, and they are highly complicated."
        },
        {
            title: "Salesforce Migration",
            content: "Any module, any CRM platform, and any add-on will be transferred by our knowledgeable Salesforce experts to the time-tested Salesforce CRM platform as part of our migration service. The main characteristics of the Salesforce custom migration solution from Infograins. Our salesforce automated migration solution is a superb example of how to transfer data quickly."
        },
        {
            title: "Salesforce Application Development",
            content: "With years of experience, Infograins provides Salesforce App Development solutions that target the demands of businesses in a variety of sectors, including manufacturing, professional services, healthcare, and retail. We create unique apps with Salesforce application development services for both internal needs and apps for the AppExchange."
        },
        {
            title: "Salesforce Support & Maintenance",
            content: "At Infograins, a group of committed Salesforce developers, business analysts, and administrators are constantly prepared to ensure the stability of your Salesforce solution and its quick ascent. We provide administrative help through our Salesforce support and maintenance services."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Comprehensive Salesforce Development Services</h2>
                                <p>Our exclusive goal is on creating robust, reliable, and bug-free commercial applications that can boost your sales and corporate operations.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
