import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                <h2 className='h2_title'>Top</h2>
                <h3 className='h3_title'>Blockchain Development Company</h3>
                <p>We have been utilizing blockchain technologies to help businesses, both large and small, optimize their efficiency since 2013. Infograins has emerged as one of the fastest growing Blockchain Development Company. As Blockchain is known for its decentralized nature, meaning that data is not stored in a central authority. This ensures transparency in transactions, as all participants in the network have access to the same information, fostering trust and accountability.we are providing end-to-end solutions by enhancing transparency and security for enterprises.</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>``
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection