import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import requirement from '../../../assets/images/hire/icons/requirement.png'
import layer from '../../../assets/images/hire/icons/Layer.png'
import testing from '../../../assets/images/hire/icons/Testing.png'
import deployment from '../../../assets/images/hire/icons/Deployment.png'
import support from '../../../assets/images/hire/icons/Support.png'
export default function Talent({ data }) {
    return (
        <>
            <section className='talent_section'>
                <Container>
                    <Row className='justify-content-center '>
                        <Col lg={12}>
                            <div className="head">
                                <h2>Our Approach Towards Development</h2>
                                <p>With our disciplinary development approach, we have created top-notch solutions for our clients.</p>
                            </div>
                        </Col>
                        {/* {data ? data.map(({ title, content, id }) => {
                            return <Col lg={4} key={id}>
                                <div className='talent_content'>
                                    <Image src='https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/wtc-finance-experts.svg' fluid />
                                    <h4>{title}</h4>
                                    <p>{content}</p>
                                </div>
                            </Col>
                        }) : ''} */}
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={requirement} fluid />
                                <h4>Understanding Requirements</h4>
                                <p>We believe that every project starts with an idea. We gather your requirements, analyze them and then work accordingly. Our focus is primarily on documentation for clarity to come on the same page.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={layer} fluid />
                                <h4>UI/UX</h4>
                                <p>We create a prototype and test it for all the possible use cases and solutions. It helps us to eliminate all the issues to provide a seamless user experience after development.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={testing} fluid />
                                <h4>QA and Testing</h4>
                                <p>For several industries, we have developed customized applications. Whether you require native or cross-platform applications, we will help you with assessment, testing and fixing the loopholes for enhanced reliability.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={deployment} fluid />
                                <h4>Deployment</h4>
                                <p>Once all the bugs and changes get fixed, we assist our clients with the final deployment of the source code and launching in the client’s platform.</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='talent_content'>
                                <Image src={support} fluid />
                                <h4>Support and Maintenance</h4>
                                <p>We also provide post-delivery assistance. You can get our reliable support team that assists you during every stage of development and delivery.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
