import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                {/* <h2 className='h2_title'>Android App</h2> */}
                <h3 className='h3_title'>Object Recognition</h3>
                <p>To find and recognize things in pictures and videos, computer vision is used. It is a technique supported by machine learning and artificial intelligence. Using object recognition, facial practices in image, video, and other small items are discovered. Analytics can be carried out using computer vision techniques.</p>
                <p>For instance, using object recognition, you may recognize characters, scenes, locations, items, and many other aspects while viewing a movie. The main benefit is that you have immediate, real-time access. It gives you the ability to find, recognize, follow, and identify the things in that particular picture or image. The algorithm assigns them labels and offers a complete set of APIs to assist you with precise categorization, tagging, and other functions.</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection