import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Django',
        content:'Django, one of the most popular and powerful open-source Python frameworks, allows for speedier deployment. Surprisingly, this is the foundation for famous sites like Instagram and Pinterest. It lets developers to create information in a variety of forms, including XML and HTML.'
    },
    {
        title: 'CherryPy',
        content:'CherryPy is an advanced Python framework that aids with Python Android development. Developers can use it to operate numerous HTTP servers at the same time while also testing their runtime behavior.'
    },
    {
        title: 'Pyramid',
        content:'Pyramid is a Python framework for verification, authentication, and routing. It\'s ideal for creating high-end online programs like CMSs. Furthermore, developers use it for API prototype and development.'
    },
    {
        title: 'Web2Py',
        content:'Web2Py is a full-stack open source Python framework with capabilities similar to Django. It has no third-party dependencies and provides out-of-the-box features. It can also work with third-party tools if necessary.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Python Development Frameworks</h2>
                                <p>Our Python developers are skilled in a variety of programming frameworks that enable the construction of value-added apps.</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
