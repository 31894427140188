import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Pinterest',
        para: ''
    },
    {
        title: 'Evernote',
        para: ''
    },
    {
        title: 'Coursera',
        para: ''
    },
    {
        title: 'Uber and Many more',
        para: ''
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Process in Flow</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card