import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Data Analysis With Real-Time Business Intelligence</h2>
                <div className='description'>With the help of Power BI, our specialists at Infograins can produce dynamic dashboards and reports that turn your company data into intelligent data. If you have access to your inventory, backlog, revenue, pipeline, bookings, orders, etc., just think about it. Integrated dashboard? And real-time data would be dynamically updated on that dashboard? Our Power BI professionals can assist your company in maximizing its potential by incorporating dynamic features like forecasting. By doing this, you can make sure that the next time you decide something for your company, you do so with knowledge.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services