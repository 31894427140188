import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
const SiteMap = (props) => {
    const navigate = useNavigate()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [])
    // =========================== scroll To Top default =========================
    const [blockChain, setBlockChain] = useState([]);
    const [error, setError] = useState(false);
    const blockChainData = async () => {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}blockchain/blockchain_list/`);
            console.log("api.data.response", api.data.response);
            setBlockChain(api.data.response)
        } catch (error) {
            setError(true)
        }
    }
    useEffect(() => {
        blockChainData();
    }, [])

    // ===================================== NFT API start ============================================ 

    const [nftList, setNftList] = useState([])
    const [ErrorNft, setErrorNft] = useState(false)
    async function headerAPI() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}nft/nft_list/`);
            //   console.log("nft api data", api.data.response)
            setNftList(api.data.response)
        } catch (error) {
            setErrorNft(true)
        }
    }

    useEffect(() => {
        headerAPI()
    }, [])

    // ===================================== NFT API end ============================================ 

    // ==================================== GAME API ============================
    const [gameList, setGameList] = useState([])
    const [errorGame, setErrorGame] = useState(false)
    async function gameApi() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}game/game_list/`);
            //   console.log("game api data", api.data.response)
            setGameList(api.data.response)
        } catch (error) {
            setErrorGame(true)
        }
    }

    useEffect(() => {
        gameApi()
    }, [])
    // ==================================== GAME API ============================

    // =============================================== Product API ========================================
    const [ProductData, setProductData] = useState([])
    const [ErrorProduct, setErrorProduct] = useState(false)
    async function ourProduct() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}product/product_list/`);
            //  console.log("product api data", api.data.response[0].array_of_product_list)
            setProductData(api.data.response[0].array_of_product_list)
        } catch (error) {
            setErrorProduct(true)
        }
    }

    useEffect(() => {
        ourProduct()
    }, [])
    // =============================================== Product API ========================================

    // ==================================== HIRE DEVELOPER API ============================
    const [hireDevList, sethireDev] = useState([])
    const [errorhireDev, setErrorhireDev] = useState(false)
    async function hireDevApi() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}hire-developers/get_hire_developers`);
            console.log("hire api data", api.data.response)
            sethireDev(api.data.response)
        } catch (error) {
            setErrorhireDev(true)
        }
    }

    useEffect(() => {
        hireDevApi()
    }, [])

    // ==================================== HIRE DEVELOPER API ============================

    const handleNav = () => {
        navigate("/aboutUs")
    }

    return (
        <>
            <section className='siteMap-wrap'>
                <Container>
                    <div className='siteMap-title'>
                        <h2 className='h2_title'>Site Map</h2>
                    </div>
                    <Row>
                        <Col sm={4} md={4} lg={4} xl={4}>
                            <div className="siteMap-card-wrap mt-3">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>BlockChain Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        {
                                            blockChain.map((ele, key) => {
                                                return (
                                                    <div className='h4_title' key={key}>{ele.blockchain_category}
                                                        {
                                                            ele.array_of_blockchain_category_list.map((e, index) => {
                                                                return (
                                                                    <li className='blockChain-category-list' key={index}><Link to={e.blockchain_url}>{e.blockchain_name}</Link></li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col sm={4} md={4} lg={4} xl={4}>
                            <div className="siteMap-card-wrap mt-3">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>NFT Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        <div className='h4_title'>
                                            {
                                                nftList.map((ele, key) => {
                                                    return (
                                                        <li className='blockChain-category-list' key={key}><Link to={ele.nft_url}>{ele.name}</Link></li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div className="siteMap-card-wrap mt-4">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>Game Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        <div className='h4_title'>
                                            {
                                                gameList.map((ele, key) => {
                                                    return (
                                                        <li className='blockChain-category-list' key={key}><Link to={ele.game_slug}>{ele.name}</Link></li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div className="siteMap-card-wrap mt-4">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>Our Product Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        <div className='h4_title'>
                                            {
                                                ProductData.map((ele, key) => {
                                                    return (
                                                        <li className='blockChain-category-list' key={key}><Link to={ele.product_url}>{ele.name}</Link></li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div className="siteMap-card-wrap mt-4">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>Insight Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        <div className='h4_title'>
                                            <li className='blockChain-category-list'><Link to="/blog/">Blogs</Link></li>

                                            <li className='blockChain-category-list'><Link to="/case-study">Case Study</Link></li>

                                            <li className='blockChain-category-list'><Link to="/engagement-models">Engagement Models</Link></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col sm={4} md={4} lg={4} xl={4}>
                            <div className="siteMap-card-wrap mt-3">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>Hire Developer Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        <div className='h4_title'>
                                            {
                                                hireDevList.map((ele, key) => {
                                                    return (

                                                        <li className='blockChain-category-list' key={key}><Link to={`/hire-developers/${ele.slug}`}>{ele.name}</Link></li>

                                                    )
                                                })
                                            }
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div className="siteMap-card-wrap mt-4">
                                <div className="blockChain-card-head">
                                    <h3 className='h3_title'>Our Company Services</h3>
                                </div>
                                <div className='blockChain-card-details'>
                                    <ul>
                                        <div className='h4_title'>
                                            <li className='blockChain-category-list'><Link to="/aboutUs">About Us</Link></li>

                                            <li className='blockChain-category-list'><Link to="/contactUs">Contact Us</Link></li>

                                            <li className='blockChain-category-list'><Link to="/career/">Career</Link></li>

                                            <li className='blockChain-category-list' onClick={handleNav} ><Link to="#mission">Mission and Vision</Link></li>

                                            <li className='blockChain-category-list'><Link to="/team">Our Team</Link></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SiteMap