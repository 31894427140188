import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Mobile UI Designing",
            content: "Craft visually appealing and user-centric mobile interfaces that captivate users and enhance the overall app experience"
        },
        {
            title: "Prototyping Services",
            content: "Transform your app ideas into interactive prototypes, providing a real preview of functionality and design."
        },
        {
            title: "App Wireframe Design",
            content: "Plan the structure and layout of your app with wireframes, ensuring a clear and organized user journey."
        },
        {
            title: "Theme Layouts",
            content: "Create captivating theme layouts that resonate with your app's brand, delivering a consistent and visually striking experience."
        },
        {
            title: "Material Design for Android Mobile",
            content: "Embrace Google's Material Design principles to create smooth, intuitive, and organized Android app interfaces."
        },
        {
            title: "iOS Design as per Apple’s standards",
            content: "Create iOS app designs that align with Apple's design guidelines, ensuring a seamless and intuitive user experience on Apple devices"
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>We Provide Services for Mobile App Design</h2>
                                <p>Professional Excellence and Next-Generation Mobile App Design</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
