import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Artificial Intelligence And Machine Learning Development Services</h2>
                                <p>Infograins develops machine learning and artificial intelligence (AI) tools to address business challenges.</p>
                                <ul style={{color:'#fff'}}>
                                    <li> Work with AI and ML developers who have over years of expertise.</li>
                                    <li> NDA confidentiality is 100%.</li>
                                    <li> An enthusiastic project manager</li>
                                    <li> Complete guidance from concept building to launching</li>
                                    <li> 100% Secure and Confidential</li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
