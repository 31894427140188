import axios from 'axios';
import React, { useState } from 'react'
import { Col, Form, Row, Button, Image, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
export default function AgricultureForm() {
    const [uploadedImage, setUploadedImage] = useState('')
    const [showImg, setShowImg] = useState("")
    const [ImgError, setImgError] = useState(false)
    const [result, setResult] = useState()
    const [Loader, setLoader] = useState(false)
    const onSubmit = async (data) => {
        setLoader(true)
        data.preventDefault()
        const bodyData = new FormData()
        bodyData.append("image", uploadedImage)
        try {
            const api = await axios.post('https://dp.infosense.tech/core/api/leaf_health', bodyData)
            setResult(api.data)
            setLoader(false)
        } catch (error) {
            console.log(error);
            setLoader(false)
        }

        if (uploadedImage.length === 0) {
            setImgError(true)
        } else {
            setImgError(false)
        }
    }

    const onFileUpload = (e) => {
        const [file] = e.target.files || e.dataTransfer.files;
        setUploadedImage(file)

        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = () => {
            const fileRes = btoa(reader.result);
            setShowImg(`data:image/jpg;base64,${fileRes}`);
        };

        reader.onerror = () => {
            console.log("There is a problem while uploading...");
        };
    }

    const dropImage = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const [file] = e.target.files || e.dataTransfer.files;
        setUploadedImage(file)
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = () => {
            const fileRes = btoa(reader.result);
            setShowImg(`data:image/jpg;base64,${fileRes}`);
        };

        reader.onerror = () => {
            console.log("There is a problem while uploading...");
        };
    }



    return (
        <>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col lg={12} className='drop_box'>
                        <button className='choose_image_btn'>
                            <input type="file" name="" id="" accept="image/png, image/jpeg" onChange={(e) => onFileUpload(e)} />
                        </button>
                        <div id='gallery' className='choose_file_wrapper' onDragOver={e => e.preventDefault()} onDrop={dropImage} style={{ backgroundImage: `url(${showImg})` }}
                        >
                        </div>
                        {ImgError &&
                            <p className='text-danger'>Please upload image</p>
                        }
                        <Form.Group className="mt-4" controlId="formGroupEmail">
                            {
                                Loader ?
                                    <div className="btn btn-primary disabled w-100"><Spinner animation='border' size='sm'/></div>
                                    :
                                    <Button className='w-100' variant="primary" type="submit">
                                        Predict
                                    </Button>
                            }
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {
                result &&
                <div className='result_wrap'>
                    <h3>Decease: {result.desease}</h3>
                    <h3>Name: {result.leaf_obj}</h3>
                </div>
            }
        </>
    )
}