import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Video from '../../../assets/media/new-landing/section2.mp4'
import { Link } from 'react-router-dom'
export default function Section2() {
    return (
        <>
            <section className='section_two'>
                <Container>
                    <Row>
                        <Col xl={5} lg={5} md={5} sm={12} className='video_sec'>
                            <video autoPlay loop muted>
                                <source src={Video} type="video/mp4" />
                                <source src={Video} type="video/ogg" />
                            </video>
                        </Col>
                        <Col xl={7} lg={7} md={7} sm={12} className='content'>
                            <div className=''>
                                <h2>Collaborate with Top Software Development Company</h2>
                                <p>
Infograins has emerged as the leading Software Development Company where we specializes in delivering end-to-end tailored software  solutions to meet the unique needs of our clients. We provide Custom Software development, Blockchain Development, Web 3 Development, Metaverse Development, Web Development and Many more. Our In-House team leverages their in-depth knowledge to develop decentralized applications, smart contracts, and Blockchain solutions.

</p>
                                <div className='btn_group'>
                                    <Link to={'/hire-developers/it-staffing-solutions'}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                                                <path d="M17.7071 8.20711C18.0976 7.81658 18.0976 7.18342 17.7071 6.79289L11.3431 0.428931C10.9526 0.0384068 10.3195 0.0384069 9.92893 0.428931C9.53841 0.819456 9.53841 1.45262 9.92893 1.84314L15.5858 7.5L9.92893 13.1569C9.53841 13.5474 9.53841 14.1805 9.92893 14.5711C10.3195 14.9616 10.9526 14.9616 11.3431 14.5711L17.7071 8.20711ZM8.74228e-08 8.5L17 8.5L17 6.5L-8.74228e-08 6.5L8.74228e-08 8.5Z" fill="black" />
                                            </svg>
                                        </div>
                                        Know more
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
